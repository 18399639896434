import styled from "@emotion/styled";

var style = getComputedStyle(document.body)


export const StyledHours = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-top: 175px;
  width: 100%;
  background-color: #8EC055;
  color: white;
  max-height: 26px;
  min-height: 26px;
  z-index: 5;
`;

export const StyledUl = styled.ul`
  background-color:  ${style.getPropertyValue('--header-third-color')};
  width: 100%;
  display: flex;
  min-height:  35px;
  max-height:  100%;
`;
export const Styledli = styled.li`

  box-sizing: border-box;
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-size: 20px;
  background-color: ${style.getPropertyValue('--header-third-color')};
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  height: 100%;
  min-height:  35px;
  max-height:  100%;
  width: 99px;
  min-width: 99px;
  max-width: 99px;
  color: white
  border-left: 1px solid white;

  &:first-child {
    margin-left: 160px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);

  }
  &:last-child {
    margin-right: 20px;
  }
  &> span {
    
    display: flex;
    width: auto;
    height: auto;
  }
`;