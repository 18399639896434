import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import "../../../style.css";
import conf from "../jsonFiles/config.json"

const StyledNavigation = styled.div`
display: flex;
position: fixed;
  background-color: #e4451c;
  height: 110px;
  margin-top: 65px;
top: 0px; 
z-index: 6;
 
`;

const StyledNavigationWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;
  width: 65%;
  position: sticky;
  z-index: 8;
`;

const StyledUl = styled.ul`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  justify-content: center;
  z-index: 10;
  margin-left: 40px;
  z-index: 8;

`;

const Styledli = styled.li`
  display: flex;
  padding-left: 10px;
  font-size: 20px;
  margin-bottom: 40px;
  width: 15%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  z-index: 8;


  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: white;
    z-index: 10;

  }
  & > a:hover{
    background-color: #481d24;
    border: 1px solid #481d24;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: underline;
  }

  & > a > span {
    width: auto;
    height: auto;
  }
 
`;

const StyledNavigationContainerM = styled.div`
display:flex;
position: relative;
  width: 100%;
  background-color: #e4451c;
  height: 50%;
  min-height: 50%;
  max-height: 50%;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const StyledNavigationTestReplayContainer = styled.div`
  display: flex;
  top: 0px;
  align-items: center;
  height: 100%;
  z-index: 1;
  width: 20%;
  max-width: 250px;
  min-width: 205px;
  margin-left: 310px;
  margin-right: 50px;

`

const StyledNavigationTestReplay = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-position: row;
  border: 10px black whitesmoke;
  border-radius: 10px;
  background-color: white;
  position: relative;
  right: 110px;
  height: 75px;
  width: 100%;
  z-index: 25;
left: 0px;
& > span {
  display: inline;
  text-align: center;
  width: auto;
  height: auto;
  font-weight: bold;
  margin: 5px;

}
`;

const StyledReplayLogoContainer = styled.div `
display: flex;
background-color: #6b773c;
  padding: 5px 2px 5px 5px;
  height: auto;
  width: auto;
  border-radius: 5px;
  margin: 15px 2px 15px 15px;

`

const StyledReplayLogo = styled.div `
display: flex;
    width: 0px;
    height: 0px;
    border-left: 22px solid whitesmoke;
    border-bottom: 13px solid transparent;
    border-top: 13px solid transparent;
    border-radius-top: 10px;
    border-radius-bottom: 10px;


`

const StyledDaySpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top: 5%;
`

const StyledMonthSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  font-size: 80%;
  
`



/*Test Carousel */

const StyledCarouselDayDiv = styled.div`
  display: flex;
  position: absolute;
  background-color: green;
  height: 100%;
  width: 90%;
  justify-content: center;
  align-items: center;
`

const StyledDayContainerDiv = styled.div`
  display: flex;
  background-color: pink;
  height: 50%;
  width: 50%;
  justify-content: center;
  align-items: center;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    text-decoration: none;
    font-weight: bold;
    font-size: 4vmin;

  }
`
/*
const StyledCarouselLeft = styled.a`
display: flex;
justify-content: center;
align-items: center;
background-color: red;

`

const StyledCarouselRight = styled.a`
display: flex;
justify-content: center;
align-items: center;
background-color: grey;

`
*/

const StyledCarouselDaySpan = styled.div`
display: flex;
background-color: orange;
justify-content: center;
align-items: center;
`



export default function Navigation(props) {


  console.log(props.index)

  const getDays = (day="", number= "") => {

    if(number !== ""){
      let date = new Date();
      date.setDate(date.getDate() + number);

      day = date.getDay();
    }

    switch (day) {
      case 0:
        return "Dimanche";
      case 1:
        return "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      default:
        return "Aujourd'hui";
    }
  };


  const getDate = (numberC = "", numberM ="") => {
    let date = new Date();

    if(numberC !== ""){
      if (numberC !== 0 ) {
      date.setDate(date.getDate() + numberC);
      }
    }
    
    if(numberM !== ""){
      date.setDate(numberM)
    }
    

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      month = "0" + month.toString();
    }
    if (day < 10) {
      day = "0" + day.toString();
    }

    return `/${day}-${month}-${year}`;
  };

  const getSpanDate = (numberC = "") => {
    let date = new Date();

      if (numberC !== 0 ) {
      date.setDate(date.getDate() + numberC);
      }
    

    let day = date.getDate();

    let month = date.getMonth() + 1;


    switch(month) {

      case 1:
        return `${day} janvier`;
      case 2:
        return `${day} fevrier`; 
      case 3:
        return `${day} mars`; 
      case 4:
        return `${day} avril`; 
      case 5:
        return `${day} mai`; 
      case 6:
        return `${day} juin`;
      case 7:
        return `${day} juillet`; 
      case 8:
        return `${day} août`; 
      case 9:
        return `${day} septembre`; 
      case 10:
        return `${day} octobre`;
      case 11:
        return `${day} novembre`; 
      case 12:
        return `${day} décembre`;
      default: 
        return `...`

    }

  };


  const select = (e) => {
    //console.log(e)
    //FUNCTION HAVE NO UTILITY NOW
    /*
    the actual day that need to be higlighted is known by the index parameters that is given at the creation of the header

    there is a new State each time that we click on another day because we change the uri
    */
  }

  const createDays = () => {
    let resp = [];
    for(let i = 0; i < 7; i++){
      if(i === 0){//option-selected 
        resp.push(<Styledli className="navLi firstLi">
        <Link className={props.index  == i ?"option-selected dayLink":"dayLink"} to={"/preprod"+getDate(0, "")} onClick={(e) => {select(e)}}><StyledDaySpan>{getDays()}</StyledDaySpan><StyledMonthSpan>{getSpanDate(0)}</StyledMonthSpan></Link>
        </Styledli>)
      }
      if(i > 0){
      resp.push(<Styledli className="navLi">
      <Link  className={props.index  == i ?"option-selected dayLink":"dayLink"} to={"/preprod"+getDate(i, "")} onClick={(e) => {select(e)}}><StyledDaySpan>{getDays("",i)}</StyledDaySpan><StyledMonthSpan>{getSpanDate(i)}</StyledMonthSpan></Link>
      </Styledli>)
      }
    }
    return resp;
  }


  const carouselChange = () => {
    document.querySelector(".carouselNav").addEventListener("click", ()=> {


       document.querySelector(".carouselLeft").href = "/preprod"+getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 0 ? 6: (parseInt(localStorage.getItem("Day")) -1):6, "")
      document.querySelector(".carouselRight").href = "/preprod"+getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")
       document.querySelector(".carouselDaySpan").innerText= localStorage.getItem("Day") ? getDays("",parseInt(localStorage.getItem("Day"))): getDays()
    })
  }


  getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")


/*
  window.addEventListener("resize", () =>{
       if(window.innerWidth < 1400){
        document.querySelector(".navigationUl").style.visibility = "hidden"
        document.querySelector(".carouselNavigationContainer").style.visibility = "visible"
       }
       if(window.innerWidth > 1400){
        document.querySelector(".navigationUl").style.visibility = "visible"
        document.querySelector(".carouselNavigationContainer").style.visibility = "hidden"

       }
       
    });*/



  //Date Logic for the Mobile Version
  let url = window.location.href
  let urlLength = url.length
  let urlDateStart = urlLength - 10
  let urlDate = url.substring(urlDateStart, urlLength)
  let urlDateLength = urlDate.length
  let dateT = new Date()
  let year = urlDate.substring(6,urlDateLength)
  let month = (parseInt(urlDate.substring(3,5)) - 1).toString()
  let day = urlDate.substring(0,2)
  dateT.setFullYear(year, month, day);
  let dateTplus1 = new Date()
  let dateTmoin1 = new Date()
  dateTplus1.setFullYear(year, month, (dateT.getDate() + 1));
  dateTmoin1.setFullYear(year, month, 
    (parseInt(dateT.getDate()) - 1).toString());
  let dayCurrent = dateT.getDay() 
  let dayMoin1 = dateTmoin1.getDate()
  let dayPlus1 = dateTplus1.getDate()
  /*-------------------------------*/


   

 

  //fix the text "available on packarabia"
  let computerVersion = (
     <StyledNavigation className="navigation">
      <StyledNavigationTestReplayContainer>
          <StyledNavigationTestReplay>
              <StyledReplayLogoContainer>
                <StyledReplayLogo />
              </StyledReplayLogoContainer>
              <span>Programmes disponibles sur MY ARABIA Replay</span>
          </StyledNavigationTestReplay>
      </StyledNavigationTestReplayContainer>
       <StyledNavigationWrapper> 
         <StyledUl className="navigationUl">
          {
            createDays()
          }
         </StyledUl>
     <StyledCarouselDayDiv className="carouselNavigationContainer" style={{
       visibility: "hidden"
     }}>
       <StyledDayContainerDiv>
       <Link className="carouselNav carouselLeft" to={"/preprod"+getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 0 ? 6: (parseInt(localStorage.getItem("Day")) -1):6, "") } onClick={(e) => {carouselChange()}}>{'<'}</Link>
         <StyledCarouselDaySpan className="carouselDaySpan">{localStorage.getItem("Day") ? getDays("",parseInt(localStorage.getItem("Day"))): getDays()}</StyledCarouselDaySpan>
       <Link className="carouselNav carouselRight" to={"/preprod"+getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")} onClick={() => {carouselChange()}}>{'>'}</Link>
       </StyledDayContainerDiv>
     </StyledCarouselDayDiv>
      </StyledNavigationWrapper>    
    </StyledNavigation>
  )


  //ok 
  let mobileVersion = (
    <StyledNavigationContainerM className="navigation" >
        <div className="carousel-container">
           <a href={"/preprod"+getDate("", dayMoin1)} 
                class="carousel__prev"><img alt="left" src="data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E"/></a>  
            <span class="dayN">{getDays(dayCurrent, "")}</span>
            <a href={"/preprod"+getDate("", dayPlus1)} 
                class="carousel__next"><img alt="right" src="data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E"/></a>
        </div>       
    </StyledNavigationContainerM>
  )


  return (window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion);
}
