import { getMinutesT, getMinutes} from "../data/TimeRelatedData.js"

const enableScroll = () => {
    window.onscroll = function() {};
}


//function to adjust the position and the size of each programs 
const adjustProgram = () => {
  try{
    document.querySelector(".flexbox-row-div").style.maxWidth = "unset"
    window.scrollTo(0,0); 
    document.querySelectorAll(".flexbox-show-div > .schedule").forEach((x, index) => {

        let time = x.textContent.substr(0, 8);
        const scheduleWidth = document.querySelector(".hours > ul > li").getBoundingClientRect().width
        let divXPosition = x.parentElement.getBoundingClientRect().x 
        let previousSiblingWidth = x.parentElement.previousSibling.getBoundingClientRect().width
        let previousSiblingX = x.parentElement.previousSibling.getBoundingClientRect().x


        if ((divXPosition < (scheduleWidth * getMinutesT(time))) === true) {
          x.parentElement.style.marginLeft = (x.parentElement.previousSibling ? 
           (scheduleWidth * getMinutesT(time)) / 30 - previousSiblingWidth - previousSiblingX + 160 + "px" : (scheduleWidth * getMinutesT(time)) / 30 + "px"); 
        }


        //Problem is above
        if(parseInt(x.parentElement.style.marginLeft) > 1){
          x.parentElement.style.borderLeft = "1px solid black";
        }
        
        if(getMinutesT(time) === 0){
          x.parentElement.style.marginLeft = "0px"
        }

        if(x.parentElement.previousSibling){
          //Before this was == If there is any ERROR concerning the adjustment of the block go back to the previous version
          if(x.parentElement.previousSibling.lastChild.innerText.substr(10,8) === x.parentElement.lastChild.innerText.substr(0,8) && getMinutesT(time) !== 0){
            x.parentElement.style.borderLeft = "1px solid black";            
          }
          if(getMinutesT(time) !== 0 ){
            x.parentElement.style.borderLeft = "1px solid black";
          }
        }
        if(!x.parentElement.nextSibling){
          x.parentElement.style.borderRight = "1px solid black"
        }

        setTimeout(() => {
          document.querySelector(".pointerHours").style.left = (document.querySelector(".hours > ul > li").getBoundingClientRect().width * 
          getMinutes((parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString())) / 30 + 160  - 8+ "px"
        }, 200)
        setTimeout(() => {
          enableScroll()
        }, 400)
      });

      if(document.querySelector(".loadingDataDiv")){
        setTimeout(()=>{
          document.querySelector(".loadingDataDiv").innerHTML = ""   
          document.querySelector(".loadingDataDiv").style.backgroundColor = "transparent" 
          document.querySelector(".loadingDataDiv").style.zIndex = "0" 

        })
      }

  }catch(e){
    console.log(e)
  }
}


export { adjustProgram}




