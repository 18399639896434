import React from "react";
import "../../../../style.css";
import axios from "axios";
import * as style from "./StyledEpgAdministrationLogin"
import * as config from "../../jsonFiles/config.json"


const epgApiDomainName = config.epgApiUrl

export default class EpgAdministrationLogin extends React.Component {

  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        liveShows: props.liveShows,
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "AR" ? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {
    
    const signIn = async (username, password) => {
            let response = await axios.post(`${epgApiDomainName}api/v1/auth/signin`, 
            {
            username : username,
            password : password
            },
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json", 
              }
            })

            return response
    }
 
    document.querySelector(".signin-btn").addEventListener("click", ()=> {
        let username = document.querySelector(".username-input").value
        let password = document.querySelector(".password-input").value

        signIn(username, password)
        .then((response) => {
            console.log(response)         
            if(response.status == 200){ 
                localStorage.setItem("username",username)
                localStorage.setItem("access-token",response.data.accessToken)
                let redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}pannels`
                document.location = redirection
            }
        }).catch((error) => {
            console.log(error)
            alert("error username or password is incorrect !❌  Try again") 
        })
      })
  }

  render () {

    let resp = ( 
    <style.StyledContainerDiv>
        <style.StyledHeaderContainer>Administration Connexion</style.StyledHeaderContainer>
        <style.StyledBodyContainer>
            <style.StyledLoginBoxContainer>
                <style.StyledLoginBoxHeader>Login</style.StyledLoginBoxHeader>
                <style.StyledLoginBoxBody>

                    <style.StyledUsernameSpan>Username : </style.StyledUsernameSpan>
                    <style.StyledUsernameInput className="username-input"></style.StyledUsernameInput>

                    <style.StyledPasswordSpan>Password : </style.StyledPasswordSpan>
                    <style.StyledPasswordInput className="password-input" type="password"></style.StyledPasswordInput>

                    <style.StyledLoginButton className="signin-btn">Sign In</style.StyledLoginButton>
                </style.StyledLoginBoxBody>
            </style.StyledLoginBoxContainer>
        </style.StyledBodyContainer>
        <style.StyledFooterContainer>Footer</style.StyledFooterContainer>
    </style.StyledContainerDiv>
    )
  
  return resp;
  }

}
