import React, { useState, useEffect, Component } from "react";
import * as request from "./../data/request"

import styled from "@emotion/styled";
import "../../../style.css";
import axios from "axios";


const StyledContainerDiv = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    background-color: white;
    flex-direction: column
`

const StyledHeaderContainer = styled.div`
    display: flex;
    height: 10%;
    width: 100%;
    background-color: #333030;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 3vmin;

`

const StyledBodyContainer = styled.div`
    display: flex;
    height: 85%;
    width: 100%;
    background-color: grey;
    align-items: center;
    justify-content: center;

`
const StyledFooterContainer = styled.div`
    display: flex;
    height: 5%;
    width: 100%;
    background-color: #333030;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 3vmin;

`




const StyledLoginBoxContainer = styled.div`
    display: flex;
    height: 70%;
    width: 40%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 5px solid black;
  
`


const StyledLoginBoxHeader = styled.div`
    display: flex;
    height: 20%;
    width: 100%;
    background-color: #333030;
    align-items: center;
    justify-content: center;
    font-size: 3vmin;
    font-weight: bold;
    color: white;

`

const StyledLoginBoxBody = styled.div`
    display: flex;
    height: 80%;
    width: 100%;
    background-color: #3f3b3b;

    flex-direction: column;

`

const StyledUsernameSpan = styled.span`
    display: flex;
    height: auto;
    width: 70%;
    margin-top: 10%;
    margin-bottom: 2%;
    margin-left: 5%;
    font-size: 2vmin;
    font-weight: bold;
    color: white;

`
const StyledUsernameInput = styled.input`
    display: flex;
    height: 10%;
    width: 70%;
    background-color: grey;
    margin-left: 5%;
    font-size: 2vmin;
    cursor: pointer;

`

const StyledPasswordSpan = styled.span`
    display: flex;
    height: auto;
    width: 70%;
    margin-top: 5%;
    margin-bottom: 2%;
    margin-left: 5%;
    font-size: 2vmin;
    font-weight: bold;
    color: white;
`

const StyledPasswordInput= styled.input`
    display: flex;
    height: 10%;
    width: 70%;
    background-color: grey;
    margin-left: 5%;
    font-size: 2vmin;
    cursor: pointer;

`

const StyledLoginButton = styled.button`
    display: flex;
    height: 10%;
    width: 20%;
    margin-top: 5%;
    margin-bottom: 2%;
    margin-left: 5%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: green;
    font-size: 2vmin;
    font-weight: bold;
    color: white;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {

            background-color: #2EE59D;
            box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
            color: #fff;
            transform: translateY(-7px);
          
    }
`




export default class EpgAdministrationLogin extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        liveShows: props.liveShows,
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "AR" ? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {
    

    const signIn = async (username, password) => {
            let response = await axios.post(`https://epg-backend-preprod.herokuapp.com/api/v1/auth/signin`, 
            {
            username : username,
            password : password
            },
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json",
                
              }
            })
            console.log(response)

            return response
          
    }
 


    document.querySelector(".signin-btn").addEventListener("click", ()=> {
        let username = document.querySelector(".username-input").value
        let password = document.querySelector(".password-input").value


        signIn(username, password).then((response) => {
            console.log(response)

            console.log(`${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}${username}/${response.data.accessToken}`)
         
            if(response.status == 200){ 
                //let redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}${username}/${response.data.accessToken}`
                localStorage.setItem("username",username)
                localStorage.setItem("access-token",response.data.accessToken)
                let redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}pannels`
                document.location = redirection
            }
            
        }).catch((error) => {
            console.log(error)
            alert("error username or password is incorrect !❌  Try again")
            
        })
    })
  }

  render () {

    let resp = ( 
    <StyledContainerDiv>
        <StyledHeaderContainer>Administration Connexion</StyledHeaderContainer>
        <StyledBodyContainer>
            <StyledLoginBoxContainer>
                <StyledLoginBoxHeader>Login</StyledLoginBoxHeader>
                <StyledLoginBoxBody>

                    <StyledUsernameSpan>Username : </StyledUsernameSpan>
                    <StyledUsernameInput className="username-input"></StyledUsernameInput>

                    <StyledPasswordSpan>Password : </StyledPasswordSpan>
                    <StyledPasswordInput className="password-input" type="password"></StyledPasswordInput>

                    <StyledLoginButton className="signin-btn">Sign In</StyledLoginButton>
                </StyledLoginBoxBody>
            </StyledLoginBoxContainer>
        </StyledBodyContainer>
        <StyledFooterContainer>
            Footer
        </StyledFooterContainer>
    </StyledContainerDiv>
    )


   
  return resp;
  }

}
