import React, { useState, useEffect, Component } from "react";

import localChannels from "../../jsonFiles/localChannelsM.json";
import { getMinutes, getDay} from "../../data/TimeRelatedData"
import categoriesTranslation from "../../jsonFiles/categoriesTranslation.json";
import * as request from "../../data/request"

import styled from "@emotion/styled";
import "../../../../style.css";



const StyledHeader = styled.div`
  display: flex;
  background-color: #e4451c;
  width: 100%;
  z-index: 5;
  height: 15%;
  min-height: 120px;
  max-height: 15%;
  justify-content: center;
  position: sticky;

`;

const StyledChannel= styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  z-index: 5;
  justify-content: center;
  flex-wrap: wrap;

`;

const StyledChannelImage= styled.img`
display: flex;
  width: 60%;
  height: 70%;
  min-width: 80px;
  max-width: 180px;
  

`;

const StyledChannelTitle = styled.div`
display: flex;
z-index: 5;
justify-content: center;
align-items: center;
width: 100%;
height: 20%;

`;

const StyledTitle = styled.span`
display: inline-block;
text-align: center;
padding-top: 0%;
width: 100%;
height: 100%;
font-weight: bold;
color: white;

`;

const ButtonContainer= styled.div`
  display: flex;
  z-index: 6;
  position: absolute;
  left: 2%;
  top: 0%;
  width: 15%;
  height: 5%;
  justify-content: center;
  align-items: center;
`;

const StyledBackSymbolContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
`

const StyledBackSymbol = styled.div`
  display: flex;
  width: 65%;
  height: 25%;
  border-top: 2px solid white;
  border-left: 2px solid white;
  transform: rotate(320deg);

`

const StyledBackSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color:white;
  font-weight: bold;
  width: 50%;
  height: 50%;
  font-size: 110%;

  &:hover {
    border-bottom: 1px solid white;
    cursor: pointer;
  }

`

const StyledProgramsContainer= styled.div`
    display: flex;
    background-color: #333030;
    width: 100%;
    z-index: 5;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10%;

`;

const StyledProgram = styled.div`
    display: flex;
    width: 100%;

    z-index: 5;
    /* needed for other device */
    min-height: auto;
    /*resolve the issue on iphone and huwaei but the issue is on ipad and big screen*/
    height: auto;

    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;

    /*370*/
    @media (min-width: 100px) and (max-width: 400px){
      min-height: 100px;
      min-height: 125px;
    }


`;

const StyledThumbnailContainer = styled.div`
    display: flex;
    background-color: #3f3b3b;
    width: auto;
    max-width: 50%;
    max-height:500px;
    z-index: 5;
    height: auto;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;

`;

const StyledThumbnail = styled.img`
    width: auto;
    max-width: 100%;
    max-height: 99.7%;
    height: auto; 
    min-height: 20%;
    min-width: 40%;


`;

const StyledDescriptionContainer = styled.div`
      display: flex;
      width: 50%;
      z-index: 5;
      height: 100%;
      min-height: 100%;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      background-color: #3f3b3b;

      @media (min-width: 100px) and (max-width: 400px){
        min-height: 98px;
        min-height: 123px;

      }

`;


const StyledProgramTitle = styled.span`
  display: inline;
  color: white;
  width: auto;
  height: auto;
  margin-top: 5%;
  margin-left: 5%;
  font-size: 3vmin;

/*
  &:hover {
    border-bottom: 2px solid white;
    cursor: pointer;
  }*/
`;

const StyledProgramLiveContainer = styled.div`
    display: flex;
    position: absolute;
    width: 30%;
    height: 30%;
    right: 0%;
    bottom: 0%;
    z-index: 5;
    visibility: hidden;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;

`;

const StyledProgramReplayContainer = styled.div`
    display: flex;
    position: absolute;
    width: 70%;
    height: 30%;
    left: 0%;
    bottom: 0%;
    z-index: 5;
    border-top: 1px solid black;
    justify-content: center;
    align-items: center;

`;

const StyledProgramLiveSymbolContainer = styled.div`
    display: flex;
    width: 30%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: flex-end;
    border-radius: 2px;

`;

const StyledProgramLiveSymbol= styled.div`
    display: flex;
    width: 45%;
    height: 20%;
    z-index: 5;
    background-color: red;
    border-radius: 100%;

`;

const StyledReplayLogoContainer = styled.div `
display: flex;
background-color: #6b773c;
  height: 60%;
  width: 15%;
  border-radius: 2px;
  justify-content: center;
    align-items: center;
  margin-left: 5%;
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 1%;


`

const StyledReplayLogo = styled.div `
    display: flex;
    width: 50%;
    height: 100%;
    margin: 5%;
    border-left: 3vw solid white;
    border-bottom: 2vw solid transparent;
    border-top: 2vw solid transparent;
    border-radius-top: 10px;
    border-radius-bottom: 50%;


`


const StyledProgramLiveTextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;

`;

const StyledProgramReplayTextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;

`;

const StyledProgramLiveText = styled.span`
    display: flex;
    width: 100%;
    height: 90%;
    z-index: 5;
    color: red;
    font-weight: bold;
    font-size: 3vmin;
    align-items: center;
    justify-content: center;

`;

const StyledProgramReplayText = styled.span`
display: flex;
width: auto;
height: 90%;
z-index: 5;
color: white;
font-weight: bold;
font-size: 3vmin;
align-items: center;
justify-content: center;


`;

const StyledProgramSchedule= styled.span`
  display: inline-block;
  background-color: #3f3b3b;
  color: white;
  width: 95%;
  height: auto;
  margin-top: 2.5%;
  margin-left: 5%;
  font-size: 2.5vmin;
`;


const StyledAppHeader = styled.div`
    display: flex;
    border-bottom: 1px solid black;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`;




const StyledThumbnailCategories = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  align-items: center;
  font-size: 4vmin;
  color: #C62A2A;
  font-weight: bold;
  font-family: trajan_reg, serif;
`;



async function getData(language, day = 0, replay=false){
  language = language.substr(0,2)
  console.log(language)

  if(replay){
    const url = "https://epg-backend-preprod.herokuapp.com/api/v1/preprod/all/replay/"

    let channels = await request.get(url)

    return channels
  }

  const url = "https://telemuslim.tv/api/v2f/live/getEPGChannels?token=cef2ec5d1876619aeb01b5508f511d6b6603319f&language="+language

  let channels = await request.get(url, "result")
 
  let channelsPrograms = await request.getEveryProgramsForEachChannels(channels, language, day)

  return channelsPrograms
}


export default class ChannelPageMobile extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        test: ["1","1",",","2"],
        liveShows: props.liveShows,
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "AR" ? "ar" : "fr") && localStorage.getItem("language")
      }
  }


  componentDidMount () {

    let url = window.location.href
    url = url.split("/")
    let channelCode = url[url.length - 4]
    let dayNum = url[url.length - 1]

    this.setState({day: dayNum})

    if(!localStorage.getItem("language")){
      localStorage.setItem("language", this.state.userLanguage === "ar" || this.state.userLanguage === "AR" ? "arabic" : "french")
    }

    let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage

   
    getData(selectedLanguage, this.state.day, true)
    .then((response)=>{
      return response
    }).then((resp)=>{
  
      this.setState({replayPrograms : resp[0].channels.filter(x => x.name == channelCode)})
   

      getData(selectedLanguage, parseInt(dayNum))
      .then((response)=>{
        return response.filter(channel => channel.code === channelCode)
      }).then((res)=>{
        console.log(res)
        this.setState({channels: res[0], channelProgram: res[0].programs });
      }).then((res)=>{
          if(this.state.userLanguage == "ar"){
            document.querySelectorAll(".modal-description").forEach(el => {
              el.style.alignItems = "flex-end"
            })
    
            document.querySelectorAll(".modal-synopsis").forEach(el => {
              el.style.textAlign = "end"
            })
          }else{
            document.querySelectorAll(".modal-description").forEach(el => {
              el.style.alignItems = ""
            })
    
            document.querySelectorAll(".modal-synopsis").forEach(el => {
              el.style.textAlign = ""
            })
          }
    
          document.querySelectorAll(".myBtn").forEach(el => 
            el.addEventListener("click", (e)=>{
            //modal-${x.title}-${x.start.substr(11,5)} - ${x.end.substr(11,5)} 
            // <div class={`modal-${(x.title).replace(/ /g,"-")}-${(x.start.substr(11,5)).replace(':', '-')}-${(x.end.substr(11,5)).replace(':', '-')} `}>
            let classModal = `.modal-${((e.target.innerText).replace(/ /g,"-")).replace("'","-").replace(":", "-").replace("+","-").replace("'","-").replace("?","-").replace(",","-")}-${(e.target.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )
    
    
          document.querySelectorAll(".programs-schedule").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.previousSibling.innerText).replace(/ /g,"-")).replace("'","-").replace(":", "-").replace("+","-").replace("'","-").replace("?","-").replace(",","-")}-${(e.target.innerText).substr(0,5).replace(':', '-')}-${(e.target.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )
    
    
          document.querySelectorAll(".thumbnail-image").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.parentElement.nextSibling.firstChild.innerText).replace(/ /g,"-")).replace("'","-").replace(":", "-").replace("+","-").replace("'","-").replace("?","-").replace(",","-")}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )
    
    
    
    
          document.querySelectorAll(".close").forEach(el => 
            el.addEventListener("click", (e)=>{
              document.querySelectorAll(".modal").forEach(el => {
                    el.style.display = "none";
              })
            })
          )
        
          window.onclick = function(event) {
            document.querySelectorAll(".modal").forEach(el => {
              if (event.target == el) {
                  el.style.display = "none";
              }
            })
    
          }
          
      })
    })
  
      
  }

  


  render () {
    
    let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()
    let channelTitleToCorret = ["MBC Plus Drama", "IqraaInternational"]
    let channelCorrection = ["MBC + Drama", "Iqraa International"]

    const goBack = () => {
      window.history.go(-1);
    }
    const checkIfProgramInReplayIsActive = (start, end) => {
      let actualDate = new Date()

      let paramYear = start.substr(0,4)
      let paramMonth = (parseInt(start.substr(5,2)) - 1)
      let paramDay = start.substr(8,2)

      let paramDate = new Date()
      paramDate.setFullYear(paramYear)
      paramDate.setMonth(paramMonth)
      paramDate.setDate(paramDay)

      let param2Year = end.substr(0,4)
      let param2Month = (parseInt(end.substr(5,2)) - 1)
      let param2Day = end.substr(8,2)

      let param2Date = new Date()
      param2Date.setFullYear(param2Year)
      param2Date.setMonth(param2Month)
      param2Date.setDate(param2Day)
      /*
      console.log(start)
      console.log(end)
      console.log(paramDate <= actualDate )
      console.log(actualDate <= param2Date)*/

      if(paramDate <= actualDate && actualDate <= param2Date){
        return true;
      }else{
        return false;
      }

    }
    let chanelLocalLogo = ""
              
    localChannels.channels.map(localChannelX => {
        if(this.state.channels.code == localChannelX.name){
            chanelLocalLogo = localChannelX.thumbnails
        }
   })




    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/epg-poc-297c3.appspot.com/o/noThubmnailsDefaultBG.jpg?alt=media&token=494947f1-8338-4c0b-891b-869e2ed00509"        
    
    let mobileVersion = ( <StyledAppHeader>
            <ButtonContainer>
                <StyledBackSymbolContainer>
                  <StyledBackSymbol></StyledBackSymbol>
                  </StyledBackSymbolContainer>
                <StyledBackSpan onClick={(e) => {goBack(e)}}>Retour</StyledBackSpan>
            </ButtonContainer>
            <StyledHeader>
              <StyledChannel>
                <StyledChannelImage src={chanelLocalLogo} />
                <StyledChannelTitle>
                  <StyledTitle>{this.state.channels.title === channelTitleToCorret[0] ?  channelCorrection[0] : this.state.channels.title === channelTitleToCorret[1] ?  channelCorrection[1]: this.state.channels.title}</StyledTitle>
                </StyledChannelTitle>
              </StyledChannel>
          </StyledHeader>
            <StyledProgramsContainer className="programs-container">
              {

                  this.state.channels ? 
                  this.state.channelProgram.map(x => {
                        
                        let programOnReplay = false;

                        if(x.start.substr(11,5) == "0:00" && x.end.substr(11,5) == "00:00"){
                          return
                        }

                     try{
                      this.state.replayPrograms.map(channelR => {
                          
                           this.state.userLanguage == "fr" ? channelR.programs.fr.map(programR => {
                            let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                 

                          if(programR.title == x.title && bool){
                            programOnReplay = true;
                          }
                        }) : channelR.programs.ar.map(programR => {
                          let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 

                          if(programR.title == x.title && bool){
                            programOnReplay = true;
                          }
                        })
                        
                      })
                     }catch(e){
                       console.log(e)
                     }
                      

                        //Option either random choices or just the first category or a list of superiority between categories like Series > to Show
                        let toExclude = ["Khaliji"]
                        let categories = (x.category.split(",").filter(x => !toExclude.includes(x)))
                        if(categories.length > 1){
                          categories.shift()
                        }
                       // console.log(categories)
                        let randomCategory = categories[Math.floor(Math.random() * categories.length)]
                        //(x.category.split(",")[0])
                        categoriesTranslation.categories.forEach(x => {
                          if(x.categorie == randomCategory){
                            randomCategory = this.state.userLanguage === "fr" ? x.fr : x.ar
                          } 
                        })


                        return( 
                        <>
                        <StyledProgram>
                      <StyledThumbnailContainer>
                        <StyledThumbnail className="thumbnail-image" src={x.thumbnail === "" ? defaultImage : x.thumbnail} sizes />
                        <StyledThumbnailCategories
                          style={{
                            visibility: x.thumbnail === "" ? "visible" : "hidden"
                          }}
                        >{randomCategory}</StyledThumbnailCategories>

                      </StyledThumbnailContainer>
                      <StyledDescriptionContainer 
                      >
                        <StyledProgramTitle className="myBtn"
                        style={{
                          fontWeight: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && getMinutes(x.end.substr(11, 16).toString()) > getMinutes(timeInMinutes)? 
                          "bold" : ""
                        }}
                        >{x.title}
                        </StyledProgramTitle>
                        <StyledProgramSchedule className="programs-schedule">{(x.start.substr(11,5) ==="0:00"? "00:00" : x.start.substr(11,5) )+ " - " + x.end.substr(11,5)}</StyledProgramSchedule>

                        <StyledProgramLiveContainer
                        style={{
                          visibility: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                          "visible" : "hidden"
                        }}>
                          <StyledProgramLiveSymbolContainer>
                            <StyledProgramLiveSymbol></StyledProgramLiveSymbol>
                          </StyledProgramLiveSymbolContainer>
                          <StyledProgramLiveTextContainer>
                            <StyledProgramLiveText>LIVE</StyledProgramLiveText>
                          </StyledProgramLiveTextContainer>
                        </StyledProgramLiveContainer>

                        <StyledProgramReplayContainer
                        style={{
                          borderRight: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                          "" : "2px solid black",
                          width: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                          "" : "100%",
                          visibility: programOnReplay ? "visible": "hidden"

        
                        }}>
                          <StyledReplayLogoContainer>
                            <StyledReplayLogo />
                          </StyledReplayLogoContainer>
                          <StyledProgramReplayTextContainer>
                            <StyledProgramReplayText>MY ARABIA</StyledProgramReplayText>
                          </StyledProgramReplayTextContainer>
                        </StyledProgramReplayContainer>

                      </StyledDescriptionContainer>      
                    </StyledProgram>
                    <div class={`modal-${((x.title).replace(/ /g,"-")).replace("'","-").replace(":","-").replace("+","-").replace("'","-").replace("?","-").replace(",","-")}-${(x.start.substr(11,5)==="0:00"? "00:00": x.start.substr(11,5)).replace(':', '-')}-${(x.end.substr(11,5)).replace(':', '-').replace(",","-")} modal`}>
                    <div class="modal-content">
                      <span class="close">&times;</span>
                      <div class="modal-thumbnail-container">
                      <img class="modal-thumbnail" src={x.thumbnail === "" ? defaultImage :x.thumbnail}/>
                      <StyledThumbnailCategories
                          style={{
                            visibility: x.thumbnail === "" ? "visible" : "hidden",
                            fontSize: "8vmin"
                          }}
                        >{randomCategory}</StyledThumbnailCategories>

                      </div>
                      <div class="modal-description-container">
                        <div class="modal-description">
                        <span class="modal-title">{x.title}</span>
                        <ul class="modal-categories-list">                   
                          {
                            x.category.split(",").filter(x => x != "Khaliji").map(x => {
                              let translatedCategory;
                                  categoriesTranslation.categories.forEach(y=> {
                                    if(y.categorie == x){
                                      translatedCategory = this.state.userLanguage === "fr" ? y.fr : y.ar
                                    } 
                                  })
                               
                              return <li>{translatedCategory}</li>
                            })
                          }
                        </ul>
                        <span class="modal-schedule">{getDay(parseInt(this.state.day), this.state.userLanguage)} {x.start.substr(11,5).replace(":","h")}</span>
                        <span class="modal-length">{this.state.userLanguage === "ar" ? "المدة " +x.duration+ " دقيقة": "Durée " +x.duration+ " minutes"}</span>
                        <span class="modal-synopsis">{x.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                    
                    )}) : console.log("noprograms")
            }     
       </StyledProgramsContainer>
      
        </StyledAppHeader>
    )

   
  return mobileVersion;
  }

}

