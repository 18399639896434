import React, { useState, useEffect, Component } from "react";
import styled from "@emotion/styled";
import "../../../style.css";
import axios from "axios";
import * as request from "../data/request"

import { getLocalDateFormat, compareDate, getMinutes} from "../data/TimeRelatedData.js"
import localChannels from "../jsonFiles/localChannels.json";
import config from "../jsonFiles/config.json";
import {StyledUpdateButton, StyledButtonContainer, StyledRecapJson, StyledRecapContainer, StyledReplayProgramSelectedDiv, StyledTestInput, StyledTestLabel, StyledProgramSpan, StyledProgramInput, StyledWrapper, StyledProgramLi
, StyledProgramUl, StyledPrograms, StyledChannel, StyledChannelsContainer, StyledEpgContainer, StyledFooterContainer, StyledBodyContainer, StyledHeaderContainer, StyledContainerDiv} from "./EpgAdministrationStyle"

const BASEURL = config.url

const TOKEN = config.token


const StyledNavigationContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

background-color: grey;

`

const StyledNavigations = styled.div`
display: flex;
height: 85%;
width: 70%;
align-items: center;
flex-wrap: wrap;
color: white;
font-weight: bold;
font-size: 3vmin;
background-color: green;

`



const StyledClientsContainer = styled.div`
display: flex;
height: 100%;
width: 50%;
justify-content:  center;
align-items: center;
flex-direction: column;
background-color: #333030;

`

const StyledClientsHeader = styled.div`
display: flex;
height: 10%;
width: 100%;
justify-content:  center;
align-items: center;
background-color: #333030;
border-top: 2px solid black;
border-bottom: 2px solid black;
border-bottom: 2px solid black;

`


const StyledClientsBody = styled.div`
display: flex;
height: 90%;
width: 100%;
align-items: center;
flex-direction: column;
background-color: #5d5959;
border-left: 1px solid black;
border-bottom: 1px solid black;
position: relative

`

const StyledClientsBodyContainer = styled.div`
display: flex;
height: auto;
width: 100%;
align-items: center;
flex-direction: row;
background-color: pink;

margin-bottom: 5px;

`



const StyledChannelsBody = styled.div`
display: flex;
height: 90%;
width: 100%;
flex-direction: column;
background-color: #4d4d4d;;

`

const StyledChannelsList = styled.div`
flex-grow: 1;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;
align-content: flex-start;
height: 90;
width: 100%;
background-color: #4d4d4d;;

`



const StyledClientsUsername = styled.span`
display: flex;
background-color: #4d4d4d;
height: auto;
width: 80%;
justify-content:  center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
     filter: brightness(80%);

 }

`


const StyledDeleteClient = styled.div`
display: flex;
height: 100%;
width: 20%;
font-size: 3vh;
font-weight: bold;
justify-content: center;
align-items: center;
background-color: red;

&:hover{
    cursor: pointer;
    filter: brightness(80%);

}

`


const StyledClientsChannels = styled.span`
display: flex;
background-color: green;
height: auto;
width: 100%;
justify-content:  center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
 }
`


const StyledAddClientButton = styled.button`
display: flex;
position: absolute;
bottom: 0;
left: 0;
background-color: green;
color: white;
height: 7%;
width: 30%;
border-radius: 4px;
padding:0px;
font-size: 30px;
justify-content:  center;
align-items: center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
     background-color: #199C02;

 }


`

const StyledAddClientContainer = styled.form`
display: none;
position: absolute;
height: 90%;
width: 100%;
grid-template-columns: repeat(10, 1fr);
grid-template-rows: repeat(15, 1fr);
background-color: #333030;

`

const StyledAddClientTitle = styled.span`
grid-column: 1 / -1;
grid-row: 1 / 1;
display: grid;
justify-content: center;
align-items: center;
height:100%;
width:100%;
border: 2px solid black;
background-color: #8ec26a;

`


const StyledFieldContainer = styled.div`
display: flex;
background-color: green;
height:auto;
width:50%;
`



//Username
const StyledAddClientLabelUsername = styled.label`
grid-column: span 2;
grid-row: 3/3;
display: grid;
align-content: center;
justify-content: center;
color: white;
font-size: 70%;

`
const StyledAddClientInputUsername = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 3/3;
height: 70%;

`

//Email
const StyledAddClientLabelEmail = styled.label`
grid-column: span 2;
grid-row: 5/5;
display: grid;
align-content: center;
justify-content: center;
font-size: 70%;
color: white;
`
const StyledAddClientInputEmail = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 5/5;
height: 70%;

`


const StyledSpanUsernameInUse = styled.span`
display: grid;
grid-column: span 4;
grid-row: 3/3;
align-self: center;
background-color: red;
height: 70%;
font-size: 50%;
margin-left: 10px;
width: auto;
visibility: hidden;

`
const StyledSpanEmailInUse = styled.span`
display: grid;
grid-column: span 4;
grid-row: 5/5;
align-self: center;
background-color: red;
height: 70%;
font-size: 50%;
margin-left: 10px;
width: auto;
visibility: hidden;


`





//Password
const StyledAddClientLabelPassword = styled.label`
grid-column: span 2;
grid-row: 7/7;
display: grid;
align-content: center;
justify-content: center;
color: white;
font-size: 70%;
`
const StyledAddClientInputPassword  = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 7/7;
height: 70%;
`

const StyledAddClientSubmit = styled.button`
grid-column: 8/10;
grid-row: 9/9;
height: 100%;
background-color: #2babff;
border-radius: 4px;
filter: brightness(120%);
border: 2px solid black;
&:hover{
    cursor: pointer;
    filter: brightness(80%);
    border-color:#FFFFFF;
    color: white;
}
`

const StyledAddClientInfoBox = styled.div`
grid-column: 1/-1;
grid-row: 11/-1;
background-color: green;
`




const getData = async(language1="fr", language2 = "ar") => {
    const url = "https://epg-backend-preprod.herokuapp.com/api/v1/clients/all"

    let clients = await request.get(url)

    return clients
}



export default class EpgAdministrationClients extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        clients: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
 
  }




  componentDidMount () {
    //https://epg-backend-preprod.herokuapp.com/api/v1/clients/all


   


    getData()
    .then(resp=> {
        console.log(resp)
        resp.map(x => console.log(x.username))
        this.setState({clients: resp})
    })


  }

  

  render () {

    function handleClick(e, name, ctx){
        e.stopPropagation()

        document.getElementById("client-channels").innerHTML = ""

        /*
        if(document.getElementById("client-channels")?.children.length > 0){
            document.getElementById("client-channels").innerHTML = ""

            return
        }*/

        document.querySelectorAll(".client-username").forEach(el=>{
            el.style.backgroundColor = "#4d4d4d";
        })


        e.target.style.backgroundColor = "green";

        
        let client = ctx.state.clients.filter(x => x.username === name)

        let channels = client[0].accessibleChannels

        channels.forEach(x => {
            let span = document.createElement("input")
            span.value = x
            span.style.display = "flex"
            span.style.textAlign = "center"
            span.style.height = "5%"
            span.style.width = "80%"
            span.style.fontSize = "2vh"
            span.style.fontWeight = "bold"
            span.style.justifyContent = "center"
            span.style.marginTop = "5%"
            span.style.backgroundColor = "grey"


            let div = document.createElement("div")
            div.innerText = "X"
            div.style.display = "flex"
            div.style.height = "5%"
            div.style.width = "20%"
            div.style.fontSize = "2vh"
            div.style.fontWeight = "bold"
            div.style.justifyContent = "center"
            div.style.marginTop = "5%"
            div.style.backgroundColor = "red"


            div.addEventListener("click", (el)=>{
                console.log(el.target.previousSibling)

                document.getElementById("client-channels").removeChild(el.target.previousSibling)
                document.getElementById("client-channels").removeChild(el.target)
            })

            document.getElementById("client-channels").appendChild(span)
            document.getElementById("client-channels").appendChild(div)

        })

        let updateDiv = document.createElement("div")
        updateDiv.style.display = "flex"
        updateDiv.style.border = "1px solid black"
        updateDiv.style.marginTop = "2%"
        updateDiv.style.height = "auto"
        updateDiv.style.justifyContent = "space-between"

        updateDiv.classList.add("updateDiv")



        let updateBtn = document.createElement("button")
        updateBtn.innerText = "Update"
        updateBtn.style.display = "flex"
        updateBtn.style.backgroundColor = "purple"
        updateBtn.style.height = "100%"
        updateBtn.style.width = "auto"
        updateBtn.style.fontSize = "3vh"
        updateBtn.style.borderRadius = "4px"
        updateBtn.style.color = "white"

        updateDiv.appendChild(updateBtn)


        let addChannels= document.createElement("button")
        addChannels.innerText = "Add"
        addChannels.style.display = "flex"
        addChannels.style.backgroundColor = "orange"
        addChannels.style.height = "100%"
        addChannels.style.width = "auto"
        addChannels.style.fontSize = "3vh"
        addChannels.style.borderRadius = "4px"
        addChannels.style.color = "white"

        updateDiv.appendChild(addChannels)


        addChannels.addEventListener("click",(el)=>{

            let span = document.createElement("input")
            span.style.display = "flex"
            span.style.textAlign = "center"
            span.style.height = "5%"
            span.style.width = "80%"
            span.style.fontSize = "2vh"
            span.style.fontWeight = "bold"
            span.style.justifyContent = "center"
            span.style.marginTop = "5%"
            span.style.backgroundColor = "grey"


            let div = document.createElement("div")
            div.innerText = "X"
            div.style.display = "flex"
            div.style.height = "5%"
            div.style.width = "20%"
            div.style.fontSize = "2vh"
            div.style.fontWeight = "bold"
            div.style.justifyContent = "center"
            div.style.marginTop = "5%"
            div.style.backgroundColor = "red"

            div.addEventListener("click", (el)=>{
                console.log(el.target.previousSibling)

                document.getElementById("client-channels").removeChild(el.target.previousSibling)
                document.getElementById("client-channels").removeChild(el.target)
            })

            document.getElementById("client-channels").appendChild(span)
            document.getElementById("client-channels").appendChild(div)
        })



        updateBtn.addEventListener("click", (e)=>{
            e.stopPropagation()

            console.log("NAMMMMEE === " + name)

            let arr = []

            for(let i =0; i < document.getElementById("client-channels").children.length; i++){
                if(document.getElementById("client-channels").children[i].nodeName == "INPUT"){
                    console.log(document.getElementById("client-channels").children[i].value)
                    arr.push(document.getElementById("client-channels").children[i].value.toLowerCase())
                }
            }

            console.log(arr)


            request.put("https://epg-backend-preprod.herokuapp.com/api/v1/clients/update/"+name, arr)
            .then(resp=>{
                console.log(resp)
            }).then(()=>{
                console.log("Success! The channels have been updated! ✔️")
                getData()
                .then(resp=> {
                    ctx.setState({clients: resp})
                })
            })


        
        })


       // if(document.getElementById("client-channels-body").children.length < 2){

        for(let i = 0; i < document.getElementById("client-channels-body").children.length; i++){
            if(document.getElementById("client-channels-body").children[i].className == "updateDiv"){
                document.getElementById("client-channels-body").children[i].remove()
            }
        }

        document.getElementById("client-channels-body").appendChild(updateDiv)
       // }

   
    }
   
    function showAddClientForm(e, ctx){
        e.stopPropagation()

        if(document.getElementById("add-client-container").style.display == "none"){
            document.getElementById("add-client-container").style.display = "grid"
            document.getElementById("add-client-btn").innerHTML = "Close"


        }else{
            document.getElementById("add-client-container").style.display = "none"
            document.getElementById("add-client-btn").innerHTML = "Add"

        }
   
    }


    function addClient(e, ctx){
        e.stopPropagation()

        let username = document.getElementById("username")
        let usernameValidity = false
        let email = document.getElementById("email")
        let emailValidity = false

        let password = document.getElementById("password")
        let passwordValidity = false


        //USERNAME
        if(username.value == ""){
            username.style.border = "1px solid red"
        }else if(username.length < 5){
            username.style.border = "10px solid red"
        }
        else{
            username.style.border = "2px solid green"
            usernameValidity = true
        }

        //EMAIL
        if(!email.value.match("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")){
            console.log('email is BS')
            email.style.border = "1px solid red"
        }else{
            email.style.border = "2px solid green"
            emailValidity = true

        }

        //PASSWORD
        if(password.value == ""){
            password.style.border = "1px solid red"
        }else if(password.value.length < 6){
            password.style.border = "10px solid red"
        }
        else{
            password.style.border = "2px solid green"
            passwordValidity = true
        }

        console.log(username.value + " " + email.value + " " + password.value)


        if(usernameValidity && emailValidity && passwordValidity){

            let obj = {username: username.value, email: email.value, password: password.value, epgClient: true }

            request.post("https://epg-backend-preprod.herokuapp.com/api/v1/auth/signup",  obj)
            .then(resp=>{
                console.log(resp)

                document.getElementById("info-message").innerHTML = resp.message

                document.getElementById("info-username-in-use").style.visibility = "hidden"
                document.getElementById("info-email-in-use").style.visibility = "hidden"
              
                if(resp.message.toLowerCase().includes("username")){
                    username.style.border = "1px solid red"
                    document.getElementById("info-username-in-use").style.visibility = "visible"
                }else if (resp.message.toLowerCase().includes("email")){
                    email.style.border = "1px solid red"
                    document.getElementById("info-email-in-use").style.visibility = "visible"
                }else{
                    email.style.border = ""
                    username.style.border = ""
                    username.value = ""
                    email.value = ""
                    password.value = ""

                    console.log("Success! The client has been added! ✔️")
                }



                setTimeout(()=>{
                    document.getElementById("info-message").innerHTML = "";
                },4000)
            }).then(()=>{
                getData()
                .then(resp=> {
                    ctx.setState({clients: resp})
                })
            })
        }
        /*
{
    "username":"testEpgClient3",
    "email":"test-epg-client3@email.com",
    "password":"123Test456",
    "epgClient": true
}
        */


   
    }



    function deleteClient(e, name, ctx){
        e.stopPropagation()

        console.log(name)
        console.log(e.target.parentElement.parentElement)

        var answer = window.confirm("Confirm the suppression of the client " + name  + " ?");
        if (answer) {
            request.deleteRequest("https://epg-backend-preprod.herokuapp.com/api/v1/clients/delete/" + name)
            .then((response)=>{
                console.log(response);
                document.getElementById("client-body").removeChild(e.target.parentElement)
            })
           
        }
        else {
            
        }

   
    }

    let resp = ( 
    <StyledContainerDiv>
        <StyledHeaderContainer>Clients</StyledHeaderContainer>
        <StyledBodyContainer>
            <StyledNavigationContainer>
                <StyledNavigations>
                   <StyledClientsContainer>
                        <StyledClientsHeader>
                            Username
                        </StyledClientsHeader>
                        <StyledClientsBody id="client-body">
                            {
                                this.state.clients.map(x=> {
                                    return (
                                        <StyledClientsBodyContainer>
                                            <StyledClientsUsername className = "client-username" onClick={(e) => {handleClick(e, x.username, this)}}>{x.username}</StyledClientsUsername>
                                            <StyledDeleteClient  onClick={(e) => {deleteClient(e, x.username, this)}}>X</StyledDeleteClient>
                                        </StyledClientsBodyContainer>
                                    )
                                })
              
                            }
                            <StyledAddClientContainer  id="add-client-container">
                                <StyledAddClientTitle >Add a Client</StyledAddClientTitle>
                                <StyledAddClientLabelUsername for="username">Username :</StyledAddClientLabelUsername>
                                <StyledAddClientInputUsername id="username" type="text" placeholder="Enter username" name="username" required></StyledAddClientInputUsername>
                                <StyledSpanUsernameInUse id="info-username-in-use">Username is already taken</StyledSpanUsernameInUse>


                                <StyledAddClientLabelEmail  for="email">Email :</StyledAddClientLabelEmail>
                                <StyledAddClientInputEmail id="email" type="email" placeholder="Enter email" pattern=".+@globex\.com" name="email" required></StyledAddClientInputEmail>
                                <StyledSpanEmailInUse id="info-email-in-use">Email is already taken</StyledSpanEmailInUse>


                                <StyledAddClientLabelPassword  for="password">Password :</StyledAddClientLabelPassword>
                                <StyledAddClientInputPassword id="password" type="password" placeholder="Enter password" name="password" required></StyledAddClientInputPassword>
                                

                                <StyledAddClientSubmit id="submit-client-btn" onClick={(e) => {addClient(e, this)}} type="submit">Submit</StyledAddClientSubmit>

                                <StyledAddClientInfoBox id="info-box"><span>Info :</span><span id="info-message" style={{marginLeft: "10px"}}></span></StyledAddClientInfoBox>
                            </StyledAddClientContainer>
                            
                            <StyledAddClientButton id="add-client-btn" onClick={(e) => {showAddClientForm(e, this)}}>Add</StyledAddClientButton>
                        </StyledClientsBody>
                   </StyledClientsContainer>
                   <StyledClientsContainer style={{borderLeft: "5px solid black"}}>
                        <StyledClientsHeader>
                            Channels
                        </StyledClientsHeader>
                        <StyledChannelsBody id="client-channels-body">
                            <StyledChannelsList id="client-channels">

                           
                            </StyledChannelsList>
                        </StyledChannelsBody>
                   </StyledClientsContainer>
                </StyledNavigations>
            </StyledNavigationContainer>
           
        </StyledBodyContainer>
        <StyledFooterContainer></StyledFooterContainer>

    </StyledContainerDiv>
    )


   
  return resp;
  }

}
