import React, { useState, useEffect, Component } from "react";
import styled from "@emotion/styled";
import "../../../style.css";
import axios from "axios";
import * as request from "./../data/request"

import { getLocalDateFormat, compareDate, getMinutes} from "../data/TimeRelatedData.js"
import localChannels from "../jsonFiles/localChannels.json";
import config from "../jsonFiles/config.json";
import {StyledUpdateButton, StyledButtonContainer, StyledRecapJson, StyledRecapContainer, StyledReplayProgramSelectedDiv, StyledTestInput, StyledTestLabel, StyledProgramSpan, StyledProgramInput, StyledWrapper, StyledProgramLi
, StyledProgramUl, StyledPrograms, StyledChannel, StyledChannelsContainer, StyledEpgContainer, StyledFooterContainer, StyledBodyContainer, StyledHeaderContainer, StyledContainerDiv} from "./EpgAdministrationStyle"

const BASEURL = config.url

const TOKEN = config.token



export default class EpgAdministration extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {


    let url = window.location.href
    url = url.split("/")
    let token = url[url.length - 1]
    let url1 = "https://epg-backend-preprod.herokuapp.com"
    let url2 = "http://localhost:8888"
    let tableId = "60dc53469301df1e24cc2a9a"
    let tableIdTest = "60f6be75014ad9beafcfa5de"

    const getData = async(language1="fr", language2 = "ar") => {
        const url = "https://telemuslim.tv/api/v2f/live/getEPGChannels?token=cef2ec5d1876619aeb01b5508f511d6b6603319f&language="+language1

        let channels = await request.get(url, "result")
        let channelsPrograms = await request.getEveryProgramsForEachChannelsForAWeeks(channels, language1, language2)

        return channelsPrograms
    }


    const axiosPostReplayPrograms = async (data) => {
        console.log(data)
        const config = { headers: {'Content-Type': 'application/json', 'Accept' : 'application/json', 'Authorization' : `Bearer ${token}`} };
        axios.put(`${url1}/api/v1/update/replay/${tableIdTest}`, data, config).then(response => {
            console.log(response)
            if(response.status == 200){
                alert("Success! The programs have been updated! ✔️")
            }
        }); 
    
    }
    

    let obj = {
        id: tableIdTest,
        channels:  [
            {
                name: "mbc1",
                programs:  {
                    fr: [
               
                    ],
                    ar: [

                    ]
                },
                
                 
            },
            {
                name: "mbcMasr",
                programs:  {
                    fr: [
                      
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "mbcdrama",
                programs:  {
                    fr: [
               
                    ],
                    ar: [
 
                  
                    ]
                }
                 
            },
            {
                name: "MBC5",
                programs:  {
                    fr: [
    
                    ],
                    ar: [
                    ]
                }
                 
            },
            {
                name: "ARTAflam1",
                programs:  {
                    fr: [
                       
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "MBC3Europe",
                programs:  {
                    fr: [
                    
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "mbcPDrama",
                programs:  {
                    fr: [
                        
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "ARTHekayat",
                programs:  {
                    fr: [
                 
                    ],
                    ar: [
                
                    ]
                }
                 
            },
            {
                name: "DMCDrama",
                programs:  {
                    fr: [
          
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "Panoramafood",
                programs:  {
                    fr: [
                   
                    ],
                    ar: [
                    
                  
                    ]
                }
                 
            },
            {
                name: "GulliBilArabi",
                programs:  {
                    fr: [
                      
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            },
            {
                name: "AlMajdRawda",
                programs:  {
                    fr: [
                   
                    ],
                    ar: [
               
                  
                    ]
                }
                 
            },
            {
                name: "Iqraa",
                programs:  {
                    fr: [
    
                    ],
                    ar: [
        
                    ]
                }
                 
            },
            {
                name: "IqraaInternational",
                programs:  {
                    fr: [
    
                    ],
                    ar: [
                    ]
                }
                 
            },
            {
                name: "Tunisia1",
                programs:  {
                    fr: [
       
                    ],
                    ar: [
    
                  
                    ]
                }
                 
            }
            ,{
                name: "hannibaltv",
                programs:  {
                    fr: [
                    ],
                    ar: [
                    ]
                }
                 
            }
        ]
    }
    let pgrmObj = {
        title: "",
        start:"",
        end:""
    }


    getData().then(resp=> {
        console.log(resp)
        function uniqueKeyValues(arr, key) {
            return [... new Set(arr.map((obj) => {return obj[key]}))];
          }
          resp.forEach(x=>{

            x.programs_fr = uniqueKeyValues(x.programs.filter(x=> x.language == "fr"), 'title')
            x.programs_ar = uniqueKeyValues(x.programs.filter(x=> x.language == "ar"), 'title')
          })
        this.setState({channels: resp})
    }).then(()=>{

  
        if(!localStorage.getItem("replayPrograms")){
            localStorage.setItem("replayPrograms", JSON.stringify(obj))
        }

        let selected =  document.querySelectorAll(".programs-checkbox-fr");
        selected.forEach(function(el) {
            el.addEventListener("click", (e) => {
                let obj = JSON.parse(localStorage.getItem("replayPrograms"))

                if(e.target.checked){
                        let spanEl = document.createElement("span");
                        spanEl.innerText = e.target.nextSibling.innerText
                        e.target.parentElement.parentElement.nextSibling.nextSibling.appendChild(spanEl)

                        obj.channels.map(x => {
                            let code =  e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('')
                            if(code == "mbcplusdrama"){
                                code = "mbcpdrama"
                            }
                            if(x.name.toLowerCase().split(' ').join('') == code){
                                pgrmObj.title=e.target.nextSibling.innerText
                                pgrmObj.start =e.target.nextSibling.nextSibling.lastChild.value
                                pgrmObj.end =e.target.nextSibling.nextSibling.nextSibling.lastChild.value
                                x.programs.fr.push(pgrmObj)
                            }
                        })
                        localStorage.setItem("replayPrograms", JSON.stringify(obj))
                        document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)
                    }

                if(!e.target.checked){

                    for(let i = 0; i< e.target.parentElement.parentElement.nextSibling.nextSibling.children.length; i++){
                        if(e.target.nextSibling.innerText == e.target.parentElement.parentElement.nextSibling.nextSibling.children[i].innerText)
                        {
                            e.target.parentElement.parentElement.nextSibling.nextSibling.removeChild( e.target.parentElement.parentElement.nextSibling.nextSibling.children[i]);
                        }
                        obj.channels.map(x => {
                            let code = e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') 

                            if(code == "mbcplusdrama"){
                                code = "mbcpdrama"
                            }

                            if(x.name.toLowerCase().split(' ').join('') == code){
                                x.programs.fr =  x.programs.fr.filter(function(item) {return item.title !== e.target.nextSibling.innerText}).map(x => x)
                            }
                        })
                    }
                    localStorage.setItem("replayPrograms", JSON.stringify(obj))
                    document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)
                }


            })

            //THIS CODE IS SLOWING THE ADMINISTRATION
            setTimeout(()=>{
                if(!el.attributes.type.ownerElement.checked){
                    if(localStorage.getItem("replayPrograms")){
                        
                    document.querySelector(".recapJson").innerHTML = localStorage.getItem("replayPrograms")
                    let lclStorage = JSON.parse(localStorage.getItem("replayPrograms"))
                    lclStorage.channels.map(x => {
                        if(x.name.toLowerCase().split(' ').join('') == el.attributes.type.ownerElement.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') ){
                            x.programs.fr.map(program => {
                                    if(program.title == el.attributes.type.ownerElement.nextSibling.innerText){
                                let spanEl = document.createElement("span");
                                spanEl.innerText = el.attributes.type.ownerElement.nextSibling.innerText
                                el.attributes.type.ownerElement.parentElement.parentElement.nextSibling.nextSibling.appendChild(spanEl)
                                el.attributes.type.ownerElement.checked = true
                            }
    
                            })
                            
                        }
                    }) 
                    }
                    
    
                }
            },0)
            
        });


        //Delaying TIME FOR ARABIC
        let selectedAr =  document.querySelectorAll(".programs-checkbox-ar");
        selectedAr.forEach(function(el) {
            el.addEventListener("click", (e) => {
                let obj = JSON.parse(localStorage.getItem("replayPrograms"))
                if(e.target.checked){
                    let spanEl = document.createElement("span");
                    spanEl.innerText = e.target.nextSibling.innerText
                    e.target.parentElement.parentElement.nextSibling.appendChild(spanEl)

                    obj.channels.map(x => {
                        let code =  e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('')
                        if(code == "mbcplusdrama"){
                            code = "mbcpdrama"
                        }
                        if(x.name.toLowerCase().split(' ').join('') == code){
                            pgrmObj.title=e.target.nextSibling.innerText
                                pgrmObj.start =e.target.nextSibling.nextSibling.lastChild.value
                                pgrmObj.end =e.target.nextSibling.nextSibling.nextSibling.lastChild.value
                                x.programs.ar.push(pgrmObj)
                        }
                    })
                    localStorage.setItem("replayPrograms", JSON.stringify(obj))
                    document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)
                }

                if(!e.target.checked){
                    for(let i = 0; i< e.target.parentElement.parentElement.nextSibling.children.length; i++){

                        if(e.target.nextSibling.innerText == e.target.parentElement.parentElement.nextSibling.children[i].innerText)
                        {
                            e.target.parentElement.parentElement.nextSibling.removeChild( e.target.parentElement.parentElement.nextSibling.children[i]);
                        }
                        obj.channels.map(x => {

                            let code = e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') 

                            if(code == "mbcplusdrama"){
                                code = "mbcpdrama"
                            }

                            if(x.name.toLowerCase().split(' ').join('') == code){
                                x.programs.ar =  x.programs.ar.filter(function(item) {return item.title !== e.target.nextSibling.innerText}).map(x => x)
                            }
                        })
                    }
                    localStorage.setItem("replayPrograms", JSON.stringify(obj))
                    document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)

                }
            })


            //THIS CODE IS SLOWING THE ADMINISTRATION
            setTimeout(()=>{
                if(!el.attributes.type.ownerElement.checked){
                                if(localStorage.getItem("replayPrograms")){
                                
                        
                                document.querySelector(".recapJson").innerHTML = localStorage.getItem("replayPrograms")
                                let lclStorage = JSON.parse(localStorage.getItem("replayPrograms"))
                                lclStorage.channels.map(x => {
                                    if(x.name.toLowerCase().split(' ').join('') == el.attributes.type.ownerElement.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') ){
                                        x.programs.ar.map(program => {
                                                if(program.title == el.attributes.type.ownerElement.nextSibling.innerText){
                                            let spanEl = document.createElement("span");
                                            spanEl.innerText = el.attributes.type.ownerElement.nextSibling.innerText
                                            el.attributes.type.ownerElement.parentElement.parentElement.nextSibling.appendChild(spanEl)
                                            el.attributes.type.ownerElement.checked = true
                                        }
                                        })
                                        

                                    }
                                }) 
                                }
                                

                            }
            },0)
           

        });


        document.querySelector(".update-btn").addEventListener("click", () => {
            if(document.querySelector(".recapJson").innerHTML !== ""){
                var answer = window.confirm("Confirmation of the update?");
                if (answer) {
                    console.log("Success! The programs have been updated! ✔️")
                    axiosPostReplayPrograms(JSON.parse(document.querySelector(".recapJson").innerHTML))
                }
                else {
                    
                }
            }else {
                alert("❌You need to select some programs ❌")
            }
        })
        


        let startDate = new Date()
        let startDateYear = startDate.getFullYear()
        let startDateMonth = (startDate.getMonth() + 1)
        startDateMonth = startDateMonth < 10 ? "0" + startDateMonth : startDateMonth
        let startDateDay = startDate.getDate()
        startDateDay = startDateDay < 10 ? "0" + startDateDay : startDateDay


        startDate = (startDateYear + "-" + startDateMonth + "-" + startDateDay)

        let endDate = new Date()
        endDate.setDate(endDate.getDate() + 15)
        let endDateYear = endDate.getFullYear()
        let endDateMonth = (endDate.getMonth() + 1)
        endDateMonth = endDateMonth < 10 ? "0" + endDateMonth : endDateMonth
        let endDateDay = endDate.getDate()
        endDateDay = endDateDay < 10 ? "0" + endDateDay : endDateDay

        endDate = (endDateYear + "-" + endDateMonth + "-" + endDateDay)

        document.querySelectorAll(".inputStart").forEach(el => {
            el.value=startDate
        })

        document.querySelectorAll(".inputEnd").forEach(el => {
            el.value= endDate
        })

    })

            
    //}
  }

  

  render () {



    const renderChannelsBlocks = () => {
            return this.state.channels.map(channel => {

                let arr = []
                let arr2 = []

                let channelToExclude = ["ennahar","carthageplus","ElHiwarEttounsi"]


                if(channelToExclude.includes(channel.code)){
                    return
                }

                return (
                <StyledChannelsContainer className="administration-channels-container">
                    <StyledChannel className="administration-channels" style={{
                        backgroundImage: 'url("")',                   
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}>{channel.title}</StyledChannel>
                    <StyledPrograms className="administration-programs">
                        <StyledProgramUl>
                            {
                                 Array.isArray(channel.programs_fr) ? channel.programs_fr.map(program => {
                                        return(
                                            <StyledProgramLi>
                                               
                                                <StyledProgramInput type="checkbox" className="programs-checkbox-fr"></StyledProgramInput>
                                                <StyledProgramSpan>{program}</StyledProgramSpan>

                                                <StyledTestLabel>Start
                                                    <StyledTestInput className="inputStart" type="text" id="name" name="name" ></StyledTestInput>
                                                </StyledTestLabel>
                                                <StyledTestLabel>End
                                                    <StyledTestInput className="inputEnd" type="text" id="name" name="name"></StyledTestInput>
                                                </StyledTestLabel>
                                            </StyledProgramLi>
                                            )   
                                }) : false
                            }
                           
                        </StyledProgramUl>
   
                        <StyledProgramUl>
                        {
                                Array.isArray(channel.programs_ar) ? channel.programs_ar.map(program => {
                                        return(
                                            <StyledProgramLi>
                                               
                                                <StyledProgramInput type="checkbox" className="programs-checkbox-ar"></StyledProgramInput>
                                                <StyledProgramSpan>{program}</StyledProgramSpan>

                                                <StyledTestLabel>Start
                                                    <StyledTestInput className="inputStart" type="text" id="name" name="name" ></StyledTestInput>
                                                </StyledTestLabel>
                                                <StyledTestLabel>End
                                                    <StyledTestInput className="inputEnd" type="text" id="name" name="name" ></StyledTestInput>
                                                </StyledTestLabel>
                                            </StyledProgramLi>
                                            
                                            )
                                    
                                  
                                }) : false
                            }
                        </StyledProgramUl>
                        <StyledReplayProgramSelectedDiv className="selected-replay-programs">

                        </StyledReplayProgramSelectedDiv>
                    </StyledPrograms>
                </StyledChannelsContainer>
           )
            })
        
        
    }

    let resp = ( 
    <StyledContainerDiv>
        <StyledHeaderContainer>Administration Pannel</StyledHeaderContainer>
        <StyledBodyContainer>
           <StyledEpgContainer>
            {
               renderChannelsBlocks()
            }
            
           </StyledEpgContainer>
           
           <StyledRecapContainer>
                <StyledRecapJson className="recapJson">
                
                </StyledRecapJson>
                <StyledButtonContainer>
                    <StyledUpdateButton className="update-btn">Update</StyledUpdateButton>
                </StyledButtonContainer>
           </StyledRecapContainer>
        </StyledBodyContainer>

        <StyledFooterContainer></StyledFooterContainer>
      
    </StyledContainerDiv>
    )


   
  return resp;
  }

}
