import { getMinutes } from "../../data/TimeRelatedData.js"
import { adjustProgram } from "../AdjustProgram"
import * as request from "../../data/request"
import * as config from "../../jsonFiles/config.json"
import channelsCorrection from "../../jsonFiles/channelsCorrection.json";

const domainName = config.url
const epgApiDomainName = config.epgApiUrl
const token = config.token

//Computer 
export const checkIfProgramInReplayIsActive = (start, end) => {
    let actualDate = new Date()

    let paramYear = start.substr(0,4)
    let paramMonth = (parseInt(start.substr(5,2)) - 1)
    let paramDay = start.substr(8,2)

    let paramDate = new Date()
    paramDate.setFullYear(paramYear)
    paramDate.setMonth(paramMonth)
    paramDate.setDate(paramDay)

    let param2Year = end.substr(0,4)
    let param2Month = (parseInt(end.substr(5,2)) - 1)
    let param2Day = end.substr(8,2)

    let param2Date = new Date()
    param2Date.setFullYear(param2Year)
    param2Date.setMonth(param2Month)
    param2Date.setDate(param2Day)

    if(paramDate <= actualDate && actualDate <= param2Date){
      return true;
    }else{
      return false;
    }

}

//Mobile
export function loadindDiv() {
    var loadingDataDiv = document.createElement("div");
    loadingDataDiv.className = "loadingDataDiv"
    var center_lds_ring = document.createElement("div");
    var lds_ring = document.createElement("div");
    lds_ring.className = "lds-ring"

    var div1 = document.createElement("div");
    var div2 = document.createElement("div");
    var div3 = document.createElement("div");
    center_lds_ring.style.display = "flex";
    center_lds_ring.style.justifyContent = "center";
    center_lds_ring.style.alignSelf = "center";

    center_lds_ring.style.width = "40%";
    center_lds_ring.style.height = "55%";

    loadingDataDiv.style.display = "flex";
    loadingDataDiv.style.justifyContent = "center";
    lds_ring.style.display = "flex";
    lds_ring.style.justifyContent = "center";
    lds_ring.style.alignItems = "center;"

    loadingDataDiv.style.top = "22%";
    loadingDataDiv.style.left = "0";
    loadingDataDiv.style.zIndex = "10";
    lds_ring.style.margin = "20% 0% 0 0%"

    lds_ring.appendChild(div1)
    lds_ring.appendChild(div2)
    lds_ring.appendChild(div3)

    center_lds_ring.appendChild(lds_ring)

    loadingDataDiv.appendChild(center_lds_ring)

    document.body.querySelector(".container").appendChild(loadingDataDiv)

}
//Mobile
export function isProgramInLive(timeInMinutes, title, start, end, livePrograms){
    let actualTime = getMinutes(timeInMinutes)

    let startTime = getMinutes(start.substr(11, 16).toString())

    let endPrograms = end.substr(11, 16).toString()
    let endMinutes = getMinutes(endPrograms)
    let endTime = getMinutes(startTime > endMinutes ? "23:59" : endPrograms)


    if( startTime < actualTime && endTime >= actualTime){
        return livePrograms.push(title + start)  
    }
}

export function setLanguageValue(){
  if(localStorage.getItem("language")){
    //English
     document.querySelector(".language").value = 
     localStorage.getItem("language").substring(0,2) === "ar" ? 
     "arabic" 
     : 
     "french"

   }
}


//Mobile & Computer
export const userHandleChange = (arr , operatorChannel, defaultOperator = false, ctx, computer) => {
    //if the selected operator is tous - all
    if(defaultOperator ===  true) {
      try{

      let channels = arr
      channels.map(channel => channel.operateurNumber =  "")
      //re render
      ctx.setState({channels: channels});
      //if it is for the computer version adjust programs
      if(computer == true){
        setTimeout(() => {
            adjustProgram()
           }, 500)  
      }
    }catch(e){
      console.log(e)
    }
    }
    //else 
    if(defaultOperator ===  false) {
      let operatorFilteredChannels = arr
      //set the operatorNumber of each channels to the one matching to the selected operator list
      operatorFilteredChannels.map(channel => {
        channel.operateurNumber = operatorChannel.channels.map(operatorFilteredChannel => {
          if(channel.code === operatorFilteredChannel.name){
            return operatorFilteredChannel.number
          }})
      })
      ctx.setState({channels: operatorFilteredChannels});

      console.log(computer)
      if(computer == true){
        setTimeout(() => {
            adjustProgram()
            }, 1000)  
      }
    }
    setTimeout(()=>{
      setLanguageValue() 
    },0)
} 

export const userLanguageHandleChange= (changedLanguage, operatorsSelectorValue, selectedLanguage,ctx, computer, operators="", timeInMinutes=0, livePrograms=[]) => {

  try {
    getData(selectedLanguage, ctx.state.day, true) 
      .then((response)=> response) 
      .then((resp)=>{    
        if(!computer){
          ctx.setState({replayPrograms : resp[0]})
        }
          getData(changedLanguage, ctx.state.day)
          .then(response => response)
          .then((res)=>{
            console.log(res)
            if(computer == true){
              ctx.setState({channels: res, replayPrograms : resp[0], allChannels: res, userLanguage: changedLanguage});
            }else{
              ctx.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
            }
            return res
          })
          .then((res)=>{
            if(computer === true){

              handleOperatorChange(operatorsSelectorValue, ctx.state.allChannels, operators, ctx, true)
            
              setTimeout(() => {
                adjustProgram()  
              }, 1000)  
            }else{
              handleOperatorChange(operatorsSelectorValue, ctx.state.allChannels, operators, ctx)
              ctx.state.channels?.map(x=> {
                x.programs?.map(program => isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
              })
              ctx.setState({channels: res, allChannels: res, userLanguage: changedLanguage});        
            } 
          })
      })
  }catch(e){
    console.log(e)
    if(!computer){
      getData(changedLanguage, ctx.state.day)
      .then((response)=>response)
      .then((res)=>{
        ctx.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
        return res
      })
      .then((res)=>{
        handleOperatorChange(operatorsSelectorValue, ctx.state.allChannels, operators, ctx)
        ctx.state.channels.map(x=> {
          x.programs.map(program => isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
        })
        ctx.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
      })
    }
  }
}


//Mobile & Computer
export const handleOperatorChange = (operatorsSelectorValue, allChannels, operators, ctx, computer=false)=> {

  if(operatorsSelectorValue === "bouygues"){
    let bouygues = allChannels.filter(x => operators.operators[0].channelsIds.includes(x.code))
    userHandleChange(bouygues, operators.operators[0], false, ctx, computer)
  }
  if(operatorsSelectorValue === "free"){
    let free = allChannels.filter(x => operators.operators[1].channelsIds.includes(x.code))
    userHandleChange(free, operators.operators[1], false, ctx, computer)
  }
  if(operatorsSelectorValue === "orange"){
    let orange = allChannels.filter(x => operators.operators[2].channelsIds.includes(x.code))
    userHandleChange(orange, operators.operators[2], false, ctx, computer)
  }
  if(operatorsSelectorValue === "sfr"){
    let sfr = allChannels.filter(x => operators.operators[3].channelsIds.includes(x.code))
    userHandleChange(sfr, operators.operators[3], false, ctx, computer)
  }
  if(operatorsSelectorValue === "default"){
    userHandleChange(allChannels, "", true, ctx, computer)
  }
}


export function operatorListener(ctx, operators, computer = true){
  //Assign listener to each selector
  document.querySelector(".operators").addEventListener('change', function (evt) {
    let operatorsSelectorValue = document.querySelector(".operators").value;

    setLanguageValue()

    //handle the change of the value
    if(computer){
      handleOperatorChange(operatorsSelectorValue, ctx.state.allChannels , operators, ctx, true)
    }else{
      handleOperatorChange(operatorsSelectorValue, ctx.state.allChannels , operators, ctx)
    }
    localStorage.setItem("operator", document.querySelector(".operators").value)
  })
}


export function languageListener(selectedLanguage,ctx, computer, operators="", timeInMinutes=0, livePrograms=[]){
  document.querySelector(".language").addEventListener('change', function (evt) {
    let operatorValue = document.querySelector(".operators").value 
    //handle the change of the value by language
    if(this.value === "french"){
      userLanguageHandleChange("fr", operatorValue, selectedLanguage, ctx, computer, operators, timeInMinutes, livePrograms)
      localStorage.setItem("language", "fr")
    }
    if(this.value === "arabic"){
      userLanguageHandleChange("ar", operatorValue, selectedLanguage, ctx, computer, operators, timeInMinutes, livePrograms)
      localStorage.setItem("language", "ar")
    }

  })
}

export async function getData(language, day = 0, replay=false){
  language = language.substr(0,2)

  if(replay){
    const url = epgApiDomainName + "api/v1/all/replay/"
    let channels = await request.get(url)

    return channels
  }
  const url = domainName + "getEPGChannels?token="+ token +"&language="+language
  let channels = await request.get(url, "result")
  channels =  channelsCorrection.toInclude.length > 0 ? channels.filter(chnl => channelsCorrection.toInclude.includes(chnl.code)) : channels
  let channelsPrograms = await request.getEveryProgramsForEachChannels(channels, language, day)

  return channelsPrograms
}




