import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "../style.css";
import styled from "@emotion/styled";
import Header from "./prod/navigation/header/Header";
import HeaderTest from "./preprod/navigation/Header";

import EpgCleanP from "./prod/epg/computer/Epg";
import EpgCleanTest from "./preprod/epg/computer/EpgProd";

import EpgAdministrationLogin from "./prod/administration/connexion/EpgAdministrationLogin";
import EpgAdministrationLoginTest from "./preprod/administration/EpgAdministrationLogin";


import EpgAdministrationPannels from "./prod/administration/pannels/EpgAdministrationPannels";
import EpgAdministrationPannelsTest from "./preprod/administration/EpgAdministrationPannels";


import EpgAdministrationClients from "./prod/administration/pannels/clients/EpgAdministrationClients";
import EpgAdministrationClientsTest from "./preprod/administration/EpgAdministrationClients";


import EpgAdministration from "./prod/administration/pannels/replay/EpgAdministration";
import EpgAdministrationTest from "./preprod/administration/EpgAdministration";

import ChannelPageMobile from "./prod/epg/mobile/ChannelPageMobile";
import ChannelPageMobileTest from "./preprod/epg/mobile/ChannelPageMobile";

import conf from "./prod/jsonFiles/config.json";


const StyledApp = styled.div`
  display: flex;
  float: left;
  background-color: #333030;
  flex-direction: column;
  scroll-behavior: smooth;

`;



const getDate = (number) => {
  let date = new Date();

  if (number !== 0) {
    date.setDate(date.getDate() + number);
  }

  let day = date.getDate();

  let month = date.getMonth() + 1;

  let year = date.getFullYear();

  if (month < 10) {
    month = "0" + month.toString();
  }
  if (day < 10) {
    day = "0" + day.toString();
  }

  return `/${day}-${month}-${year}`;
};


let image = "https://packarabia.tv/wp-content/uploads/2020/10/Logo-Arabia-03.png"


const epgRender = () => {

  let resp = [];

  for(let i = 0; i < 7; i++){
     resp.push(<Route key={"route"+i} path={getDate(i)} exact>
             <Header index={i}/>
             <EpgCleanP day={i} />
                {
                window.innerWidth > conf.mobileSize ? <div className="loadingDataDiv"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div> : console.log("")
                }
            </Route>)

  }
  return resp;
}

const epgPreprodRender = () => {

  let resp = [];
  
  for(let i = 0; i < 7; i++){
     resp.push(<Route key={"route"+i} path={"/preprod"+getDate(i)} exact>
             <HeaderTest logo={image} index={i} />
             <EpgCleanTest day={i} />
                {
                window.innerWidth > conf.mobileSize ? <div className="loadingDataDiv"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div> : console.log("")
                }
            </Route>)
  }
  return resp;
}

export default function App() {

  return (
    <StyledApp className="container">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Header />
            <Redirect to={getDate(0)} />;
          </Route>
          <Route path="/preprod" exact>
            <Header logo={image} />
            <Redirect to={"/preprod" + getDate(0)} />;
          </Route>
          {epgRender()}
          {epgPreprodRender()}
          <Route path="/channel/:id/:date/:language/:day" exact>
            <ChannelPageMobile />
          </Route>
          <Route path="/preprod/channel/:id/:date/:language/:day" exact>
              <ChannelPageMobileTest />
          </Route>
            
          <Route path="/administration" exact>
            <EpgAdministrationLogin />
          </Route>
          <Route path="/administration/pannels" exact>
            <EpgAdministrationPannels />
          </Route>
          <Route path="/administration/pannels/clients" exact>
            <EpgAdministrationClients />
          </Route>
          <Route path="/administration/pannels/:id/:token" exact>
              <EpgAdministration />
          </Route>
          
          <Route path="/preprod/administration" exact>
            <EpgAdministrationLoginTest />
          </Route>
          <Route path="/preprod/administration/pannels" exact>
            <EpgAdministrationPannelsTest />
          </Route>
          <Route path="/preprod/administration/pannels/clients" exact>
            <EpgAdministrationClientsTest />
          </Route>
          <Route path="/preprod/administration/pannels/:id/:token" exact>
              <EpgAdministrationTest />
          </Route>

          <Route>
          <Header logo={image} />
            <Redirect to={getDate(0)} />;
          </Route>
        </Switch>
      </Router>
    </StyledApp>
  );
}
