import styled from "@emotion/styled";


export const StyledContainerDiv = styled.div`
display: flex;
height: 100%;
width: 100%;
background-color: white;
flex-direction: column
`

export const StyledHeaderContainer = styled.div`
display: flex;
height: 10%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`

export const StyledBodyContainer = styled.div`
display: flex;
height: 85%;
width: 100%;
background-color: grey;
align-items: center;

`
export const StyledFooterContainer = styled.div`
display: flex;
height: 5%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`


/*Body Content */

export const StyledEpgContainer = styled.div`
display: flex;
height: 100%;
width: 75%;
flex-direction: column;
border: 5px solid black;
background-color: #333030;
overflow: scroll;
margin-left: 0%;
`

export const StyledChannelsContainer = styled.div`
display: flex;
height: 60%;
width: 100%;
flex-direction: row;
min-height: 60%;
border: 2px solid black;
margin-top: 1%;

`

export const StyledChannel = styled.div`
display: flex;
height: 100%;
width: 10%;
background-color: #3f3b3b;
align-items: center;
justify-content: center;
font-size: 2vmin;
color: white;
font-weight: bold;
`

export const StyledPrograms = styled.div`
display: flex;
height: 100%;
width: 90%;
background-color: grey;
align-items: center;

`

export const StyledProgramUl = styled.ul`
display: flex;
height: 90%;
width: 30%;
background-color: #333030;
text-decoration: none;
overflow-y: scroll;
flex-direction: row;
flex-wrap: wrap;
margin-left: 5%;
color: white;
font-weight: bold;
`

export const StyledProgramLi = styled.li`
display: flex;
height: 25%;
width: 100%;
margin-top: 3%;
flex-directon: column;
background-color: #3f3b3b;

flex-wrap:wrap;

&:last-child{
margin-bottom: 3%;

}
`


export const StyledWrapper= styled.div`
display: flex;

flex-directon: column;
background-color: puprle;

`

export const StyledProgramInput = styled.input`
height: 20%;
width: 100%;
min-height: 20%;
min-width: 100%;
margin-right: 5%;

`

export const StyledProgramSpan = styled.span`
display: flex;
height: 20%;
width: 100%;
align-items: center;
justify-content: center;
margin-left: 1%;

`


export const StyledTestLabel = styled.label`
height: 20%;
width: 100%;
min-height: 20%;
min-width: 100%;
position: relative;

`

export const StyledTestInput = styled.input`
height: 100%;
width: 70%;
min-height: 15%;
min-width: 70%;
position: absolute;
left: 20%;


`


export const StyledReplayProgramSelectedDiv = styled.div`
display: flex;
height: 90%;
width: 30%;
background-color: #333030;
margin-left: 5%;
flex-direction: column; 
overflow-y: auto;
color: white;
font-weight: bold;

& > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    min-height: 10%; 
    width: auto;
    /*background-color: red;*/
    margin-top: 5%;
    

}
`

export const StyledRecapContainer = styled.div`
display: flex;
border: 4px solid black;
height: 90%;
width: 35%;
margin-left: 5%;
padding: 0;
align-items: center;
flex-direction: column;
background-color: #3f3b3b;
`

export const StyledRecapJson = styled.div`
display: flex;
background-color: whitesmoke;
height: 90%;
width: 90%;
overflow-y: auto;
margin-top: 5%;


`


export const StyledButtonContainer = styled.div`
display: flex;
height: 15%;
width: 100%;
align-items: center;
background-color: #3f3b3b;
justify-content: center;
align-items: center;

`

export const StyledUpdateButton = styled.button`
display: flex;
height: 50%;
width: 20%;

justify-content: center;
align-items: center;
border-radius: 4px;
background-color: #0077E0;
font-size: 2vmin;
font-weight: bold;
color: white;
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
transition: all 0.3s ease 0s;
cursor: pointer;
outline: none;

&:hover {

        background-color: #2EB9E5;
        box-shadow: 0px 15px 20px rgba(46, 185, 229, 0.4);
        color: #fff;
        transform: translateY(-7px);
      
}
`



