import React from "react";
import styled from "@emotion/styled";
import Navigation from "./Navigation";
import Hours from "./Hours";
import conf from "../jsonFiles/config.json"
import { Link } from "react-router-dom";



//Computer
const StyledAppHeader = styled.div`
  background-color: #481d24;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 8;
  height: 210px;
  /*min-height: 210px;
  max-height: 210px;*/

`;
const StyledHeader = styled.div`
position: fixed;
top: 0px; 
left: 0px; 
  background-color: white;
  height: 70px;
  width: 100%;
  z-index: 8;

  @media all and (max-width: 609px) {
    display:flex;
    justify-content:flex-start;
    height: 50px;
  }
`;

const StyledHeaderTest = styled.div`
position: sticky;


`;

const StyledLogo = styled.img`
  
  width: auto;
  height: auto;
  margin-top: 0px;
  margin-left: 0%;
  position: fixed;
  max-height: 70px;
  left: 30px;
  background-color: none;
 
`;

const StyledSelector = styled.div`
position: absolute;
display: flex;
margin-top: 87px;
height: 67px;
width: 280px;
z-index: 10;
`;

const StyledOperatorList = styled.div`
  display: flex;
  position: fixed;
  left: 50px;
  width: 200px;
  height: 30px;
  z-index: 10;
  align-items: center;
`;

const StyledLanguageSelection= styled.label`  
    display: flex;
    position: fixed;
    top: 123px;
    left: 50px;
    width: 200px;
    z-index: 10;
    height: 30px;


;
`;

const StyledLanguageLabel= styled.label`
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

const StyledOperatorLabel= styled.label`
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

const StyledOperatorSelect = styled.select`
  border-radius: 5px;
  z-index: 20;

`

const StyledLanguageSelect = styled.select`
  border-radius: 5px;
  z-index: 20;

`


const StyledPointerHours= styled.div`
    display: flex;
    position: absolute;
    left: 100%;
    bottom: 26px;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 15px solid white;
    z-index: 10;

`;

//Mobile

const StyledAppHeaderM = styled.div`
  position: fixed;
  top: 0%;
  left: 0px;
  z-index: 10;
  height:20%;
  max-height: 30%;
  padding-bottom: 4.3%;
  
`;

const StyledHeaderM = styled.div`
position: relative;

left: 0px; 
  background-color: white;
  height: 50%;
  width: 100%;
  z-index: 5;

`;


const StyledSelectorContainer = styled.div `
  display: flex;
  margin-bottom: 4%;
  height: 30%;
  z-index: 5;

`
const StyledLanguageSelectionContainerM = styled.div`
  display: flex;
  background-color: white;
  flex:  1 1 50%;
  z-index: 5;

`
const StyledLanguageSelectionM = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
  background-color: #e4451c;
  & > select {
    width: 100%;
    text-align: center;
    text-align-last: center;
    font-size: 2vmin;

  }

`
const StyledLogoM = styled.img`
  width: auto;
  height: auto;
  margin-left: -3px;
  margin-top: 3px;
  position: fixed;
  max-height: 9%;
  max-width: 50%;
  left: 30px;


`;

const StyledOperatorSelectionContainerM = styled.div`
  display: flex;
  background-color: grey;
  flex:  1 1 50%;
  z-index: 5;

`
const StyledOperatorSelectionM = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
  background-color: #e4451c;

  & > select {
    width: 100%;
    text-align: center;
    text-align-last: center;
    font-size: 2vmin;

  }

`



export default class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      operators : "default",
      language : "french"
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChange2 = this.handleChange2.bind(this)

  }

  handleChange (e) {
    this.setState({
      operators: e.target.value,
    })
  }
  handleChange2 (e) {
    console.log(e.target.value)
    console.log(this.state.operators)
    this.setState({
      language: e.target.value,
    })
  }

 
  render () {

    let computerVersion = (
    <StyledAppHeader>
      <StyledHeader>
      <Link onClick={(e) => {window.location.assign("https://packarabia.tv/");}}><StyledLogo src={this.props.logo}></StyledLogo></Link>
        <StyledSelector>
          <StyledOperatorList>
          <StyledOperatorLabel htmlFor="operators"><span>Operateur :</span></StyledOperatorLabel>
            <StyledOperatorSelect className="operators" value={this.state.operators} onChange={this.handleChange}>
              <option value="default">Tous</option>
              <option value="free">Free</option>
              <option value="sfr">Sfr</option>
              <option value="orange">Orange</option>
              <option value="bouygues">Bouygues</option>
            </StyledOperatorSelect>
          </StyledOperatorList>
          <StyledLanguageSelection>
          <StyledLanguageLabel htmlFor="language">Langue  :</StyledLanguageLabel>
            <StyledLanguageSelect className="language" value={this.state.language} onChange={this.handleChange2}>
              <option value="french">Français</option>
              <option value="arabic">العربية</option>
            </StyledLanguageSelect>
          </StyledLanguageSelection>
          </StyledSelector> 
      </StyledHeader>
      <Navigation index={this.props.index} />
      <StyledPointerHours className="pointerHours" />
      <Hours />
    </StyledAppHeader>)


    let mobileVersion = (
      <StyledAppHeaderM className="mobile-app-header">
        <StyledHeaderM>
          <Link onClick={(e) => {window.location.assign("https://packarabia.tv/");}}><StyledLogoM src={this.props.logo}></StyledLogoM></Link>
        </StyledHeaderM>
        <Navigation />
        <StyledSelectorContainer>
          <StyledLanguageSelectionContainerM>
          <StyledLanguageSelectionM>
            <select className="language" value={this.state.language} onChange={this.handleChange2} content="user-scalable=no">
              <option value="french">Français</option>
              <option value="arabic">العربية</option>
            </select>
          </StyledLanguageSelectionM>
        </StyledLanguageSelectionContainerM>
        <StyledOperatorSelectionContainerM>
          <StyledOperatorSelectionM>
          <select className="operators" value={this.state.operators} onChange={this.handleChange} content="user-scalable=no">
              <option value="default">Tous</option>
              <option value="free">Free</option>
              <option value="sfr">Sfr</option>
              <option value="orange">Orange</option>
              <option value="bouygues">Bouygues</option>
            </select>
          </StyledOperatorSelectionM>
        </StyledOperatorSelectionContainerM>
        </StyledSelectorContainer>
      </StyledAppHeaderM>)

    return window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion;
  }




}

