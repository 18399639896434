import React from "react";
import { Link } from "react-router-dom";
import operators from "../../jsonFiles/operators.json" //BOUYGUE[0] - FREE[1] - ORANGE[2] - SFR[3] 
import localChannels from "../../jsonFiles/localChannels.json";
import { getDate, compareDate, getMinutes} from "../../data/TimeRelatedData.js"
import * as style from "./StyledEpg"
import { adjustProgram} from "../AdjustProgram"
import * as request from "../../data/request"
import conf from "../../jsonFiles/config.json"
import * as logic from "./EpgLogic"
import channelsCorrection from "../../jsonFiles/channelsCorrection.json";


export default class EpgProd extends React.Component {

  constructor (props) {
      super(props)
      this.state = {
        channels : [],
        day : props.day,
        allChannels: [],
        allChannelsTest: [],
        replayPrograms: [],
        liveShows: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substring(0,2) : localStorage.setItem("language", (navigator.language).substring(0,2)  === "ar" || (navigator.language).substring(0,2)  === "AR" ? "ar" : "fr") && localStorage.getItem("language"),
        smallScreen: false
     }
     this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      //this.disableScroll = this.disableScroll.bind(this)
      //this.enableScroll = this.enableScroll.bind(this)
      //this.scrollToActualHour = this.scrollToActualHour.bind(this)
  }


  forceUpdateHandler(){
    this.forceUpdate();
  };

 
  componentDidMount () {
    let windowWidth = window.innerWidth

    //Computer version 
    if(window.innerWidth > conf.mobileSize) {
      let ctx = this

      let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage
      
      logic.setLanguageValue()

      //Listener on operator change / true = indication Computer Version running
      logic.operatorListener(ctx, operators, true)

      //Listener on language change
      logic.languageListener(selectedLanguage, ctx, true, operators) 


     try{
      //Get the programs in replay 
      logic.getData(selectedLanguage, this.state.day, true)
      .then((response)=>{
        console.log(response)
        return response
      })
      .then((resp)=>{
        this.setState({replayPrograms : resp[0], channels: []})
        //Get the channel
     
        logic.getData(selectedLanguage, this.state.day)
            .then(response=> response)
            .then((res)=>{
              this.setState({channels: res, allChannels: res});
              console.log(res)
            })
            .then(()=>{
              //Set the operator value to the one saved on the localstorage 
              if(localStorage.getItem("operator")){
                document.querySelector(".operators").value = localStorage.getItem("operator")
                logic.handleOperatorChange(localStorage.getItem("operator"),  ctx.state.allChannels , operators, ctx, true)
              }
              setTimeout(() => {
                adjustProgram()
              }, 1000)      
            })

        })
     }catch(e){
       console.log(e)
       logic.getData(selectedLanguage, this.state.day)
        .then(response=> response)
        .then((res)=>{
          this.setState({channels: res, allChannels: res});
          console.log(res)
        })
        .then(()=>{
          //Set the operator value to the one saved on the localstorage 
          if(localStorage.getItem("operator")){
            document.querySelector(".operators").value = localStorage.getItem("operator")
            logic.handleOperatorChange(localStorage.getItem("operator"),  ctx.state.allChannels , operators, ctx, true)
          }
          setTimeout(() => {
            adjustProgram()
          }, 1000)      
        })
     }
    }

    //Mobile Version 
    if(window.innerWidth <= conf.mobileSize) {
      //launch loading animation 
      logic.loadindDiv()
      
      let livePrograms = [];
      let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()
      let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage

      //Get the progrmas in replay
      try{
      logic.getData(selectedLanguage, this.state.day, true)
      .then(response => response)
      .then((resp)=>{
        this.setState({replayPrograms : resp[0]})
        //Get the channels
       
        logic.getData(selectedLanguage, this.state.day)
        .then(response => response )
        .then(res => this.setState({channels: res, allChannels: res, day: this.state.day }))
        .then(()=>{
          //Push to an array live programs by channels
          this.state.channels.map(x=> {
            if(!x.programs){ return }
            x.programs.map(program =>  logic.isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
          })
          this.setState({liveShows : livePrograms})
          //remove loading animation
          document.body.querySelector(".loadingDataDiv").remove()
        })
      })
    }catch(e){
      console.log(e)
      logic.getData(selectedLanguage, this.state.day)
        .then(response => response )
        .then(res => this.setState({channels: res, allChannels: res, day: this.state.day }))
        .then(()=>{
          //Push to an array live programs by channels
          this.state.channels.map(x=> {
            if(!x.programs){ return }
            x.programs.map(program =>  logic.isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
          })
          this.setState({liveShows : livePrograms})
          //remove loading animation
          document.body.querySelector(".loadingDataDiv").remove()
        })
    }

      //Hangle language change
      //selectedLanguage, ctx, operators, timeInMinutes, livePrograms

      const userLanguageHandleChange= (changedLanguage, operatorsSelectorValue) => { 
        try{
        logic.getData(selectedLanguage, this.state.day, true)
        .then(response => response)
        .then((resp)=>{
          this.setState({replayPrograms : resp[0]})
          logic.getData(changedLanguage, this.state.day)
          .then((response)=>{return response})
          .then((res)=>{
            this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
            return res
          })
          .then((res)=>{
            logic.handleOperatorChange(operatorsSelectorValue, this.state.allChannels, operators, this)
            this.state.channels.map(x=> {
              x.programs.map(program => logic.isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
            })
            this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
          })
        })
        }catch(e){
          console.log(e)
          logic.getData(changedLanguage, this.state.day).then((response)=>{
            console.log(response)
            return response
          }).then((res)=>{
            this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
            return res
          }).then((res)=>{
            logic.handleOperatorChange(operatorsSelectorValue, this.state.allChannels, operators, this)
            this.state.channels.map(x=> {
              x.programs.map(program => logic.isProgramInLive(timeInMinutes, program.title, program.start, program.end, livePrograms))
            })
            this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
          })
        }
      }
      
      logic.setLanguageValue()
      
      let ctx = this;

      //Listener on operator change / false = indication Mobile Version running
      logic.operatorListener(ctx, operators, false)

      //Listener on language change
      logic.languageListener(selectedLanguage, ctx, false, operators, timeInMinutes, livePrograms)

      
    }

    //Listener on the resize of the window to pass from the computer version to the mobile version and vice versa
    window.addEventListener("resize", () =>{
      if(windowWidth > conf.mobileSize){
      if(window.innerWidth < conf.mobileSize){
        window.location.reload()
      }
    }
    if(windowWidth <= conf.mobileSize){
      if(window.innerWidth > conf.mobileSize){
        window.location.reload()
      }
    }
    });
  }

  render () {
    
    let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()
    let channelTitleAvoiDoublon = []
    let channelToExclude = channelsCorrection.toExlcude
    let computerVersion ;
    let mobileVersion ;

    localStorage.setItem("Day",this.state.day)
    //Computer
    if(window.innerWidth > conf.mobileSize){
      console.log(this.state.channels)
      computerVersion = this.state.channels ? this.state.channels?.map((channel) => {
            let chanelLocalLogo = ""
            let dateProgStart = new Date();
            dateProgStart.setDate(dateProgStart.getDate() + parseInt(this.state.day));

            //To get the thumbnails of each channels - using a json file 
            localChannels.channels.map(localChannelX => {
                if(channel.code == localChannelX.name){
                  chanelLocalLogo= localChannelX.thumbnails
                }
            })

            //To not display channel with an item_id equal to null 
            if(channel.item_id == null || channelToExclude.includes(channel.code)){ return }
    
            //Avoid doublon by adding to a list the channel title 
            if(channelTitleAvoiDoublon.includes(channel.title)){ return } 
              channelTitleAvoiDoublon.push(channel.title)
    
              return (
                <>
                {
                  <div className="flexbox-row-div">
                  <style.StyledFlexChannelDiv>
                    <style.StyledFlexContainerDiv>
                    <style.StyledImageDiv>
                      <style.StyledImage src={chanelLocalLogo} size/>
                    </style.StyledImageDiv>
                    <style.StyledFlexNumberDiv className="flexNumber">{channel.operateurNumber}</style.StyledFlexNumberDiv>
                    <style.StyledTitleDiv>{channelsCorrection.hasOwnProperty(channel.title) ? channelsCorrection[channel.title] : channel.title}</style.StyledTitleDiv>
                    </style.StyledFlexContainerDiv>
                  </style.StyledFlexChannelDiv>
                  {
                      channel.programs ? channel.programs.map((program, y) => {
                        let programOnReplay = false;
                        const hoursLiWidth = document.querySelector(".hours > ul > li").getBoundingClientRect().width;
                        let minutes =  parseInt(program.duration.toString())
                          //Check if the programs is on Replay
                          if(this.state.replayPrograms?.length != 0){
                            try{
                              this.state.replayPrograms?.channels?.forEach(channelR => {
                                  if(channel.code === channelR.name){
                                    //English Replay
                                    localStorage.getItem("language").substring(0,2) === "fr" ? //Lucas:Peut être?
                                    channelR.programs.fr.map(programR => {
                                      let bool = logic.checkIfProgramInReplayIsActive(programR.start,programR.end) 
                                      if(programR.title == program.title && bool){ programOnReplay = true; }

                                    }) : channelR.programs.ar.map(programR => {
                                          let bool = logic.checkIfProgramInReplayIsActive(programR.start,programR.end) 
                                          if(programR.title == program.title && bool){ programOnReplay = true; }
                                        })
                                  } 
                                })
                            }catch(e){
                              console.log(e)
                            }
                          }

                          return (
                            <div
                              className="flexbox-show-div"
                              style={
                                //background color => ternary operator to determine if it is on live or not
                                {
                                width: (hoursLiWidth * minutes) / 30 + "px",
                                backgroundColor: 
                                getMinutes(timeInMinutes) > getMinutes(program.start.substr(11, 16).toString())&& 
                                getMinutes(program.end.substr(11, 2).toString() == "00" && compareDate(program.start, program.end, this.state.day) || getMinutes(program.start.substr(11, 16).toString()) > getMinutes( program.end.substr(11, 16).toString()) ? "23:59" : program.end.substr(11, 16).toString() ) >= getMinutes(timeInMinutes)  && this.state.day == "0" ? 
                                "#b10017" : programOnReplay ? "#3f3b3b" : "#3f3b3b"
                              
                              }}
                              onMouseOver={(div)=>{
                                //Original value 75 not 80
                                
                                if((hoursLiWidth * minutes) / 30 < 80 ){
                                  if(div.target.localName === "h4" || div.target.localName === "h5" || div.target.localName === "h6"){
                                    div.target.parentElement.style.width= "200px";
                                  }
                                  
                                  if(div.target.localName === "div" && div.target.className.split(" ")[0] !== "replayLogoContainer"  && div.target.className.split(" ")[0] !== "replayLogo"){
                                    console.log(div)
                                    div.target.style.width= "200px";   
                                    div.target.childNodes[1].innerText = program.title.length > 45 ? program.title.substring(0, 45) + "..." : program.title
                                    div.target.childNodes[1].style.position = "relative"
                                    div.target.lastChild.style.color = "white"
                                    div.target.lastChild.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6): "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                    div.target.lastChild.style.visibility = "visible"
        
                                  }       
                                }
                              }}
                              onMouseLeave={(div)=>{
                                //Original value 75 not 80
                                if((hoursLiWidth * minutes) / 30 < 80 ){
                                  if(div.target.localName === "h4" || div.target.localName === "h5" || div.target.localName === "h6"){
                                    div.target.parentElement.style.width = (hoursLiWidth * minutes) / 30 + "px";
                                    if(div.target.localName === "h4") {
                                      div.target.innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                      div.target.nextSibling.style.color = "#3f3b3b"
                                      div.target.nextSibling.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                      program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                      div.target.nextSibling.style.visibility = "hidden"
                                    }
                                    if(div.target.localName === "h5") {
                                      div.target.previousSibling.innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                      div.target.style.color = "#3f3b3b"
                                      div.target.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                      program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                      div.target.style.visibility = "hidden"
                                    }
                                  }
                                  if(div.target.localName === "div" && div.target.className.split(" ")[0] !== "replayLogoContainer"  && div.target.className.split(" ")[0] !== "replayLogo") {
                                    div.target.childNodes[1].innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                    div.target.style.width = (hoursLiWidth * minutes) / 30 + "px";
                                    div.target.lastChild.style.color = "#3f3b3b"
                                    div.target.lastChild.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                    program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                    div.target.lastChild.style.visibility = "hidden"
                                  }    
                                }
                              }}
                            >

                              <style.StyledReplayLogoContainer  className="replayLogoContainer"
                              style={{
                                visibility: programOnReplay ? "visble": "hidden",
                                height: programOnReplay ? "auto": "0px"
                              }}>
                                <style.StyledReplayLogo className="replayLogo"/>
                              </style.StyledReplayLogoContainer>
                              <style.StyledShowDescription> {
                              
                              //Condition on the length of the title regarding the size of the programs block
                              (hoursLiWidth * minutes) / 30 < 50 ? 
                              "" : (hoursLiWidth * minutes) / 30 < 80 ? 
                              "..." : (hoursLiWidth * minutes) / 30 < 100 ?
        
                              (program.title.length > 30 ? program.title.substring(0, 20) + "..." : program.title ) : 
        
                              (hoursLiWidth * minutes) / 30 < 150 ? 
                              (program.title.length > 25 ? program.title.substring(0, 20) + "..." : program.title) :
                              (program.title.length > 35 ? program.title.substring(0, 35) + "..." : program.title )
                              
                            }</style.StyledShowDescription>
                              <h5 className="schedule"
                                style ={{
                                  color: (hoursLiWidth * minutes) / 30 < 80 ? "#3f3b3b" : "white",
                                  visibility:  (hoursLiWidth * minutes) / 30 < 80 ? "hidden" : "visible"
                                }
                              }
                              >
                                {(hoursLiWidth * minutes) / 30 < 80 ? "  " + program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)}
                              </h5>
                            </div>
                          );
                        //}
                      })  : false
                  }
                  </div>
                }
                  </>  );
              }): <h1>No Data</h1>
    }
    //Mobile
    if(window.innerWidth <= conf.mobileSize){
      
      const reloadPage = () => {
        let val = localStorage.getItem("firstInit")
        if(val < 2){
          val ++
          localStorage.setItem("firstInit", val)
          window.location.reload()
        }
      }

     

      mobileVersion = this.state.channels.map((channel) => {
          let chanelLocalLogo = ""

          //To get the thumbnails of each channels - using a json file 
          localChannels.channels.map(localChannelX => {
            if(channel.code == localChannelX.name){
              chanelLocalLogo= localChannelX.thumbnails
            }
          })

          //To not display channel with an item_id equal to null 
          if(channel.item_id == null || channelToExclude.includes(channel.code)){ return }
    
          //Avoid doublon by adding to a list the channel title 
          if(channelTitleAvoiDoublon.includes(channel.title)){ return }
          channelTitleAvoiDoublon.push(channel.title)
          
          return (
            <>
            {
              <div className="flexbox-row-div-mobile">
                <style.StyledFlexChannelDivM>
                  <style.StyledFlexContainerDivM>
                  <style.StyledImageDivM>
                  <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}> <style.StyledImageM src={chanelLocalLogo} size/></Link>
                  </style.StyledImageDivM>

                  <style.StyledFlexNumberDivM className="flexNumber">{channel.operateurNumber}</style.StyledFlexNumberDivM>

                    <style.StyledTitleDivM>
                      <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{
                      channelsCorrection.hasOwnProperty(channel.title) ? channelsCorrection[channel.title] : channel.title
                      }
                      </Link>
                    </style.StyledTitleDivM>
                  </style.StyledFlexContainerDivM>
                </style.StyledFlexChannelDivM>

                <style.StyledFlexShowContainerM>
                  {
                    channel.programs ? channel.programs.map((program, index) => {
                      let programOnReplay = false;         
                      
                      //Check if the programs in on Replay
                      if(this.state.replayPrograms?.length != 0){
                        try{
                          this.state.replayPrograms?.channels?.map(channelR => {
                            //English Replay
                            this.state.userLanguage === "fr" ? 
                            channelR.programs.fr.map(programR => {
                              let bool = logic.checkIfProgramInReplayIsActive(programR.start,programR.end) 
                              if(programR.title == program.title && bool){ programOnReplay = true; }     
                            }) : 
                            channelR.programs.ar.map(programR => {
                              let bool = logic.checkIfProgramInReplayIsActive(programR.start,programR.end) 
                              if(programR.title == program.title && bool){ programOnReplay = true; }
                            })
                          })
                        }catch(e){
                          console.log(e)
                        }
                      }

                      //if the programs is on live then display title
                      if(this.state.liveShows.includes(program.title + program.start)){
                        return (
                          <style.StyledShowDescriptionM>
                            <style.StyledFlexProgramsScheduleM> <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{"  " + program.start.substr(10, 6) +"-" +program.end.substr(10, 6)}</Link></style.StyledFlexProgramsScheduleM>
                            <style.StyledFlexProgramsTitleM> <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{ 
                            program.title.length > 15 && localStorage.getItem("language") === "ar" ?  
                            "..." + program.title.substring(3, 18)  
                            : 
                            program.title.length > 15 && localStorage.getItem("language") !== "ar"? 
                            program.title.substring(0, 15) + "..." 
                            :
                            program.title}</Link></style.StyledFlexProgramsTitleM>
                          </style.StyledShowDescriptionM>
                        )
                      }
                    }): reloadPage()
                  }
                </style.StyledFlexShowContainerM>
              </div>
      }
      </>
          )
      })
    }

  return ( window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion);

  }

}