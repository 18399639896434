import React, { useState, useEffect, Component } from "react";
import "../../../../../style.css";
import axios from "axios";
import * as request from "../../../data/request"
import * as style from "./StyledEpgAdministration"
import {channelsSchema} from "./EpgAdministrationChannelsModel"
import * as config from "../../../jsonFiles/config.json"


const BASEURL = config.url

const epgApiDomainName = config.epgApiUrl

const TOKEN = config.token



export default class EpgAdministration extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {

    let url = window.location.href
    url = url.split("/")
    let token = url[url.length - 1]
    let url1 = epgApiDomainName
    let tableId = "60f6be75014ad9beafcfa5de"

    const getData = async(language1="fr", language2 = "ar") => {
        const url = BASEURL+ "getEPGChannels?token=cef2ec5d1876619aeb01b5508f511d6b6603319f&language="+language1
        let channels = await request.get(url, "result")
        let channelsPrograms = await request.getEveryProgramsForEachChannelsForAWeeks(channels, language1, language2)

        return channelsPrograms
    }

    const putReplayPrograms = async (data) => {
        const config = { headers: {'Content-Type': 'application/json', 'Accept' : 'application/json', 'Authorization' : `Bearer ${token}`} };
        axios.put(`${url1}api/v1/update/replay/${tableId}`, data, config).then(response => {
            console.log(response)
            if(response.status == 200){
                alert("Success! The programs have been updated! ✔️")
            }
        }); 
    
    }
    
    let obj = channelsSchema
    let pgrmObj = {
        title: "",
        start:"",
        end:""
    }

    getData()
    .then(resp=> {
        console.log(resp)
        //Filter to get unique programs for each channels 
        function uniqueKeyValues(arr, key) {
            return [... new Set(arr.map((obj) => {return obj[key]}))];
          }
        resp.forEach(x=>{
        x.programs_fr = uniqueKeyValues(x.programs.filter(x=> x.language == "fr"), 'title')
        x.programs_ar = uniqueKeyValues(x.programs.filter(x=> x.language == "ar"), 'title')
        })
        this.setState({channels: resp})
    })
    .then(()=>{
        if(!localStorage.getItem("replayPrograms")){
            localStorage.setItem("replayPrograms", JSON.stringify(obj))
        }

        let selected =  document.querySelectorAll(".programs-checkbox-fr");
        let selectedAr =  document.querySelectorAll(".programs-checkbox-ar");
        let checkboxArr = [selected, selectedAr]

        //Given a listener on click for every checkbox
        for(let i = 0; i < checkboxArr.length; i++){
            let lang = i == 0 ? "fr" : "ar"

            checkboxArr[i].forEach(function(el) {
                el.addEventListener("click", (e) => {
                    let obj = JSON.parse(localStorage.getItem("replayPrograms"))
    
                    if(e.target.checked){
                            let spanEl = document.createElement("span");
                            spanEl.innerText = e.target.nextSibling.innerText

                            lang == "fr"?
                            e.target.parentElement.parentElement.nextSibling.nextSibling.appendChild(spanEl):
                            e.target.parentElement.parentElement.nextSibling.appendChild(spanEl)


                            obj.channels.map(x => {
                                let code =  e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('')
                                //specialAffectation for this channels because the code is differents
                                if(code == "mbcplusdrama"){ code = "mbcpdrama"}
                                if(x.name.toLowerCase().split(' ').join('') == code){
                                    pgrmObj.title=e.target.nextSibling.innerText
                                    pgrmObj.start =e.target.nextSibling.nextSibling.lastChild.value
                                    pgrmObj.end =e.target.nextSibling.nextSibling.nextSibling.lastChild.value
                                    x.programs[lang].push(pgrmObj)
                                }
                            })
                            //Updating the data
                            localStorage.setItem("replayPrograms", JSON.stringify(obj))
                            document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)
                    }

                    if(!e.target.checked){
                        for(let i = 0; i< (lang == "fr"? e.target.parentElement.parentElement.nextSibling.nextSibling.children.length : e.target.parentElement.parentElement.nextSibling.children.length ); i++){
                            if(e.target.nextSibling.innerText ==  (lang == "fr"? e.target.parentElement.parentElement.nextSibling.nextSibling.children[i].innerText : e.target.parentElement.parentElement.nextSibling.children[i].innerText))
                            {
                                lang == "fr"? e.target.parentElement.parentElement.nextSibling.nextSibling.removeChild( e.target.parentElement.parentElement.nextSibling.nextSibling.children[i]) : 
                                              e.target.parentElement.parentElement.nextSibling.removeChild(e.target.parentElement.parentElement.nextSibling.children[i])
                            }
                            obj.channels.map(x => {
                                let code = e.target.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') 
                                if(code == "mbcplusdrama"){ code = "mbcpdrama" }
    
                                if(x.name.toLowerCase().split(' ').join('') == code){
                                    x.programs[lang] =  x.programs[lang].filter(function(item) {return item.title !== e.target.nextSibling.innerText}).map(x => x)
                                }
                            })
                        }
                        localStorage.setItem("replayPrograms", JSON.stringify(obj))
                        document.querySelector(".recapJson").innerHTML = JSON.stringify(obj)
                    }
                })
    
                //THIS CODE IS TAKE SOME TIME TO EXECUTE COULD BE OPTIMIZED
                setTimeout(()=>{
                    //check if the programs has been stored on the localstorage and if so activate is checkbox
                    if(!el.attributes.type.ownerElement.checked){
                        if(localStorage.getItem("replayPrograms")){
                            document.querySelector(".recapJson").innerHTML = localStorage.getItem("replayPrograms")
                            let lclStorage = JSON.parse(localStorage.getItem("replayPrograms"))
                            lclStorage.channels.map(x => {
                                if(x.name.toLowerCase().split(' ').join('') == el.attributes.type.ownerElement.parentElement.parentElement.parentElement.previousSibling.innerText.toLowerCase().split(' ').join('') ){
                                    x.programs[lang].map(program => {
                                        if(program.title == el.attributes.type.ownerElement.nextSibling.innerText){
                                            let spanEl = document.createElement("span");
                                            spanEl.innerText = el.attributes.type.ownerElement.nextSibling.innerText
                                            lang == "fr"?
                                            el.attributes.type.ownerElement.parentElement.parentElement.nextSibling.nextSibling.appendChild(spanEl):
                                            el.attributes.type.ownerElement.parentElement.parentElement.nextSibling.appendChild(spanEl)  

                                            el.attributes.type.ownerElement.checked = true
                                        }
                                    })
                                }
                            }) 
                        }
                    }
                },0)
            });
        }

        //Click to update the progams in replay
        document.querySelector(".update-btn").addEventListener("click", () => {
            if(document.querySelector(".recapJson").innerHTML !== ""){
                var answer = window.confirm("Confirmation of the update?");
                if (answer) {
                    console.log("Success! The programs have been updated! ✔️")
                    putReplayPrograms(JSON.parse(document.querySelector(".recapJson").innerHTML))
                }
            }else {
                alert("❌You need to select some programs ❌")
            }
        })
        

        //SET A DEFAULT DATE 
        let startDate = new Date()
        let startDateYear = startDate.getFullYear()
        let startDateMonth = (startDate.getMonth() + 1)
        startDateMonth = startDateMonth < 10 ? "0" + startDateMonth : startDateMonth
        let startDateDay = startDate.getDate()
        startDateDay = startDateDay < 10 ? "0" + startDateDay : startDateDay

        startDate = (startDateYear + "-" + startDateMonth + "-" + startDateDay)

        let endDate = new Date()
        endDate.setDate(endDate.getDate() + 15)
        let endDateYear = endDate.getFullYear()
        let endDateMonth = (endDate.getMonth() + 1)
        endDateMonth = endDateMonth < 10 ? "0" + endDateMonth : endDateMonth
        let endDateDay = endDate.getDate()
        endDateDay = endDateDay < 10 ? "0" + endDateDay : endDateDay

        endDate = (endDateYear + "-" + endDateMonth + "-" + endDateDay)

        document.querySelectorAll(".inputStart").forEach(el => el.value = startDate)
        document.querySelectorAll(".inputEnd").forEach(el => el.value = endDate)
    })
  }

  
  render () {

    const renderChannelsBlocks = () => {
        return this.state.channels.map(channel => {
            let channelToExclude = ["ennahar","carthageplus","ElHiwarEttounsi"]

            if(channelToExclude.includes(channel.code)){return}

            return (
            <style.StyledChannelsContainer className="administration-channels-container">
                <style.StyledChannel className="administration-channels" 
                style={{
                    backgroundImage: 'url("")',                   
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                }}>{channel.title}</style.StyledChannel>
                <style.StyledPrograms className="administration-programs">
                    <style.StyledProgramUl>
                        {
                        Array.isArray(channel.programs_fr) ? channel.programs_fr.map(program => {
                            return(
                                <style.StyledProgramLi>
                                    
                                    <style.StyledProgramInput type="checkbox" className="programs-checkbox-fr"></style.StyledProgramInput>
                                    <style.StyledProgramSpan>{program}</style.StyledProgramSpan>

                                    <style.StyledTestLabel>Start
                                        <style.StyledTestInput className="inputStart" type="text" id="name" name="name" ></style.StyledTestInput>
                                    </style.StyledTestLabel>

                                    <style.StyledTestLabel>End
                                        <style.StyledTestInput className="inputEnd" type="text" id="name" name="name"></style.StyledTestInput>
                                    </style.StyledTestLabel>
                                </style.StyledProgramLi>
                                )   
                        }) : false
                        }
                    </style.StyledProgramUl>
                    <style.StyledProgramUl>
                    {
                        Array.isArray(channel.programs_ar) ? channel.programs_ar.map(program => {
                                return(
                                    <style.StyledProgramLi>
                                        <style.StyledProgramInput type="checkbox" className="programs-checkbox-ar"></style.StyledProgramInput>
                                        <style.StyledProgramSpan>{program}</style.StyledProgramSpan>

                                        <style.StyledTestLabel>Start
                                            <style.StyledTestInput className="inputStart" type="text" id="name" name="name" ></style.StyledTestInput>
                                        </style.StyledTestLabel>
                                        <style.StyledTestLabel>End
                                            <style.StyledTestInput className="inputEnd" type="text" id="name" name="name" ></style.StyledTestInput>
                                        </style.StyledTestLabel>
                                    </style.StyledProgramLi>
                                    )
                        }) : false
                    }
                    </style.StyledProgramUl>
                    <style.StyledReplayProgramSelectedDiv className="selected-replay-programs">

                    </style.StyledReplayProgramSelectedDiv>
                </style.StyledPrograms>
            </style.StyledChannelsContainer>
        )
        })
    }

    let resp = ( 
    <style.StyledContainerDiv>
        <style.StyledHeaderContainer>Administration Pannel</style.StyledHeaderContainer>
        <style.StyledBodyContainer>
           <style.StyledEpgContainer>
            {renderChannelsBlocks()}
           </style.StyledEpgContainer>
           
           <style.StyledRecapContainer>
                <style.StyledRecapJson className="recapJson">
                
                </style.StyledRecapJson>
                <style.StyledButtonContainer>
                    <style.StyledUpdateButton className="update-btn">Update</style.StyledUpdateButton>
                </style.StyledButtonContainer>
           </style.StyledRecapContainer>
        </style.StyledBodyContainer>
        <style.StyledFooterContainer></style.StyledFooterContainer>
    </style.StyledContainerDiv>
    )

  return resp;
  }
}
