const getMinutes = (hour) => {
  if (parseInt(hour) === 0) {
      return parseInt(hour.substr(3, 2))
  }
  // get substr(3,2) if any problem occur with the computer version 
  return parseInt(hour) * 60 + parseInt(hour.toString().substr(3, 2));
  };
  
const getMinutesTest = (hour) => {
    if (parseInt(hour) === 0) {
      return parseInt(hour.substr(2, 2))
    }
    return ((parseInt(hour) * 60) + parseInt(hour.toString().substr(2, 2)));
};


const getMinutesT = (hour) => {
    if (parseInt(hour) === 0) {
      return parseInt(hour.toString().substr(6, 5));
    }
    return parseInt(hour) * 60 + parseInt(hour.toString().substr(6, 5));
  };
  
const compareDate = (date1, date2, propsDay) => {
    let newDate01 = new Date()
    let newDate02 = new Date()
    let newDate = new Date();

    newDate01.setMonth(date1.substr(5,2).toString());
    newDate01.setDate(parseInt(date1.substr(8, 2).toString()));
  
    newDate02.setMonth(date2.substr(5,2).toString());
    newDate02.setDate(date2.substr(8, 2).toString());
  
    
    newDate.setDate(newDate.getDate() + parseInt(propsDay));
    newDate.setMonth(newDate.getMonth());
    newDate01.setMonth(newDate.getMonth());
  
    if(newDate01 > newDate){
      return true
    }
    return newDate02 > newDate01 ? false : false
  };

const getLocalDateFormat = (days) => {
    let newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    let year = newDate.getFullYear().toString();
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    if (month < 10) {
      month = "0" + month.toString();
    }
    if (day < 10) {
      day = "0" + day.toString();
    }
    let exactDate = `${year}-${month}-${day}`;
    return exactDate;
  };

const timeUnderTenFormat = (time) => {
  if(time < 10){
    time = "0" + time.toString()
  }
  return time;
}


const gmtPrograms = (x) => {
  //let newGmtHour = ""
 // let len = x.length

  let date = new Date();
  let year = parseInt(x.substr(0, 4))
  console.log(x.substr(5, 2))
  let month = parseInt(x.substr(5, 2)) - 1 
  let day = parseInt(x.substr(8, 2))
  let hour = (parseInt(x.substr(11, 2)) + 2)
  let minutes = parseInt(x.substr(14, 2))


  date.setFullYear(year, month, day);

  date.setHours(hour, minutes)



  year = date.getFullYear().toString()
  month = timeUnderTenFormat(date.getMonth() + 1)
  day =  timeUnderTenFormat(date.getDate())
  hour = timeUnderTenFormat(date.getHours())
  minutes = timeUnderTenFormat(date.getMinutes())


  x = `${year}-${month}-${day} ${hour}:${minutes}:00`

  return x;

}


const getDate = (number, string) => {
  let date = new Date();

  if (number !== 0) {
    date.setDate(date.getDate() + number);
  }

  let day = date.getDate();

  let month = date.getMonth() + 1;

  let year = date.getFullYear();

  if (month < 10) {
    month = "0" + month.toString();
  }
  if (day < 10) {
    day = "0" + day.toString();
  }

  return `${day}${string}${month}${string}${year}`;
};

const getDay = (number, language ="fr") => {

  
    let date = new Date();
    date.setDate(date.getDate() + number);

   /// let day = date.getDay();
  
  if(language === "fr"){
     switch (date.getDay()) {
      case 0:
        return "Dimanche";
      case 1:
        return "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      default:
        return "Aujourd'hui";
    }
  }

  if(language === "ar"){
    switch (date.getDay()) {
     case 0:
       return "أَحَدْ";
     case 1:
       return "إٍثْنَيْنْ";
     case 2:
       return "ثٌلَاثَاءْ";
     case 3:
       return "أَرْبِعَاءْ";
     case 4:
       return "خَمِيسْ";
     case 5:
       return "جُمُعَة";
     case 6:
       return "سَبْتْ";
     default:
       return "اليوم";
   }
 }
 
};

export { getDate, getLocalDateFormat, compareDate, getMinutesT, getMinutes, getMinutesTest, gmtPrograms, getDay}