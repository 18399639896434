import React, { useState, useEffect, Component } from "react";
import "../../../../../style.css";
import * as request from "../../../data/request"
import { getLocalDateFormat, compareDate, getMinutes} from "../../../data/TimeRelatedData.js"
import * as style from "./StyledEpgAdministrationClients"
import * as config from "../../../jsonFiles/config.json"


const BASEURL = config.url
const epgApiDomainName = config.epgApiUrl

const TOKEN = config.token


const getData = async(language1="fr", language2 = "ar") => {
    const url = epgApiDomainName+"api/v1/clients/all"
    let clients = await request.get(url)

    return clients
}

export default class EpgAdministrationClients extends React.Component {

  constructor (props) {
      super(props)
      this.state = {
        day : "",
        clients: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
  }

  componentDidMount () {
    getData()
    .then(resp=> {
        console.log(resp)
        this.setState({clients: resp})
    })
  }

  
  render () {

    function UsernameClick(e, name, ctx){
        e.stopPropagation()

        document.getElementById("client-channels").innerHTML = ""
        document.querySelectorAll(".client-username").forEach(el=>{
            el.style.backgroundColor = "#4d4d4d";
        })

        e.target.style.backgroundColor = "green";

        let client = ctx.state.clients.filter(x => x.username === name)
        let channels = client[0].accessibleChannels

        channels.forEach(x => {
            let span = document.createElement("input")
            span.value = x
            span.classList.add("channelInput")

            /* create a selector with the code of the channels to avoid error */

            let div = document.createElement("div")
            div.innerText = "X"
            div.classList.add("closeChannelInput")

            div.addEventListener("click", (el)=>{
                document.getElementById("client-channels").removeChild(el.target.previousSibling)
                document.getElementById("client-channels").removeChild(el.target)
            })

            document.getElementById("client-channels").appendChild(span)
            document.getElementById("client-channels").appendChild(div)
        })

        let updateDiv = document.createElement("div")
        updateDiv.classList.add("updateDiv")

        let updateBtn = document.createElement("button")
        updateBtn.innerText = "Update"
        updateBtn.classList.add("updateBtn")

        let addChannels= document.createElement("button")
        addChannels.innerText = "Add"
        addChannels.classList.add("addChannels")

        updateDiv.appendChild(updateBtn)
        updateDiv.appendChild(addChannels)

        addChannels.addEventListener("click",(el)=>{

            let span = document.createElement("input")
            span.classList.add("channelInput")

            let div = document.createElement("div")
            div.innerText = "X"
            div.classList.add("closeChannelInput")

            div.addEventListener("click", (el)=>{
                document.getElementById("client-channels").removeChild(el.target.previousSibling)
                document.getElementById("client-channels").removeChild(el.target)
            })

            document.getElementById("client-channels").appendChild(span)
            document.getElementById("client-channels").appendChild(div)
        })

        updateBtn.addEventListener("click", (e)=>{
            e.stopPropagation()

            let arr = []
            for(let i =0; i < document.getElementById("client-channels").children.length; i++){
                if(document.getElementById("client-channels").children[i].nodeName == "INPUT"){
                    arr.push(document.getElementById("client-channels").children[i].value.toLowerCase())
                }
            }

            request.put(epgApiDomainName+"api/v1/clients/update/"+name, arr)
            .then(resp=>{
                console.log(resp)
            }).then(()=>{
                console.log("Success! The channels have been updated! ✔️")
                getData()
                .then(resp=> {
                    ctx.setState({clients: resp})
                })
            })
        })

        for(let i = 0; i < document.getElementById("client-channels-body").children.length; i++){
            if(document.getElementById("client-channels-body").children[i].className == "updateDiv"){
                document.getElementById("client-channels-body").children[i].remove()
            }
        }
        document.getElementById("client-channels-body").appendChild(updateDiv)
    }
   
    function showAddClientForm(e){
        e.stopPropagation()

        if(document.getElementById("add-client-container").style.display == "none"){
            document.getElementById("add-client-container").style.display = "grid"
            document.getElementById("add-client-btn").innerHTML = "Close"
        }
        else{
            document.getElementById("add-client-container").style.display = "none"
            document.getElementById("add-client-btn").innerHTML = "Add"
        }
    }

    function addClient(e, ctx){
        e.stopPropagation()

        let username = document.getElementById("username")
        let usernameValidity = false
        let email = document.getElementById("email")
        let emailValidity = false
        let password = document.getElementById("password")
        let passwordValidity = false

        //USERNAME
        if(username.value == ""){ username.style.border = "2px solid red" }
        else if(username.length < 5){ username.style.border = "2px solid red" }
        else{
            username.style.border = "2px solid green"
            usernameValidity = true
        }

        //EMAIL
        if(!email.value.match("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")){
            email.style.border = "1px solid red"
        }
        else{
            email.style.border = "2px solid green"
            emailValidity = true
        }

        //PASSWORD => (else if length < 6) ADD (uppercase, lowercase, number match regex)
        if(password.value == ""){ password.style.border = "1px solid red" }
        else if(password.value.length < 6){ password.style.border = "1px solid red" }
        else{
            password.style.border = "2px solid green"
            passwordValidity = true
        }

        if(usernameValidity && emailValidity && passwordValidity){
            let obj = {username: username.value, email: email.value, password: password.value, epgClient: true }

            request.post(epgApiDomainName+"api/v1/auth/signup",  obj)
            .then(resp=>{
                console.log(resp)
                document.getElementById("info-message").innerHTML = resp.message
                document.getElementById("info-username-in-use").style.visibility = "hidden"
                document.getElementById("info-email-in-use").style.visibility = "hidden"
              
                if(resp.message.toLowerCase().includes("username")){
                    username.style.border = "1px solid red"
                    document.getElementById("info-username-in-use").style.visibility = "visible"
                }
                else if (resp.message.toLowerCase().includes("email")){
                    email.style.border = "1px solid red"
                    document.getElementById("info-email-in-use").style.visibility = "visible"
                }
                else{
                    email.style.border = ""
                    username.style.border = ""
                    username.value = ""
                    email.value = ""
                    password.value = ""
                    console.log("Success! The client has been added! ✔️")
                }

                setTimeout(()=>{
                    document.getElementById("info-message").innerHTML = "";
                },5000)
            })
            .then(()=>{
                getData()
                .then(resp=> {
                    ctx.setState({clients: resp})
                })
            })
        } 
    }

    function deleteClient(e, name){
        e.stopPropagation()

        var answer = window.confirm("Confirm the suppression of the client " + name  + " ?");
        if (answer) {
            request.deleteRequest(epgApiDomainName+"api/v1/clients/delete/" + name)
            .then((response)=>{
                console.log(response);
                document.getElementById("client-body").removeChild(e.target.parentElement)
            })
        }
    }

    let resp = ( 
    <style.StyledContainerDiv>
        <style.StyledHeaderContainer>Clients</style.StyledHeaderContainer>
        <style.StyledBodyContainer>
            <style.StyledNavigationContainer>
                <style.StyledNavigations>
                   <style.StyledClientsContainer>
                        <style.StyledClientsHeader> Username </style.StyledClientsHeader>
                        <style.StyledClientsBody id="client-body">
                            {
                                this.state.clients.map(x=> {
                                    return (
                                        <style.StyledClientsBodyContainer>
                                            <style.StyledClientsUsername className = "client-username" onClick={(e) => {UsernameClick(e, x.username, this)}}>{x.username}</style.StyledClientsUsername>
                                            <style.StyledDeleteClient  onClick={(e) => {deleteClient(e, x.username)}}>X</style.StyledDeleteClient>
                                        </style.StyledClientsBodyContainer>
                                    )
                                })
                            }
                            <style.StyledAddClientContainer  id="add-client-container">
                                <style.StyledAddClientTitle >Add a Client</style.StyledAddClientTitle>

                                <style.StyledAddClientLabelUsername for="username">Username :</style.StyledAddClientLabelUsername>
                                <style.StyledAddClientInputUsername id="username" type="text" placeholder="Enter username" name="username" required></style.StyledAddClientInputUsername>
                                <style.StyledSpanUsernameInUse id="info-username-in-use">Username is already taken</style.StyledSpanUsernameInUse>


                                <style.StyledAddClientLabelEmail  for="email">Email :</style.StyledAddClientLabelEmail>
                                <style.StyledAddClientInputEmail id="email" type="email" placeholder="Enter email" pattern=".+@globex\.com" name="email" required></style.StyledAddClientInputEmail>
                                <style.StyledSpanEmailInUse id="info-email-in-use">Email is already taken</style.StyledSpanEmailInUse>


                                <style.StyledAddClientLabelPassword  for="password">Password :</style.StyledAddClientLabelPassword>
                                <style.StyledAddClientInputPassword id="password" type="password" placeholder="Enter password" name="password" required></style.StyledAddClientInputPassword>
                                

                                <style.StyledAddClientSubmit id="submit-client-btn" onClick={(e) => {addClient(e, this)}} type="submit">Submit</style.StyledAddClientSubmit>
                                <style.StyledAddClientInfoBox id="info-box"><span>Info :</span><span id="info-message" style={{marginLeft: "10px"}}></span></style.StyledAddClientInfoBox>
                            </style.StyledAddClientContainer>
                            <style.StyledAddClientButton id="add-client-btn" onClick={(e) => {showAddClientForm(e)}}>Add</style.StyledAddClientButton>
                        </style.StyledClientsBody>
                   </style.StyledClientsContainer>
                   <style.StyledClientsContainer style={{borderLeft: "5px solid black"}}>
                        <style.StyledClientsHeader> Channels </style.StyledClientsHeader>
                        <style.StyledChannelsBody id="client-channels-body">
                            <style.StyledChannelsList id="client-channels">

                            </style.StyledChannelsList>
                        </style.StyledChannelsBody>
                   </style.StyledClientsContainer>
                </style.StyledNavigations>
            </style.StyledNavigationContainer>
        </style.StyledBodyContainer>
        <style.StyledFooterContainer>Footer</style.StyledFooterContainer>
    </style.StyledContainerDiv>
    )

  return resp;
  }
}
