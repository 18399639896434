import React, { useState, useEffect, Component } from "react";
import styled from "@emotion/styled";
import "../../../style.css";
import axios from "axios";
import * as request from "../data/request"

import { getLocalDateFormat, compareDate, getMinutes} from "../data/TimeRelatedData.js"
import localChannels from "../jsonFiles/localChannels.json";
import config from "../jsonFiles/config.json";
import {StyledUpdateButton, StyledButtonContainer, StyledRecapJson, StyledRecapContainer, StyledReplayProgramSelectedDiv, StyledTestInput, StyledTestLabel, StyledProgramSpan, StyledProgramInput, StyledWrapper, StyledProgramLi
, StyledProgramUl, StyledPrograms, StyledChannel, StyledChannelsContainer, StyledEpgContainer, StyledFooterContainer, StyledBodyContainer, StyledHeaderContainer, StyledContainerDiv} from "./EpgAdministrationStyle"

const BASEURL = config.url

const TOKEN = config.token


const StyledNavigationContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`

const StyledNavigations = styled.div`
display: flex;
height: 85%;
width: 50%;
justify-content:  space-evenly;
align-items: center;
flex-wrap: wrap;
color: white;
font-weight: bold;
font-size: 3vmin;
`

const StyledNavigation = styled.div`
display: flex;
height: 40%;
width: 40%;
border: 1px solid black;
align-items: center;
justify-content: center;

color: white;
font-weight: bold;
font-size: 3vmin;

border-radius: 4px;
transition: box-shadow .3s;
&:hover{
    cursor: pointer;





}

`

const StyledNavigationText = styled.span`
display: flex;
height: auto;
width: auto;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

    &:hover{
        cursor: pointer;
        border-bottom: 1px solid white;
    }
`



export default class EpgAdministrationPannels extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {



  }

  

  render () {


    function handleClick(e, name){
        e.stopPropagation()

        console.log(name)

        let redirection 

        if(name === "replay"){
        redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}${localStorage.getItem("username")}/${localStorage.getItem("access-token")}`
        }

        if(name === "clients"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}clients`
        }
        if(name === "new-programs"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}newprograms`
        }
        if(name === "profile"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}profile`
        }



       document.location = redirection

            
    }


    let resp = ( 
    <StyledContainerDiv>
        <StyledHeaderContainer>Administration Pannel</StyledHeaderContainer>
        <StyledBodyContainer>
            <StyledNavigationContainer>
                <StyledNavigations>
                    <StyledNavigation className="replay-box" onClick={(e) => {handleClick(e, "replay")}}>
                        <StyledNavigationText>Replay</StyledNavigationText>
                    </StyledNavigation>
                    <StyledNavigation className="clients-box" onClick={(e) => {handleClick(e, "clients")}}>
                        <StyledNavigationText>Clients</StyledNavigationText>
                    </StyledNavigation>
                    <StyledNavigation className="new-programs-box" onClick={(e) => {handleClick(e, "new-programs")}}>
                        <StyledNavigationText>New Programs</StyledNavigationText>
                    </StyledNavigation>
                    <StyledNavigation className="profile-box" onClick={(e) => {handleClick(e, "profile")}}>
                        <StyledNavigationText>Profile</StyledNavigationText>
                    </StyledNavigation>
                </StyledNavigations>
            </StyledNavigationContainer>
           
        </StyledBodyContainer>
        <StyledFooterContainer></StyledFooterContainer>

    </StyledContainerDiv>
    )


   
  return resp;
  }

}
