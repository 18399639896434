import React from "react";
import * as style from "./StyledHours"


const createSchedule = () => {
  let resp = []
  for(let i = 0; i < 24 ; i ++){
    for(let y = 0; y < 2 ; y ++){
      resp.push(<style.Styledli key={`${i}-${y}`}><span>{(i < 10 ? ("0"+i) : i) + ":" + (y === 1 ? "30" : "00")}</span></style.Styledli>)
    }
  }
  return resp
}

export default function Hours() {
  return (
    <style.StyledHours id="hours" className="hours">
      <style.StyledUl>
        { createSchedule() }
      </style.StyledUl>
    </style.StyledHours>
  );
}
