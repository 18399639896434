import styled from "@emotion/styled";


//Computer
export const StyledAppHeader = styled.div`
  background-color: #481d24;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 8;
  height: 210px;
  /*min-height: 210px;
  max-height: 210px;*/

`;
export const StyledHeader = styled.div`
  position: fixed;
  top: 0px; 
  left: 0px; 
  background-color: white;
  height: 70px;
  width: 100%;
  z-index: 8;

  @media all and (max-width: 609px) {
    display:flex;
    justify-content:flex-start;
    height: 50px;
  }
`;

export const StyledHeaderTest = styled.div`
position: sticky;


`;

export const StyledLogo = styled.img`
  
  width: auto;
  height: auto;
  margin-top: 0px;
  margin-left: 0%;
  position: fixed;
  max-height: 70px;
  left: 30px;
  background-color: none;
 
`;

export const StyledSelector = styled.div`
position: absolute;
display: flex;
margin-top: 87px;
height: 67px;
width: 280px;
z-index: 10;
`;

export const StyledOperatorList = styled.div`
  display: flex;
  position: fixed;
  left: 50px;
  width: 200px;
  height: 30px;
  z-index: 10;
  align-items: center;
`;

export const StyledLanguageSelection= styled.label`  
    display: flex;
    position: fixed;
    top: 123px;
    left: 50px;
    width: 200px;
    z-index: 10;
    height: 30px;


;
`;

export const StyledLanguageLabel= styled.label`
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

export const StyledOperatorLabel= styled.label`
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

export const StyledOperatorSelect = styled.select`
  border-radius: 5px;
  z-index: 20;

`

export const StyledLanguageSelect = styled.select`
  border-radius: 5px;
  z-index: 20;

`

export const StyledPointerHours= styled.div`
    display: flex;
    position: absolute;
    left: 100%;
    bottom: 26px;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 15px solid white;
    z-index: 10;

`;


//Mobile
export const StyledAppHeaderM = styled.div`
  position: fixed;
  top: 0%;
  left: 0px;
  z-index: 10;
  height:20%;
  max-height: 30%;
  padding-bottom: 4.3%;
  
`;

export const StyledHeaderM = styled.div`
position: relative;

left: 0px; 
  background-color: white;
  height: 50%;
  width: 100%;
  z-index: 5;

`;


export const StyledSelectorContainer = styled.div `
  display: flex;
  margin-bottom: 4%;
  height: 30%;
  z-index: 5;

`
export const StyledLanguageSelectionContainerM = styled.div`
  display: flex;
  background-color: white;
  flex:  1 1 50%;
  z-index: 5;

`
export const StyledLanguageSelectionM = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
  background-color: #e4451c;
  & > select {
    width: 100%;
    text-align: center;
    text-align-last: center;
    font-size: 2vmin;

  }

`
export const StyledLogoM = styled.img`
  width: auto;
  height: auto;
  margin-left: -3px;
  margin-top: 3px;
  position: fixed;
  max-height: 9%;
  max-width: 50%;
  left: 30px;


`;

export const StyledOperatorSelectionContainerM = styled.div`
  display: flex;
  background-color: grey;
  flex:  1 1 50%;
  z-index: 5;

`
export const StyledOperatorSelectionM = styled.div`
  display: flex;
  width: 100%;
  z-index: 5;
  background-color: #e4451c;

  & > select {
    width: 100%;
    text-align: center;
    text-align-last: center;
    font-size: 2vmin;

  }

`


