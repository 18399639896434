import styled from "@emotion/styled";
import conf from "../../jsonFiles/config.json"



export const StyledReplayLogoContainer = styled.div `
display: flex;
background-color: #6b773c;
  padding: 5px 2px 5px 5px;
  height: auto;
  width: auto;
  border-radius: 5px;
  margin-bottom: 10px;
`

export const StyledReplayLogo = styled.div `
display: flex;
    width: 0px;
    height: 0px;
    border-left: 18px solid whitesmoke;
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
    border-radius-top: 10px;
    border-radius-bottom: 10px;

`

export const StyledFlexChannelDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  height: 109px;
  min-width: 100px;
  max-width: 160px;
  min-height: 80px;
  max-height: 110px;
  background-color: #3f3b3b;
  position: sticky;
  left: 0px;
  z-index: 6;
  flex-shrink:0;

  @media (max-width: ${conf.mobileSize}px) {
    width: 120px;
    height: 100px;
    min-width: 100px;
    max-width: 120px;
    min-height: 100px;
    max-height: 100px;
  }
`;

export const StyledFlexChannelDivM = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  height: 100%;
  min-width: 30%;
  max-width: 30%;
  min-height: 100%;
  max-height: 100%;
  background-color: #3f3b3b;
  position: sticky;
  left: 0px;
  z-index: 6;
  flex-shrink:0;

`;

export const StyledFlexContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  border-right: 1px solid black;
  
`;

export const StyledFlexContainerDivM = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 100%;
  max-width: 221px;
  height: 100%;

`;

export const StyledImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /*background-color: #3f3b3b;*/
  padding: 0px 0px 0px 0px;
  flex-basis: 65%;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  min-height:73px;
  max-height: 73px;

  @media (max-width: ${conf.mobileSize}px) {
    min-height: 73px;
    max-height: 73px;
  }
`;

export const StyledImageDivM = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /*background-color: #3f3b3b;*/
  padding: 0px 0px 0px 0px;
  flex-basis: 65%;
  border-bottom: 1px solid black;
  min-height: 65%;
  max-height: 65%;


`;

export const StyledImage = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 72px;
  max-width: 102px;
  padding: 0;
  margin: 0;
 /* mix-blend-mode: lighten;*
`;

export const StyledImageM = styled.img`
  display: flex;
  height: 100%;
  width: 80%;
  max-height:  100%;
  padding: 0;
  margin: 0;
 /* mix-blend-mode: lighten;*/
`;

export const StyledFlexNumberDiv = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 20%;
  flex-grow: 1;
  justify-content: center;
  color: white;
  font-weight: bold;
  /*background-color: #3f3b3b;*/
  border-bottom: 1px solid black;
  min-height:73px;
  max-height: 73px;
`;

export const StyledFlexNumberDivM = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 20%;
  flex-grow: 1;
  justify-content: center;
  color: white;
  font-weight: bold;
  /*background-color: #3f3b3b;*/
  border-bottom: 1px solid black;
  min-height:65%;
  max-height: 65%;
  font-size: 3vmin;

`;

export const StyledTitleDiv = styled.div`
  display: flex;
  margin-left: 0px;
  flex-direction: row;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: center;
  color: white;
  font-weight: bold;
  min-height:36px;
  max-height: 36px;
`;

export const StyledTitleDivM = styled.div`
  display: flex;
  margin-left: 0px;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: center;
  font-weight: bold;
  height:35%;
  min-height:35%;
  max-height: 35%;
  
  & > a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3vmin;

    text-decoration: none
  }
`;

export const StyledShowDescription = styled.h4`
  display: inline-block;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
  height: auto;
  text-align: center;
`;



/************** */


export const StyledFlexShowContainerM = styled.div`
  display: flex;
  width: 100%;
  background-color: #3f3b3b;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-left: 1px solid black;
`;

export const StyledFlexProgramsM = styled.div`
  display: flex;
  flex: 1 1 100%;
  width: 90%;
  height: 30%;
  align-items: center;
  margin: 0 5% 5% 5%;
  font-size: 12px;
  justify-content: center;

  &:first-child {
    margin-top: 5%;
  }
`;

export const StyledShowDescriptionM = styled.div`
display: flex;
flex-wrap: nowrap;
height: 40%;
z-index: 5;

`;
export const StyledFlexProgramsTitleM = styled.span`
  display: inline;
  height: auto;
  width: auto;
  & > a {
    color: white;
    font-size: 3vmin;
    text-decoration: none;
  }
  & > a:hover {
    border-bottom: solid 2px white;
  }
`;

export const StyledFlexProgramsScheduleM = styled.span`
display: inline;
margin-left: 20px;
margin-right: 20px;
height: auto;
width: auto;


  & > a {
    color: white;
    font-size: 3vmin;
    text-decoration: none;
  }
  & > a:hover {
  }

`;



