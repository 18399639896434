import React from "react";
import Navigation from "../days/Navigation";
import Hours from "../hours/Hours";
import conf from "../../jsonFiles/config.json"
import { Link } from "react-router-dom";
import * as style from "./StyledHeader";


export default class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      operators : "default",
      language : "french",
      logo: "https://firebasestorage.googleapis.com/v0/b/epg-poc-297c3.appspot.com/o/logo-packarabia.png?alt=media&token=a859b012-b95e-4db9-80ed-738895dce3ca"
    }
    this.operatorsChange = this.operatorsChange.bind(this)
    this.languageChange = this.languageChange.bind(this)
  }

  operatorsChange (e) {
    this.setState({ operators: e.target.value })
  }

  languageChange (e) {
    this.setState({ language: e.target.value })
  }

  render () {

    const website = "https://packarabia.tv/"

    let computerVersion = (
    <style.StyledAppHeader>
      <style.StyledHeader>
      <Link onClick={(e) => {window.location.assign(website);}}><style.StyledLogo src={this.state.logo}></style.StyledLogo></Link>
        <style.StyledSelector>
          <style.StyledOperatorList>
          <style.StyledOperatorLabel htmlFor="operators"><span>Operateur :</span></style.StyledOperatorLabel>
            <style.StyledOperatorSelect className="operators" value={this.state.operators} onChange={this.operatorsChange}>
              <option value="default">Tous</option>
              <option value="free">Free</option>
              <option value="sfr">Sfr</option>
              <option value="orange">Orange</option>
              <option value="bouygues">Bouygues</option>
            </style.StyledOperatorSelect>
          </style.StyledOperatorList>
          <style.StyledLanguageSelection>
          <style.StyledLanguageLabel htmlFor="language">Langue  :</style.StyledLanguageLabel>
            <style.StyledLanguageSelect className="language" value={this.state.language} onChange={this.languageChange}>
              <option value="french">Français</option>
              <option value="arabic">العربية</option>
            </style.StyledLanguageSelect>
          </style.StyledLanguageSelection>
          </style.StyledSelector> 
      </style.StyledHeader>
      <Navigation index={this.props.index} />
      <style.StyledPointerHours className="pointerHours" />
      <Hours />
    </style.StyledAppHeader>)


    let mobileVersion = (
      <style.StyledAppHeaderM className="mobile-app-header">
        <style.StyledHeaderM>
          <Link onClick={(e) => {window.location.assign(website);}}><style.StyledLogoM src={this.state.logo}></style.StyledLogoM></Link>
        </style.StyledHeaderM>
        <Navigation />
        <style.StyledSelectorContainer>
          <style.StyledLanguageSelectionContainerM>
          <style.StyledLanguageSelectionM>
            <select className="language" value={this.state.language} onChange={this.languageChange} content="user-scalable=no">
              <option value="french">Français</option>
              <option value="arabic">العربية</option>
            </select>
          </style.StyledLanguageSelectionM>
        </style.StyledLanguageSelectionContainerM>
        <style.StyledOperatorSelectionContainerM>
          <style.StyledOperatorSelectionM>
          <select className="operators" value={this.state.operators} onChange={this.operatorsChange} content="user-scalable=no">
              <option value="default">Tous</option>
              <option value="free">Free</option>
              <option value="sfr">Sfr</option>
              <option value="orange">Orange</option>
              <option value="bouygues">Bouygues</option>
            </select>
          </style.StyledOperatorSelectionM>
        </style.StyledOperatorSelectionContainerM>
        </style.StyledSelectorContainer>
      </style.StyledAppHeaderM>)

    return window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion;
  }




}

