import styled from "@emotion/styled";


var style = getComputedStyle(document.body)

export const StyledNavigation = styled.div`
  display: flex;
  position: fixed;
  background-color: ${style.getPropertyValue('--header-second-color')};
  height: 110px;
  margin-top: 65px;
  top: 0px; 
  z-index: 6;
 
`;

export const StyledNavigationWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 10;
  width: 65%;
  position: sticky;
  z-index: 8;
`;

export const StyledUl = styled.ul`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  justify-content: center;
  z-index: 10;
  margin-left: 40px;
  z-index: 8;

`;

export const Styledli = styled.li`
  display: flex;
  padding-left: 10px;
  font-size: 20px;
  margin-bottom: 40px;
  width: 15%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative;
  z-index: 8;


  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: white;
    z-index: 10;

  }
  & > a:hover{
    background-color: ${style.getPropertyValue('--header-third-color')};
    border: 1px solid ${style.getPropertyValue('--header-third-color')};
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: underline;
  }

  & > a > span {
    width: auto;
    height: auto;
  }
 
`;

export const StyledNavigationContainerM = styled.div`
display:flex;
position: relative;
  width: 100%;
  background-color: ${style.getPropertyValue('--header-second-color')};
  height: 50%;
  min-height: 50%;
  max-height: 50%;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const StyledNavigationTestReplayContainer = styled.div`
  display: flex;
  top: 0px;
  align-items: center;
  height: 100%;
  z-index: 1;
  width: 20%;
  max-width: 250px;
  min-width: 205px;
  margin-left: 310px;
  margin-right: 50px;

`

export const StyledNavigationTestReplay = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-position: row;
  border: 10px black whitesmoke;
  border-radius: 10px;
  background-color: white;
  position: relative;
  right: 110px;
  height: 75px;
  width: 100%;
  z-index: 25;
left: 0px;
& > span {
  display: inline;
  text-align: center;
  width: auto;
  height: auto;
  font-weight: bold;
  margin: 5px;

}
`;

export const StyledReplayLogoContainer = styled.div `
display: flex;
background-color: #6b773c;
  padding: 5px 2px 5px 5px;
  height: auto;
  width: auto;
  border-radius: 5px;
  margin: 15px 2px 15px 15px;

`

export const StyledReplayLogo = styled.div `
display: flex;
    width: 0px;
    height: 0px;
    border-left: 22px solid whitesmoke;
    border-bottom: 13px solid transparent;
    border-top: 13px solid transparent;
    border-radius-top: 10px;
    border-radius-bottom: 10px;


`

export const StyledDaySpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top: 5%;
`

export const StyledMonthSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  font-size: 80%;
  
`



/*Test Carousel */

export const StyledCarouselDayDiv = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 90%;
  justify-content: center;
  align-items: center;
`

export const StyledDayContainerDiv = styled.div`
  display: flex;
  background-color: pink;
  height: 50%;
  width: 50%;
  justify-content: center;
  align-items: center;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    text-decoration: none;
    font-weight: bold;
    font-size: 4vmin;

  }
`
/*
export const StyledCarouselLeft = styled.a`
display: flex;
justify-content: center;
align-items: center;
background-color: red;

`

export const StyledCarouselRight = styled.a`
display: flex;
justify-content: center;
align-items: center;
background-color: grey;

`
*/

export const StyledCarouselDaySpan = styled.div`
display: flex;
background-color: orange;
justify-content: center;
align-items: center;
`