import styled from "@emotion/styled";


export const StyledNavigationContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;
background-color: grey;

`

export const StyledNavigations = styled.div`
display: flex;
height: 85%;
width: 70%;
align-items: center;
flex-wrap: wrap;
color: white;
font-weight: bold;
font-size: 3vmin;
background-color: green;

`

export const StyledClientsContainer = styled.div`
display: flex;
height: 100%;
width: 50%;
justify-content:  center;
align-items: center;
flex-direction: column;
background-color: #333030;

`

export const StyledClientsHeader = styled.div`
display: flex;
height: 10%;
width: 100%;
justify-content:  center;
align-items: center;
background-color: #333030;
border-top: 2px solid black;
border-bottom: 2px solid black;
border-bottom: 2px solid black;

`


export const StyledClientsBody = styled.div`
display: flex;
height: 90%;
width: 100%;
align-items: center;
flex-direction: column;
background-color: #5d5959;
border-left: 1px solid black;
border-bottom: 1px solid black;
position: relative;

`

export const StyledClientsBodyContainer = styled.div`
display: flex;
height: auto;
width: 100%;
align-items: center;
flex-direction: row;
background-color: pink;
margin-bottom: 5px;
`

export const StyledChannelsBody = styled.div`
display: flex;
height: 90%;
width: 100%;
flex-direction: column;
background-color: #4d4d4d;;

`

export const StyledChannelsList = styled.div`
flex-grow: 1;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
flex-direction: row;
align-content: flex-start;
height: 90;
width: 100%;
background-color: #4d4d4d;;

`

export const StyledClientsUsername = styled.span`
display: flex;
background-color: #4d4d4d;
height: auto;
width: 80%;
justify-content:  center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
     filter: brightness(80%);
 }

`

export const StyledDeleteClient = styled.div`
display: flex;
height: 100%;
width: 20%;
font-size: 3vh;
font-weight: bold;
justify-content: center;
align-items: center;
background-color: red;

&:hover{
    cursor: pointer;
    filter: brightness(80%);

}
`

export const StyledClientsChannels = styled.span`
display: flex;
background-color: green;
height: auto;
width: 100%;
justify-content:  center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
 }
`


export const StyledAddClientButton = styled.button`
display: flex;
position: absolute;
bottom: 0;
left: 0;
background-color: green;
color: white;
height: 7%;
width: 30%;
border-radius: 4px;
padding:0px;
font-size: 30px;
justify-content:  center;
align-items: center;
border-bottom: 2px solid black;

 &:hover{
     cursor: pointer;
     background-color: #199C02;

 }
`

export const StyledAddClientContainer = styled.form`
display: none;
position: absolute;
height: 90%;
width: 100%;
grid-template-columns: repeat(10, 1fr);
grid-template-rows: repeat(15, 1fr);
background-color: #333030;

`

export const StyledAddClientTitle = styled.span`
grid-column: 1 / -1;
grid-row: 1 / 1;
display: grid;
justify-content: center;
align-items: center;
height:100%;
width:100%;
border: 2px solid black;
background-color: #8ec26a;

`


export const StyledFieldContainer = styled.div`
display: flex;
background-color: green;
height:auto;
width:50%;
`



//Username
export const StyledAddClientLabelUsername = styled.label`
grid-column: span 2;
grid-row: 3/3;
display: grid;
align-content: center;
justify-content: center;
color: white;
font-size: 70%;

`
export const StyledAddClientInputUsername = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 3/3;
height: 70%;

`

//Email
export const StyledAddClientLabelEmail = styled.label`
grid-column: span 2;
grid-row: 5/5;
display: grid;
align-content: center;
justify-content: center;
font-size: 70%;
color: white;
`
export const StyledAddClientInputEmail = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 5/5;
height: 70%;

`

export const StyledSpanUsernameInUse = styled.span`
display: grid;
grid-column: span 4;
grid-row: 3/3;
align-self: center;
background-color: red;
height: 70%;
font-size: 50%;
margin-left: 10px;
width: auto;
visibility: hidden;

`
export const StyledSpanEmailInUse = styled.span`
display: grid;
grid-column: span 4;
grid-row: 5/5;
align-self: center;
background-color: red;
height: 70%;
font-size: 50%;
margin-left: 10px;
width: auto;
visibility: hidden;

`

//Password
export const StyledAddClientLabelPassword = styled.label`
grid-column: span 2;
grid-row: 7/7;
display: grid;
align-content: center;
justify-content: center;
color: white;
font-size: 70%;
`
export const StyledAddClientInputPassword  = styled.input`
display: grid;
align-self: center;
grid-column: span 3;
grid-row: 7/7;
height: 70%;
`

export const StyledAddClientSubmit = styled.button`
grid-column: 8/10;
grid-row: 9/9;
height: 100%;
background-color: #2babff;
border-radius: 4px;
filter: brightness(120%);
border: 2px solid black;
&:hover{
    cursor: pointer;
    filter: brightness(80%);
    border-color:#FFFFFF;
    color: white;
}
`

export const StyledAddClientInfoBox = styled.div`
grid-column: 1/-1;
grid-row: 11/-1;
background-color: green;
`

export const StyledContainerDiv = styled.div`
display: flex;
height: 100%;
width: 100%;
background-color: white;
flex-direction: column
`

export const StyledHeaderContainer = styled.div`
display: flex;
height: 10%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`

export const StyledBodyContainer = styled.div`
display: flex;
height: 85%;
width: 100%;
background-color: grey;
align-items: center;

`
export const StyledFooterContainer = styled.div`
display: flex;
height: 5%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`
