import React from "react";
import localChannels from "../../jsonFiles/localChannels.json";
import { getMinutes, getDay} from "../../data/TimeRelatedData"
import categoriesTranslation from "../../jsonFiles/categoriesTranslation.json";
import "../../../../style.css";
import * as style from "./StyledChannelPageMobile"
import { getData } from "../computer/EpgLogic"; 
import channelsCorrection from "../../jsonFiles/channelsCorrection.json";

export default class ChannelPageMobile extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        test: ["1","1",",","2"],
        liveShows: props.liveShows,
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substring(0,2) : localStorage.setItem("language", (navigator.language).substring(0,2)  === "ar" || (navigator.language).substring(0,2)  === "AR" ? "ar" : "fr") && localStorage.getItem("language")
      }
    }

  componentDidMount () {
    let url = (window.location.href).split("/")
    let channelCode = url[url.length - 4]
    let dayNum = url[url.length - 1]

    this.setState({day: dayNum})

    if(!localStorage.getItem("language")){
      localStorage.setItem("language", this.state.userLanguage === "ar" || this.state.userLanguage === "AR" ? "arabic" : "french")
    }
    let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage

    
    //get the replay 
    try{
      getData(selectedLanguage, this.state.day, true)
      .then((response)=>{
        return response
      })
      .then((resp)=>{
        //filter the response to get only the needed channels 
        this.setState({replayPrograms : resp[0].channels.filter(x => x.name === channelCode)})
        //get the channel matching the data passed into the url
    
        getData(selectedLanguage, parseInt(dayNum))
        .then((response)=>{
          return response.filter(channel => channel.code === channelCode)
        })
        .then((res)=>{
          console.log(res)
          this.setState({channels: res[0], channelProgram: res[0].programs });
        })
        .then((res)=>{
          //check the language to see if the text on the card should be from Right to Left or Left to Right
          if(this.state.userLanguage === "ar"){
            document.querySelectorAll(".modal-description").forEach(el => { el.style.alignItems = "flex-end" })
            document.querySelectorAll(".modal-synopsis").forEach(el => { el.style.textAlign = "end" })
          }else{
            document.querySelectorAll(".modal-description").forEach(el => { el.style.alignItems = "" })
            document.querySelectorAll(".modal-synopsis").forEach(el => { el.style.textAlign = "" })
          }
          
          //Add differents click listener for the thumbnails, the programs title and the schedule to show the card of the programs
          document.querySelectorAll(".myBtn").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          document.querySelectorAll(".programs-schedule").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.previousSibling.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.innerText).substr(0,5).replace(':', '-')}-${(e.target.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          document.querySelectorAll(".thumbnail-image").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.parentElement.nextSibling.firstChild.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          //Remove the card on click
          document.querySelectorAll(".close").forEach(el => 
            el.addEventListener("click", (e)=>{
              document.querySelectorAll(".modal").forEach(el => {
                    el.style.display = "none";
              })
            })
          )
          window.onclick = function(event) {
            document.querySelectorAll(".modal").forEach(el => {
              if (event.target === el) { el.style.display = "none"; }
            })
          }
        })
      })
      .catch(e=>{
        getData(selectedLanguage, parseInt(dayNum))
        .then((response)=>{
          return response.filter(channel => channel.code === channelCode)
        })
        .then((res)=>{
          console.log(res)
          this.setState({channels: res[0], channelProgram: res[0].programs });
        })
        .then((res)=>{
          //check the language to see if the text on the card should be from Right to Left or Left to Right
          if(this.state.userLanguage === "ar"){
            document.querySelectorAll(".modal-description").forEach(el => { el.style.alignItems = "flex-end" })
            document.querySelectorAll(".modal-synopsis").forEach(el => { el.style.textAlign = "end" })
          }else{
            document.querySelectorAll(".modal-description").forEach(el => { el.style.alignItems = "" })
            document.querySelectorAll(".modal-synopsis").forEach(el => { el.style.textAlign = "" })
          }
          
          //Add differents click listener for the thumbnails, the programs title and the schedule to show the card of the programs
          document.querySelectorAll(".myBtn").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          document.querySelectorAll(".programs-schedule").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.previousSibling.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.innerText).substr(0,5).replace(':', '-')}-${(e.target.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          document.querySelectorAll(".thumbnail-image").forEach(el => 
            el.addEventListener("click", (e)=>{
            let classModal = `.modal-${((e.target.parentElement.nextSibling.firstChild.innerText).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(0,5).replace(':', '-')}-${(e.target.parentElement.nextSibling.firstChild.nextSibling.innerText).substr(8,5).replace(':', '-')}`
            document.querySelector(classModal).style.display = "block";
            })
          )

          //Remove the card on click
          document.querySelectorAll(".close").forEach(el => 
            el.addEventListener("click", (e)=>{
              document.querySelectorAll(".modal").forEach(el => {
                    el.style.display = "none";
              })
            })
          )
          window.onclick = function(event) {
            document.querySelectorAll(".modal").forEach(el => {
              if (event.target === el) { el.style.display = "none"; }
            })
          }
        }).catch(e=> console.log(e))
      })
    }catch(e){
      console.log(e)
    }
    
  }

  
  render () {
    
    let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()
    let channelTitleToCorret = ["MBC Plus Drama", "IqraaInternational"]
   

    const goBack = () => { window.history.go(-1); }

    const checkIfProgramInReplayIsActive = (start, end) => {
      let actualDate = new Date()

      let paramYear = start.substr(0,4)
      let paramMonth = (parseInt(start.substr(5,2)) - 1)
      let paramDay = start.substr(8,2)

      let paramDate = new Date()
      paramDate.setFullYear(paramYear)
      paramDate.setMonth(paramMonth)
      paramDate.setDate(paramDay)

      let param2Year = end.substr(0,4)
      let param2Month = (parseInt(end.substr(5,2)) - 1)
      let param2Day = end.substr(8,2)

      let param2Date = new Date()
      param2Date.setFullYear(param2Year)
      param2Date.setMonth(param2Month)
      param2Date.setDate(param2Day)

      if(paramDate <= actualDate && actualDate <= param2Date){ return true;}
      else{ return false; }
    }

    let chanelLocalLogo = ""         
    localChannels.channels.map(localChannelX => {
        if(this.state.channels.code === localChannelX.name){
            chanelLocalLogo = localChannelX.thumbnailsMobile
        }
    })

    let defaultImage = "https://firebasestorage.googleapis.com/v0/b/epg-poc-297c3.appspot.com/o/noThubmnailsDefaultBG.jpg?alt=media&token=494947f1-8338-4c0b-891b-869e2ed00509"        
    
    console.log(this.state.channels)
    let mobileVersion = ( 
      <style.StyledAppHeader>
        <style.ButtonContainer>
            <style.StyledBackSymbolContainer>
              <style.StyledBackSymbol></style.StyledBackSymbol>
            </style.StyledBackSymbolContainer>
            <style.StyledBackSpan onClick={(e) => {goBack(e)}}>Retour</style.StyledBackSpan>
        </style.ButtonContainer>
        <style.StyledHeader>
          <style.StyledChannel>
            <style.StyledChannelImage src={chanelLocalLogo} />
            <style.StyledChannelTitle>
              <style.StyledTitle>{
                channelsCorrection.hasOwnProperty(this.state.channels.title) ? channelsCorrection[this.state.channels.title] : this.state.channels.title
              }</style.StyledTitle>
            </style.StyledChannelTitle>
          </style.StyledChannel>
      </style.StyledHeader>
        <style.StyledProgramsContainer className="programs-container">
          {

            this.state.channels ? 
            this.state.channelProgram.map(x => {
              let programOnReplay = false;
              //to handle weird data from ATOM
              if(x.start.substr(11,5) === "0:00" && x.end.substr(11,5) === "00:00"){ return }

              
              try{
              this.state.replayPrograms.map(channelR => {
                this.state.userLanguage === "fr" ? 
                channelR.programs.fr.map(programR => {
                  let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                  if(programR.title === x.title && bool){ programOnReplay = true; }
                }) : 
                channelR.programs.ar.map(programR => {
                  let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                  if(programR.title === x.title && bool){ programOnReplay = true; }
                }) 
              })
              }catch(e){
                console.log(e)
              }
              
              //Option either random choices or just the first category or a list of superiority between categories like Series > to Show
              let toExclude = ["Khaliji"]
              let categories = (x.category.split(",").filter(x => !toExclude.includes(x)))
              if(categories.length > 1){ categories.shift() }

              let randomCategory = categories[Math.floor(Math.random() * categories.length)]

              categoriesTranslation.categories.forEach(x => {
                if(x.categorie == randomCategory){
                  randomCategory = this.state.userLanguage === "fr" ? x.fr : x.ar
                } 
              })

              return( 
              <>
                <style.StyledProgram>
                  <style.StyledThumbnailContainer>
                    <style.StyledThumbnail className="thumbnail-image" src={x.thumbnail === "" ? defaultImage : x.thumbnail} sizes />
                    <style.StyledThumbnailCategories
                      style={{ visibility: x.thumbnail === "" ? "visible" : "hidden" }}
                    >{randomCategory}</style.StyledThumbnailCategories>
                  </style.StyledThumbnailContainer>
                  <style.StyledDescriptionContainer>
                    <style.StyledProgramTitle className="myBtn"
                      style={{
                        fontWeight: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && getMinutes(x.end.substr(11, 16).toString()) > getMinutes(timeInMinutes)? 
                        "bold" : ""
                      }}
                    >{x.title} </style.StyledProgramTitle>
                    <style.StyledProgramSchedule className="programs-schedule">{(x.start.substr(11,5) ==="0:00"? "00:00" : x.start.substr(11,5) )+ " - " + x.end.substr(11,5)}</style.StyledProgramSchedule>
                    <style.StyledProgramLiveContainer
                      style={{
                        visibility: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                        "visible" : "hidden"
                      }}>
                      <style.StyledProgramLiveSymbolContainer>
                        <style.StyledProgramLiveSymbol></style.StyledProgramLiveSymbol>
                      </style.StyledProgramLiveSymbolContainer>
                      <style.StyledProgramLiveTextContainer>
                        <style.StyledProgramLiveText>LIVE</style.StyledProgramLiveText>
                      </style.StyledProgramLiveTextContainer>
                    </style.StyledProgramLiveContainer>
                    <style.StyledProgramReplayContainer
                  style={{
                    borderRight: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                    "" : "2px solid black",
                    width: getMinutes(timeInMinutes) > getMinutes(x.start.substr(11, 16).toString()) && (getMinutes(x.end.substr(11, 16).toString())+1) >= getMinutes(timeInMinutes)? 
                    "" : "100%",
                    visibility: programOnReplay ? "visible": "hidden"
                  }}>
                  <style.StyledReplayLogoContainer>
                    <style.StyledReplayLogo />
                  </style.StyledReplayLogoContainer>
                  <style.StyledProgramReplayTextContainer>
                    <style.StyledProgramReplayText>MY ARABIA</style.StyledProgramReplayText>
                  </style.StyledProgramReplayTextContainer>
                </style.StyledProgramReplayContainer>
                  </style.StyledDescriptionContainer>      
                </style.StyledProgram>
                <div class={`modal-${((x.title).replace(/ /g,"-")).replace(/[^A-Za-z0-9]/g, "-")}-${(x.start.substr(11,5)==="0:00"? "00:00": x.start.substr(11,5)).replace(':', '-')}-${(x.end.substr(11,5)).replace(':', '-').replace(",","-")} modal`}>
                  <div class="modal-content">
                    <span class="close">&times;</span>
                    <div class="modal-thumbnail-container">
                    <img class="modal-thumbnail" src={x.thumbnail === "" ? defaultImage :x.thumbnail}/>
                    <style.StyledThumbnailCategories
                        style={{
                          visibility: x.thumbnail === "" ? "visible" : "hidden",
                          fontSize: "8vmin"
                        }}
                      >{randomCategory}</style.StyledThumbnailCategories>

                    </div>
                    <div class="modal-description-container">
                      <div class="modal-description">
                      <span class="modal-title">{x.title}</span>
                      <ul class="modal-categories-list">                   
                        {
                          //Filter some unwanter categories Name
                          x.category.split(",").filter(x => x !== "Khaliji").map(x => {
                            let translatedCategory;
                                categoriesTranslation.categories.forEach(y=> {
                                  if(y.categorie === x){
                                    translatedCategory = this.state.userLanguage === "fr" ? y.fr : y.ar
                                  } 
                                })
                              
                            return <li>{translatedCategory}</li>
                          })
                        }
                      </ul>
                      <span class="modal-schedule">{getDay(parseInt(this.state.day), this.state.userLanguage)} {x.start.substr(11,5).replace(":","h")}</span>
                      <span class="modal-length">{this.state.userLanguage === "ar" ? "المدة " +x.duration+ " دقيقة": "Durée " +x.duration+ " minutes"}</span>
                      <span class="modal-synopsis">{x.description}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              )}) : console.log("Programs Error")
          }     
        </style.StyledProgramsContainer>
      </style.StyledAppHeader>
    )

  return mobileVersion;
  }
}

