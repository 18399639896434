import styled from "@emotion/styled";


export const StyledContainerDiv = styled.div`
display: flex;
height: 100%;
width: 100%;
background-color: white;
flex-direction: column
`

export const StyledHeaderContainer = styled.div`
display: flex;
height: 10%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;
`

export const StyledBodyContainer = styled.div`
display: flex;
height: 85%;
width: 100%;
background-color: grey;
align-items: center;
justify-content: center;
`

export const StyledFooterContainer = styled.div`
display: flex;
height: 5%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;
`

export const StyledLoginBoxContainer = styled.div`
display: flex;
height: 70%;
width: 40%;
align-items: center;
justify-content: center;
flex-direction: column;
border: 5px solid black;
`

export const StyledLoginBoxHeader = styled.div`
display: flex;
height: 20%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
font-size: 3vmin;
font-weight: bold;
color: white;
`

export const StyledLoginBoxBody = styled.div`
display: flex;
height: 80%;
width: 100%;
background-color: #3f3b3b;
flex-direction: column;
`

export const StyledUsernameSpan = styled.span`
display: flex;
height: auto;
width: 70%;
margin-top: 10%;
margin-bottom: 2%;
margin-left: 5%;
font-size: 2vmin;
font-weight: bold;
color: white;
`

export const StyledUsernameInput = styled.input`
display: flex;
height: 10%;
width: 70%;
background-color: grey;
margin-left: 5%;
font-size: 2vmin;
cursor: pointer;
`

export const StyledPasswordSpan = styled.span`
display: flex;
height: auto;
width: 70%;
margin-top: 5%;
margin-bottom: 2%;
margin-left: 5%;
font-size: 2vmin;
font-weight: bold;
color: white;
`

export const StyledPasswordInput= styled.input`
display: flex;
height: 10%;
width: 70%;
background-color: grey;
margin-left: 5%;
font-size: 2vmin;
cursor: pointer;

`

export const StyledLoginButton = styled.button`
display: flex;
height: 10%;
width: 20%;
margin-top: 5%;
margin-bottom: 2%;
margin-left: 5%;
justify-content: center;
align-items: center;
border-radius: 4px;
background-color: green;
font-size: 2vmin;
font-weight: bold;
color: white;
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
transition: all 0.3s ease 0s;
cursor: pointer;
outline: none;

&:hover {

        background-color: #2EE59D;
        box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
        color: #fff;
        transform: translateY(-7px);
      
}
`