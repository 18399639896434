import { getMinutesT, getMinutes} from "../data/TimeRelatedData.js"

const enableScroll = () => {window.onscroll = function() {};}

//function to adjust the position and the size of each programs 
const adjustProgram = () => {
  try{
    document.querySelector(".flexbox-row-div").style.maxWidth = "unset"
    window.scrollTo(0,0); 
    document.querySelectorAll(".flexbox-show-div > .schedule").forEach((x, index) => {

      let time = x.textContent.substr(0, 8);
      const scheduleWidth = document.querySelector(".hours > ul > li").getBoundingClientRect().width
      let divXPosition = x.parentElement.getBoundingClientRect().x 
      let previousSiblingWidth = x.parentElement.previousSibling.getBoundingClientRect().width
      let previousSiblingX = x.parentElement.previousSibling.getBoundingClientRect().x

      //COMPARAISON BETWEEN THE POSITION OF THE PROGRAMS AND THE POSITION WERE HE SHOULD BE BY USING THE GRID SCHDEULE AND THE TIME OF THE PROGRAMS
      if ((divXPosition < (scheduleWidth * getMinutesT(time))) === true) {
        x.parentElement.style.marginLeft = (x.parentElement.previousSibling ? 
          (scheduleWidth * getMinutesT(time)) / 30 - previousSiblingWidth - previousSiblingX + 160 + "px" : 
          (scheduleWidth * getMinutesT(time)) / 30 + "px"); 
      }

      if(parseInt(x.parentElement.style.marginLeft) > 1){x.parentElement.style.borderLeft = "1px solid black";}
      
      if(getMinutesT(time) === 0){x.parentElement.style.marginLeft = "0px"}

      //Check the differents programs to see wich one need a border and if so were (left or right)
      if(x.parentElement.previousSibling){
        if(x.parentElement.previousSibling.lastChild.innerText.substr(10,8) === x.parentElement.lastChild.innerText.substr(0,8) && getMinutesT(time) !== 0){
          x.parentElement.style.borderLeft = "1px solid black";            
        }
        if(getMinutesT(time) !== 0 ){
          x.parentElement.style.borderLeft = "1px solid black";
        }
      }
      if(!x.parentElement.nextSibling){
        x.parentElement.style.borderRight = "1px solid black"
      }

      //Update the position of the pointer above the schedule
      setTimeout(() => {
        document.querySelector(".pointerHours").style.left = (document.querySelector(".hours > ul > li").getBoundingClientRect().width * 
        getMinutes((parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString())) / 30 + 160  - 8+ "px"
      }, 200)
      //Enable scroll
      setTimeout(() => {
        enableScroll()
      }, 400)
    });

    //Remove the loading animation if it is running
    if(document.querySelector(".loadingDataDiv")){
      setTimeout(()=>{
        document.querySelector(".loadingDataDiv").innerHTML = ""   
        document.querySelector(".loadingDataDiv").style.backgroundColor = "transparent" 
        document.querySelector(".loadingDataDiv").style.zIndex = "0" 
      })
    }
  }catch(e){
    console.log(e)
  }
}

export { adjustProgram}




