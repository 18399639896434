import * as style from "./StyledNavigation"
import { Link } from "react-router-dom";


export const getDays = (day="", number= "") => {

  if(number !== ""){
    let date = new Date();
    date.setDate(date.getDate() + number);
    day = date.getDay();
  }

  switch (day) {
    case 0:
      return "Dimanche";
    case 1:
      return "Lundi";
    case 2:
      return "Mardi";
    case 3:
      return "Mercredi";
    case 4:
      return "Jeudi";
    case 5:
      return "Vendredi";
    case 6:
      return "Samedi";
    default:
      return "Aujourd'hui";
  }
};

//get date format
export const getDate = (actualDatePlusDay = "", givenDate ="") => {
  let date = new Date();

  if(actualDatePlusDay !== ""){
    if (actualDatePlusDay !== 0 ) { date.setDate(date.getDate() + actualDatePlusDay); }
  }
  if(givenDate !== ""){ date.setDate(givenDate) }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (month < 10) { month = "0" + month.toString(); }
  if (day < 10) { day = "0" + day.toString(); }

  return `/${day}-${month}-${year}`;
};

export const getSpanDate = (number = "") => {
  let date = new Date();

  if (number !== 0 ) { date.setDate(date.getDate() + number); }

  let day = date.getDate();
  let month = date.getMonth() + 1;

  switch(month) {
      case 1:
      return `${day} janvier`;
      case 2:
      return `${day} fevrier`; 
      case 3:
      return `${day} mars`; 
      case 4:
      return `${day} avril`; 
      case 5:
      return `${day} mai`; 
      case 6:
      return `${day} juin`;
      case 7:
      return `${day} juillet`; 
      case 8:
      return `${day} août`; 
      case 9:
      return `${day} septembre`; 
      case 10:
      return `${day} octobre`;
      case 11:
      return `${day} novembre`; 
      case 12:
      return `${day} décembre`;
      default: 
      return `...`
  }
};

export const select = (e) => {}


//Mobile
export const carouselChange = () => {
  document.querySelector(".carouselNav").addEventListener("click", ()=> {
    document.querySelector(".carouselLeft").href = getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 0 ? 6: (parseInt(localStorage.getItem("Day")) -1):6, "")
    document.querySelector(".carouselRight").href = getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")
    document.querySelector(".carouselDaySpan").innerText= localStorage.getItem("Day") ? getDays("",parseInt(localStorage.getItem("Day"))): getDays()
  })
}

