import styled from "@emotion/styled";


export const StyledNavigationContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`

export const StyledNavigations = styled.div`
display: flex;
height: 85%;
width: 50%;
justify-content:  space-evenly;
align-items: center;
flex-wrap: wrap;
color: white;
font-weight: bold;
font-size: 3vmin;
`

export const StyledNavigation = styled.div`
display: flex;
height: 40%;
width: 40%;
border: 1px solid black;
align-items: center;
justify-content: center;

color: white;
font-weight: bold;
font-size: 3vmin;

border-radius: 4px;
transition: box-shadow .3s;
&:hover{
    cursor: pointer;





}

`

export const StyledNavigationText = styled.span`
display: flex;
height: auto;
width: auto;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

    &:hover{
        cursor: pointer;
        border-bottom: 1px solid white;
    }
`


export const StyledContainerDiv = styled.div`
display: flex;
height: 100%;
width: 100%;
background-color: white;
flex-direction: column
`

export const StyledHeaderContainer = styled.div`
display: flex;
height: 10%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`

export const StyledBodyContainer = styled.div`
display: flex;
height: 85%;
width: 100%;
background-color: grey;
align-items: center;

`
export const StyledFooterContainer = styled.div`
display: flex;
height: 5%;
width: 100%;
background-color: #333030;
align-items: center;
justify-content: center;
color: white;
font-weight: bold;
font-size: 3vmin;

`