import React, { useState, useEffect, Component } from "react";
import "../../../../style.css";
import config from "../../jsonFiles/config.json";
import * as style from "./StyledEpgAdministrationPannels"


const BASEURL = config.url

const TOKEN = config.token


export default class EpgAdministrationPannels extends React.Component {


  constructor (props) {
      super(props)
      this.state = {
        day : "",
        channels: [],
        channelProgram: [],
        replayPrograms: [],
        userLanguage: localStorage.getItem("language") ? localStorage.getItem("language").substr(0,2) : localStorage.setItem("language", (navigator.language).substr(0,2)  === "ar" || (navigator.language).substr(0,2)  === "ar"? "arabic" : "french") && localStorage.getItem("language")
      }
  }


  componentDidMount () {
  }

  

  render () {

    function handleClick(e, name){
        e.stopPropagation()

        let redirection 

        if(name === "replay"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}${localStorage.getItem("username")}/${localStorage.getItem("access-token")}`
        }
        if(name === "clients"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}clients`
        }
        if(name === "new-programs"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}newprograms`
        }
        if(name === "profile"){
            redirection = `${document.location.toString().slice(-1) == "/" ? document.location: document.location + "/"}profile`
        }

       document.location = redirection    
    }


    let resp = ( 
    <style.StyledContainerDiv>
        <style.StyledHeaderContainer>Administration Pannel</style.StyledHeaderContainer>
        <style.StyledBodyContainer>
            <style.StyledNavigationContainer>
                <style.StyledNavigations>
                    <style.StyledNavigation className="replay-box" onClick={(e) => {handleClick(e, "replay")}}>
                        <style.StyledNavigationText>Replay</style.StyledNavigationText>
                    </style.StyledNavigation>
                    <style.StyledNavigation className="clients-box" onClick={(e) => {handleClick(e, "clients")}}>
                        <style.StyledNavigationText>Clients</style.StyledNavigationText>
                    </style.StyledNavigation>
                    <style.StyledNavigation className="new-programs-box" onClick={(e) => {handleClick(e, "new-programs")}}>
                        <style.StyledNavigationText>New Programs</style.StyledNavigationText>
                    </style.StyledNavigation>
                    <style.StyledNavigation className="profile-box" onClick={(e) => {handleClick(e, "profile")}}>
                        <style.StyledNavigationText>Profile</style.StyledNavigationText>
                    </style.StyledNavigation>
                </style.StyledNavigations>
            </style.StyledNavigationContainer>
        </style.StyledBodyContainer>
        <style.StyledFooterContainer></style.StyledFooterContainer>
    </style.StyledContainerDiv>
    )

  return resp;
  }
}
