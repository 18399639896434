import localChannels from "../jsonFiles/localChannels.json";
import axios from "axios";
import axiosRetry from "axios";
import * as config from "../jsonFiles/config.json"

const domainName = config.url
const token = "token="+ config.token
const getPrograms = "getItemEPG?"


axiosRetry(axios, {
  retries: 0,
  shouldResetTimeout: true,
  retryCondition: (_error) => true // retry no matter what
});
//GET REQUEST 
export async function get(url, props="", timeout = 5000){
  try{
    const myConfig = {timeout: timeout}
    const response = await axios.get(url, myConfig)
    .then(value =>{
      if(props!==""){
        return value.data[props]
      }
      return value.data
    }) 
    return response
  }catch(e){return e}
}


//PUT REQUEST 
export async function put(url, formData){
  try{
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({accessibleChannels: formData}),
        headers: {"Content-Type": "application/json"}
    })
    .then(response=>response.json())
    .then(value =>value)

    return response
  }catch(e){return e}
}

//POST REQUEST
export async function post(url, formData){
  try{
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {"Content-Type": "application/json"}
    })
    .then(response=>response.json())
    .then(value =>value)

    return response
  }catch(e){return e}
}

//DELETE REQUEST
export async function deleteRequest(url){
  try{
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {"Content-Type": "application/json"}
    })
    .then(response=>response.json())
    .then(value =>value)
    
    return response 
  }catch(e){return e}
}

//GET DATE FORMAT => ACTUAL DATE + x
export function getdate(x = 0){
  let newDate = new Date() 
  newDate.setDate((parseInt(newDate.getDate()) + x)) 

  let year = newDate.getFullYear().toString()
  let month = parseInt(newDate.getMonth() + 1) < 10 ? "0"+ (parseInt(newDate.getMonth()) + 1).toString(): (parseInt(newDate.getMonth()) +1).toString()
  console.log(month)

  let day = parseInt(newDate.getDate()) < 10 ? "0"+newDate.getDate().toString() : newDate.getDate().toString()
  let fullDate = `${year}-${month}-${day}`

  return fullDate
}

//GET DATE FORMAT => GIVEN DATE + x
export function dateFormat(date, x = 0){
  let newDate = new Date() 
  newDate.setFullYear(date.substr(0,4))
  newDate.setMonth((parseInt(date.substr(5,2)) -1 ))
  x !== 0 ? newDate.setDate((parseInt(date.substr(8,2)) + x)) : newDate.setDate(date.substr(8,2))

  let year = newDate.getFullYear().toString()
  let month = parseInt(newDate.getMonth()) < 10 ? "0"+ (parseInt(newDate.getMonth()) + 1).toString(): (parseInt(newDate.getMonth()) +1).toString()
  let day = parseInt(newDate.getDate()) < 10 ? "0"+newDate.getDate().toString() : newDate.getDate().toString()
 
  let fullDate = `${year}-${month}-${day}`

  return fullDate
}


export async function getEveryProgramsForEachChannels(channels, language, date){

  let fullDate = getdate(date)
  let fullDateMinus1 = getdate((date-1))
  let fullDatePlus1 = getdate((date+1))

  if(Array.isArray(channels)){
    //Requests for each channels for the given date and the day before
    for(const chnl of channels){
      if(chnl.item_id == null){
        continue;
      }

      let url1 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDateMinus1
      let url2 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDate
      let requests = [get(url1,"result"),get(url2,"result")]

      await Promise.all(requests)
      .then(async (response)=>{
        //filter to get only the programs for the date
        chnl.programs = response.flat().filter(prgms=>prgms.end.substr(0,10) == fullDate&& prgms.end.substr(11,8)!=="00:00:00"||prgms.end.substr(0,10) == fullDatePlus1 &&prgms.start.substr(0,10) !== fullDatePlus1)
        //removing all doublon
        chnl.programs = Array.from(new Set(chnl.programs.map(JSON.stringify))).map(JSON.parse);
   
        for(let i = 0; i < chnl.programs.length; i++){
          if(chnl.programs[i+1]){
            let next = chnl.programs[i+1]
            if(chnl.programs[i].start == next.start){
              chnl.programs = chnl.programs.filter(f => f != next)
            }
          }
        }
       
        //SET THE CORRECT DURATION FOR EVERY PROGRAMS BY USING THEIR START AND END DATE
        chnl.programs.map((x, index)=> {
          if(index == 0){
            let yearS = x.start.substr(0,4)
            let monthS = x.start.substr(5,2)
            let dayS = x.start.substr(8,2)

            let yearE = x.end.substr(0,4)
            let monthE = x.end.substr(5,2)
            let dayE = x.end.substr(8,2)

            let startDate = new Date()
            
            startDate.setFullYear(yearS)
            startDate.setMonth((parseInt(monthS) -1 ))
            startDate.setDate(dayS)

            let endDate = new Date()
            endDate.setFullYear(yearE)
            endDate.setMonth((parseInt(monthE) -1 ))
            endDate.setDate(dayE)

            if(startDate < endDate){
              x.start = x.start.substr(0,11) + "00:00:00"
              startDate.setHours(x.start.substr(11,2))
              startDate.setMinutes(x.start.substr(14,2))
              endDate.setHours(x.end.substr(11,2))
              endDate.setMinutes(x.end.substr(14,2))
              
              x.duration = endDate.getHours() * 60 +  endDate.getMinutes()
            }
          }
          if(index == chnl.programs.length -1 ){
            let yearS = x.start.substr(0,4)
            let monthS = x.start.substr(5,2)
            let dayS = x.start.substr(8,2)

            let yearE = x.end.substr(0,4)
            let monthE = x.end.substr(5,2)
            let dayE = x.end.substr(8,2)

            let startDate = new Date()
            
            startDate.setFullYear(yearS)
            startDate.setMonth((parseInt(monthS) -1 ))
            startDate.setDate(dayS)

            let endDate = new Date()
            endDate.setFullYear(yearE)
            endDate.setMonth((parseInt(monthE) -1 ))
            endDate.setDate(dayE)

            if(startDate < endDate){
              x.end = x.end.substr(0,11) + "00:00:00"
              startDate.setHours(x.start.substr(11,2))
              startDate.setMinutes(x.start.substr(14,2))
              endDate.setHours(x.end.substr(11,2))
              endDate.setMinutes(x.end.substr(14,2))

              x.duration = 1440 - (startDate.getHours() * 60 +  startDate.getMinutes())
              
            }
          }
        })
      }).catch(e=>console.log(e))
    }

    //Assigning an Id to Each Channels
    channels.map(channel => {
      localChannels.channels.map(localChannelX => {
        if(channel.code == localChannelX.name){
          channel.id = localChannelX.orderNumber
        }
      })
    })

    //Sort By Assigned Id
    channels.sort((a, b) => (a.id > b.id) ? 1 : -1)

    return channels
  }
  return null;
}

//getEveryProgramsForEachChannelsForAWeeks 7 Days
export async function getEveryProgramsForEachChannelsForAWeeks(channels, language, language2 ){
  let fullDate = getdate(0)
  let fullDatePlus1 = getdate(1)
  let fullDatePlus2 = getdate(2)
  let fullDatePlus3 = getdate(3)
  let fullDatePlus4 = getdate(4)
  let fullDatePlus5 = getdate(5)
  let fullDatePlus6 = getdate(6)

  if(Array.isArray(channels)){
    //Iterate over Every channels and get the programs
    for(const chnl of channels){
      let url1 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDate
      let url2 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus1
      let url3 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus2
      let url4 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus3        
      let url5 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus4
      let url6 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus5
      let url7 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language+"&dateStart="+fullDatePlus6

      let url8 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDate
      let url9 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus1
      let url10 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus2
      let url11 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus3        
      let url12 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus4
      let url13 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus5
      let url14 = domainName + getPrograms+ token + "&itemId="+chnl.item_id+"&language="+language2+"&dateStart="+fullDatePlus6
      

      let test = [get(url1,"result"),get(url2,"result"),get(url3,"result"),get(url4,"result"),get(url5,"result"),get(url6,"result"),get(url7,"result"),
                  get(url8,"result"),get(url9,"result"),get(url10,"result"),get(url11,"result"),get(url12,"result"),get(url13,"result"),get(url14,"result")]

      await Promise.all(test)
      .then(async (response)=>{
        chnl.programs = response.flat()
      })
    }
    return channels
  }
  return null;
}

