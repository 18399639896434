

 export let channelsSchema = {
    id: "60f6be75014ad9beafcfa5de",
    channels:  [
        {
            name: "mbc1",
            programs:  {
                fr: [
           
                ],
                ar: [

                ]
            },
            
             
        },
        {
            name: "mbcMasr",
            programs:  {
                fr: [
                  
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "mbcdrama",
            programs:  {
                fr: [
           
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "MBC5",
            programs:  {
                fr: [

                ],
                ar: [
                ]
            }
             
        },
        {
            name: "ARTAflam1",
            programs:  {
                fr: [
                   
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "MBC3Europe",
            programs:  {
                fr: [
                
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "mbcPDrama",
            programs:  {
                fr: [
                    
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "ARTHekayat",
            programs:  {
                fr: [
             
                ],
                ar: [
            
                ]
            }
             
        },
        {
            name: "DMCDrama",
            programs:  {
                fr: [
      
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "Panoramafood",
            programs:  {
                fr: [
               
                ],
                ar: [
                
              
                ]
            }
             
        },
        {
            name: "GulliBilArabi",
            programs:  {
                fr: [
                  
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "DMCHD",
            programs:  {
                fr: [
                  
                ],
                ar: [

              
                ]
            }
             
        },
        {
            name: "AlMajdRawda",
            programs:  {
                fr: [
               
                ],
                ar: [
           
              
                ]
            }
             
        },
        {
            name: "Iqraa",
            programs:  {
                fr: [

                ],
                ar: [
    
                ]
            }
             
        },
        {
            name: "IqraaInternational",
            programs:  {
                fr: [

                ],
                ar: [
                ]
            }
             
        },
        {
            name: "Tunisia1",
            programs:  {
                fr: [
   
                ],
                ar: [

              
                ]
            }
             
        }
        ,
        {
            name: "onDrama",
            programs:  {
                fr: [
   
                ],
                ar: [

              
                ]
            }
             
        }
        ,{
            name: "hannibaltv",
            programs:  {
                fr: [
                ],
                ar: [
                ]
            }
             
        }
    ]
}
export let pgrmObj = {
    title: "",
    start:"",
    end:""
}