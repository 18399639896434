import React from "react";
import styled from "@emotion/styled";

const StyledHours = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-top: 175px;
  width: 100%;
  background-color: #481d24;
  color: white;
  max-height: 26px;
  min-height: 26px;
  z-index: 5;
`;

const StyledUl = styled.ul`
background-color:  #481d24;
width: 100%;
display: flex;
 min-height:  35px;
  max-height:  100%;
`;
const Styledli = styled.li`

  box-sizing: border-box;
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-size: 20px;
  background-color: #481d24;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  height: 100%;
  min-height:  35px;
  max-height:  100%;
  width: 99px;
  min-width: 99px;
  max-width: 99px;
  color: white
  border-left: 1px solid white;

  &:first-child {
    margin-left: 160px;
    border-left: 1px solid rgba(255, 255, 255, 0.5);

  }
  &:last-child {
    margin-right: 20px;
  }
  &> span {
    
    display: flex;
    width: auto;
    height: auto;
  }
`;

const createSchedule = () => {
  let resp = []
  for(let i = 0; i < 24 ; i ++){
    for(let y = 0; y < 2 ; y ++){
      resp.push(<Styledli key={`${i}-${y}`}><span>{(i < 10 ? ("0"+i) : i) + ":" + (y === 1 ? "30" : "00")}</span></Styledli>)
    }
  }
  return resp
}

//Make a function for this!!!
export default function Hours() {
  return (
    <StyledHours id="hours" className="hours">
      <StyledUl>
        {
          createSchedule()
        }
      </StyledUl>
    </StyledHours>
  );
}
