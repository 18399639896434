import React, {} from "react";
import { Link } from "react-router-dom";
import operators from "../../jsonFiles/operators.json" //BOUYGUE FREE ORANGE SFR
import localChannels from "../../jsonFiles/localChannels.json";
import { getLocalDateFormat,getDate, compareDate, getMinutes} from "../../data/TimeRelatedData.js"
import * as style from "./EpgCleanStyle"
import { adjustProgram} from "../AdjustProgram"
import * as request from "../../data/request"
import conf from "../../jsonFiles/config.json"



const domainName = "https://telemuslim.tv/api/v2f/live/"
const uri = "getItemEPG?"
const token = "token=cef2ec5d1876619aeb01b5508f511d6b6603319f"

let channelsList = []

async function getData(language, day = 0, replay=false){

  language = language.substr(0,2)


  if(replay){
    const url = "https://epg-backend-preprod.herokuapp.com/api/v1/preprod/all/replay/"

    let channels = await request.get(url)

    return channels
  }


  const url = "https://telemuslim.tv/api/v2f/live/getEPGChannels?token=cef2ec5d1876619aeb01b5508f511d6b6603319f&language="+language

  let channels = await request.get(url, "result")

 
  let channelsPrograms = await request.getEveryProgramsForEachChannels(channels, language, day)

  return channelsPrograms
}


export default class EpgProd extends React.Component {

  constructor (props) {
      super(props)
      this.state = {
        channels : [],
        day : props.day,
        allChannels: [],
        allChannelsTest: [],
        replayPrograms: [],
        liveShows: [],
        userLanguage: ["fr","ar","FR","AR"].includes(((navigator.language).substr(0,2) || (navigator.userLanguage).substr(0,2))) ? (navigator.language).substr(0,2) || (navigator.userLanguage).substr(0,2) : "fr",
        smallScreen: false
      
     }
     this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

      //this.disableScroll = this.disableScroll.bind(this)
      //this.enableScroll = this.enableScroll.bind(this)
      //this.scrollToActualHour = this.scrollToActualHour.bind(this)
  }


  forceUpdateHandler(){
    this.forceUpdate();
  };

  componentDidMount () {

    let windowWidth = window.innerWidth

    //Computer version 
    if(window.innerWidth > conf.mobileSize) {
  
         //Above is fine 
      //Find a way to reduce the number of adjustPrograms and to use it at the best moment
      const userHandleChange = (x , operatorChannel, defaultOperator = false ) => {
        if(defaultOperator ==  true) {
          let channels = x
          channels.map(channel => channel.operateurNumber =  "")

          this.setState({channels: channels});
          setTimeout(() => {
            adjustProgram()
           }, 500)  
  
        }
        if(defaultOperator ==  false) {
          let operatorFilteredChannels = x
          operatorFilteredChannels.map(channel => {
            channel.operateurNumber = operatorChannel.channels.map(operatorFilteredChannel => {
              if(channel.code === operatorFilteredChannel.name){
                return operatorFilteredChannel.number
              }})
          })
          this.setState({channels: operatorFilteredChannels});
         
           setTimeout(() => {
           //workinh here like the change
            adjustProgram()
           }, 1000)  
        }
        setTimeout(()=>{
          if(localStorage.getItem("language")){
            document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
          } 
        },0)
      } 

      //Switch ???
      const handleOperatorChange = (operatorsSelectorValue)=> {
        if(operatorsSelectorValue === "free"){
          let free = this.state.allChannels.filter(x => operators.operators[1].channelsIds.includes(x.code))
          userHandleChange(free, operators.operators[1])
        }
        if(operatorsSelectorValue === "sfr"){
          let sfr = this.state.allChannels.filter(x => operators.operators[3].channelsIds.includes(x.code))
          userHandleChange(sfr, operators.operators[3])
        }
        if(operatorsSelectorValue === "orange"){
          let orange = this.state.allChannels.filter(x => operators.operators[2].channelsIds.includes(x.code))
          userHandleChange(orange, operators.operators[2])
        }
        if(operatorsSelectorValue === "bouygues"){
          let bouygues = this.state.allChannels.filter(x => operators.operators[0].channelsIds.includes(x.code))
          userHandleChange(bouygues, operators.operators[0])
        }
        if(operatorsSelectorValue === "default"){
          userHandleChange(this.state.allChannels, "", true)
        }

      }

      const userLanguageHandleChange= (changedLanguage, tt) => {

        getData(selectedLanguage, this.state.day, true)
        .then((response)=>{
          return response
        })
        .then((resp)=>{        
            getData(changedLanguage, this.state.day)
            .then((response)=>{
              return response
            })
            .then((res)=>{
              console.log(res)
              this.setState({channels: res, replayPrograms : resp[0], allChannels: res, userLanguage: changedLanguage});
            })
            .then(()=>{
              handleOperatorChange(tt)
              setTimeout(() => {
                adjustProgram()  
              }, 1000)     
            })
        })
       
          
       
     

      }

      let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage
      if(localStorage.getItem("language")){
        document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
      }
      
      document.querySelector(".operators").addEventListener('change', function (evt) {
        let operatorsSelectorValue = document.querySelector(".operators").value;
        if(localStorage.getItem("language")){
          document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
        }
        handleOperatorChange(operatorsSelectorValue)
        localStorage.setItem("operator", document.querySelector(".operators").value)

      })

      document.querySelector(".language").addEventListener('change', function (evt) {
        let tt = document.querySelector(".operators").value 
        if(this.value == "french"){
          userLanguageHandleChange("fr", tt)
          localStorage.setItem("language", "fr")
        }
        if(this.value == "arabic"){
          userLanguageHandleChange("ar", tt)
          localStorage.setItem("language", "ar")
        }

      })


     try{

      getData(selectedLanguage, this.state.day, true)
      .then((response)=>{
        console.log(response)
        return response
      }).then((resp)=>{
        //channels en plus sinon c ok ici
        this.setState({replayPrograms : resp[0], channels: []})
    
          getData(selectedLanguage, this.state.day).then((response)=>{
            return response
          }).then((res)=>{
            this.setState({channels: res, allChannels: res});
            console.log(res)
          }).then(()=>{
            
            if(localStorage.getItem("operator")){
              document.querySelector(".operators").value = localStorage.getItem("operator")
              handleOperatorChange(localStorage.getItem("operator"))
            }
            setTimeout(() => {
              adjustProgram()
            }, 1000)     
            
          })


      })

         
      
     }catch(e){
       console.log(e)
     }
      

    }

  //Mobile Version 
  if(window.innerWidth <= conf.mobileSize) {
    

    function loadindDiv() {
      var loadingDataDiv = document.createElement("div");
      loadingDataDiv.className = "loadingDataDiv"
      var center_lds_ring = document.createElement("div");
      var lds_ring = document.createElement("div");
      lds_ring.className = "lds-ring"

      var div1 = document.createElement("div");
      var div2 = document.createElement("div");
      var div3 = document.createElement("div");
      center_lds_ring.style.display = "flex";
      center_lds_ring.style.justifyContent = "center";
      center_lds_ring.style.alignSelf = "center";

      center_lds_ring.style.width = "40%";
      center_lds_ring.style.height = "55%";

      loadingDataDiv.style.display = "flex";
      loadingDataDiv.style.justifyContent = "center";
      lds_ring.style.display = "flex";
      lds_ring.style.justifyContent = "center";
      lds_ring.style.alignItems = "center;"

      loadingDataDiv.style.top = "22%";
      loadingDataDiv.style.left = "0";
      loadingDataDiv.style.zIndex = "10";
      lds_ring.style.margin = "20% 0% 0 0%"

      lds_ring.appendChild(div1)
      lds_ring.appendChild(div2)
      lds_ring.appendChild(div3)

      center_lds_ring.appendChild(lds_ring)

      loadingDataDiv.appendChild(center_lds_ring)

      document.body.querySelector(".container").appendChild(loadingDataDiv)

    }

    loadindDiv()
     
     
    let livePrograms = [];
    let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()

    let selectedLanguage = localStorage.getItem("language") ? localStorage.getItem("language") : this.state.userLanguage

    getData(selectedLanguage, this.state.day, true)
    .then((response)=>{
      console.log(response)
      return response
    }).then((resp)=>{
      this.setState({replayPrograms : resp[0]})

        getData(selectedLanguage, this.state.day)
        .then((response)=>{
          return response
        })
        .then((res)=>{
          console.log(res)
          this.setState({channels: res, allChannels: res, day: this.state.day });
        })
        .then(()=>{
          this.state.channels.map(x=> {
            if(!x.programs){
              return
            }
            x.programs.map(program => {
              // check if the programs is on live and if it is the case push it to livePrograms array


              //ADED >= at the end for  program.end.substr(11, 16).toString()) >= (getMinutes(timeInMinutes) to see if there is still the issue with missing programs at
              //the end of a show
              if(getMinutes(timeInMinutes) > getMinutes(program.start.substr(11, 16).toString()) && 
                  getMinutes(parseInt(program.start.substr(11, 2).toString()) > parseInt(program.end.substr(11, 2).toString())&& 
                  getMinutes(program.start.substr(11, 16).toString()) > getMinutes(program.end.substr(11, 16).toString()) ? 
                  "23:59" : program.end.substr(11, 16).toString()) >= (getMinutes(timeInMinutes) )){
                    return livePrograms.push(program.title + program.start)  
              }
            })
          })
          this.setState({liveShows : livePrograms})
          document.body.querySelector(".loadingDataDiv").remove()

        })
      })

   





    const userHandleChange = (x , operatorChannel, defaultOperator = false ) => {
      if(defaultOperator ==  true) {
        let channels = x
        channels.map(channel => channel.operateurNumber =  "")

        this.setState({channels: channels});

      }
      if(defaultOperator ==  false) {
        let operatorFilteredChannels = x
        operatorFilteredChannels.map(channel => {
          channel.operateurNumber = operatorChannel.channels.map(operatorFilteredChannel => {
            if(channel.code === operatorFilteredChannel.name){
              return operatorFilteredChannel.number
            }})
        })
        this.setState({channels: operatorFilteredChannels});
      
      }
      setTimeout(()=>{
        if(localStorage.getItem("language")){
          document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
        } 
      },0)
    } 

    //Switch ???
    const handleOperatorChange = (operatorsSelectorValue)=> {
      if(operatorsSelectorValue === "free"){
        let free = this.state.allChannels.filter(x => operators.operators[1].channelsIds.includes(x.code))
        userHandleChange(free, operators.operators[1])
      }
      if(operatorsSelectorValue === "sfr"){
        let sfr = this.state.allChannels.filter(x => operators.operators[3].channelsIds.includes(x.code))
        userHandleChange(sfr, operators.operators[3])
      }
      if(operatorsSelectorValue === "orange"){
        let orange = this.state.allChannels.filter(x => operators.operators[2].channelsIds.includes(x.code))
        userHandleChange(orange, operators.operators[2])
      }
      if(operatorsSelectorValue === "bouygues"){
        let bouygues = this.state.allChannels.filter(x => operators.operators[0].channelsIds.includes(x.code))
        userHandleChange(bouygues, operators.operators[0])
      }
      if(operatorsSelectorValue === "default"){
        userHandleChange(this.state.allChannels, "", true)
      }

    }


    //NOT LOADING THE CHANGEMENT RENDER ISSUE NOT REQUEST
    const userLanguageHandleChange= (changedLanguage, tt) => { 
      getData(changedLanguage, this.state.day).then((response)=>{
        console.log(response)
        return response
      }).then((res)=>{
        this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});
        return res
      }).then((res)=>{
        //to save the filter on the operators
        handleOperatorChange(tt)
        this.state.channels.map(x=> {
          x.programs.map(program => {
            // check if the programs is on live and if it is the case push it to livePrograms array +1 ?
            if(getMinutes(timeInMinutes) > getMinutes(program.start.substr(11, 16).toString()) && 
              getMinutes(parseInt(program.start.substr(11, 2).toString()) > parseInt(program.end.substr(11, 2).toString())&& getMinutes(program.start.substr(11, 16).toString()) > getMinutes(program.end.substr(11, 16).toString()) ? 
              "23:59" : program.end.substr(11, 16).toString()) >= (getMinutes(timeInMinutes)  )){
                    if(!livePrograms.includes(program.title + program.start)){
                      return livePrograms.push(program.title + program.start)
                    }
            }    
          })
        })
        this.setState({channels: res, allChannels: res, userLanguage: changedLanguage});

      })
    }

    if(localStorage.getItem("language")){
      document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
    }
    
    document.querySelector(".operators").addEventListener('change', function (evt) {
      let operatorsSelectorValue = document.querySelector(".operators").value;
      if(localStorage.getItem("language")){
        document.querySelector(".language").value = localStorage.getItem("language") == "ar" ? "arabic" : "french"
      }
      handleOperatorChange(operatorsSelectorValue)
      localStorage.setItem("operator", document.querySelector(".operators").value)

    })


    document.querySelector(".language").addEventListener('change', function (evt) {
      let tt = document.querySelector(".operators").value 
      if(this.value == "french"){
        userLanguageHandleChange("fr", tt)
        localStorage.setItem("language", "fr")
      }
      if(this.value == "arabic"){
        userLanguageHandleChange("ar", tt)
        localStorage.setItem("language", "ar")
      }

    })
      
  }


    //Listener on the resize of the window to pass from the computer version to the mobile version and vice versa
    window.addEventListener("resize", () =>{
      if(windowWidth > conf.mobileSize){
       if(window.innerWidth < conf.mobileSize){
         window.location.reload()
       }
     }
     if(windowWidth <= conf.mobileSize){
       if(window.innerWidth > conf.mobileSize){
         window.location.reload()
       }
     }
    });
     
  }



  render () {
    
    let timeInMinutes = (parseInt(new Date().getHours()) < 10 ? "0" +  new Date().getHours().toString() : new Date().getHours().toString() ) + ":" + new Date().getMinutes().toString()
    let channelTitleAvoiDoublon = []
    let channelToExclude = ["canalAlgerie","ennahar","carthageplus","ElHiwarEttounsi", "IqraaArabic", "GulliBilArabie"]
    let channelToCorrect = ["GulliBilArabi","MBC Plus Drama", "IqraaInternational"]
    let channelCorrection = ["Gulli Bil Arabi","MBC + Drama","Iqraa International"]
    let computerVersion ;
    let mobileVersion ;


    localStorage.setItem("Day",this.state.day)

    const checkIfProgramInReplayIsActive = (start, end) => {
      let actualDate = new Date()

      let paramYear = start.substr(0,4)
      let paramMonth = (parseInt(start.substr(5,2)) - 1)
      let paramDay = start.substr(8,2)

      let paramDate = new Date()
      paramDate.setFullYear(paramYear)
      paramDate.setMonth(paramMonth)
      paramDate.setDate(paramDay)

      let param2Year = end.substr(0,4)
      let param2Month = (parseInt(end.substr(5,2)) - 1)
      let param2Day = end.substr(8,2)

      let param2Date = new Date()
      param2Date.setFullYear(param2Year)
      param2Date.setMonth(param2Month)
      param2Date.setDate(param2Day)

      if(paramDate <= actualDate && actualDate <= param2Date){
        return true;
      }else{
        return false;
      }

    }
    
 
    if(window.innerWidth > conf.mobileSize){
      computerVersion = this.state.channels.map((channel) => {
            let chanelLocalLogo = ""
            let dateProgStart = new Date();
            dateProgStart.setDate(dateProgStart.getDate() + parseInt(this.state.day));
            // dateProgStart.setMonth(parseInt(program.start.substr(5, 2)) - 1 )

            //To get the thumbnails of each channels - using a json file 
            localChannels.channels.map(localChannelX => {
                if(channel.code == localChannelX.name){
                  chanelLocalLogo= localChannelX.thumbnails
                }
            })

            //To not display channel with an item_id equal to null 
            if(channel.item_id == null || channelToExclude.includes(channel.code)){
                return
            }
    
            //Avoid doublon by adding to a list the channel title 
            if(channelTitleAvoiDoublon.includes(channel.title)){
                return
            }
              
            channelTitleAvoiDoublon.push(channel.title)
    
            // <style.StyledTitleDiv>{channel.title ==  channelToCorrect[0] ?  channelCorrection[0] : channel.title ==  channelToCorrect[1] ? channelCorrection[1] : channel.title ==  channelToCorrect[2] ? channelCorrection[2]: channel.title}</style.StyledTitleDiv>

              return (
                <>
                {
                  <div className="flexbox-row-div">
                  <style.StyledFlexChannelDiv>
                    <style.StyledFlexContainerDiv>
                    <style.StyledImageDiv>
                      <style.StyledImage src={chanelLocalLogo} size/>
                    </style.StyledImageDiv>
                    <style.StyledFlexNumberDiv className="flexNumber">{channel.operateurNumber}</style.StyledFlexNumberDiv>
                    <style.StyledTitleDiv>{channel.title ==  channelToCorrect[0] ?  channelCorrection[0] : channel.title ==  channelToCorrect[1] ? channelCorrection[1] : channel.title ==  channelToCorrect[2] ? channelCorrection[2]: channel.title}</style.StyledTitleDiv>
                    </style.StyledFlexContainerDiv>
                  </style.StyledFlexChannelDiv>
                  {
                      channel.programs ? channel.programs.map((program, y) => {
                        let programOnReplay = false;
                        const hoursLiWidth = document.querySelector(".hours > ul > li").getBoundingClientRect().width;
     
                        //get the minutes of a program to determine the size of the div that will be multiplied by this and another variable
                        //j'enleve pour test de resoudre le bug des margin a 1,021421
                        let minutes =  parseInt(program.duration.toString())
                        //console.log(program.title + " = " + minutes + " li = " + hoursLiWidth )
                        //console.log((hoursLiWidth * minutes) / 30)


                       
                          this.state.replayPrograms.channels.forEach(channelR => {
                         
                            if(channel.code === channelR.name){
                              localStorage.getItem("language").substr(0,2) === "fr" ? channelR.programs.fr.map(programR => {
                                let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                             
                                  if(programR.title == program.title && bool){
                                   // console.log(program.title)
                                    programOnReplay = true;
                              }
                            }) : channelR.programs.ar.map(programR => {
                              let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                        
                              if(programR.title == program.title && bool){
                                programOnReplay = true;
                              }
                            })
                            } 
                          })
                        
        
                          return (
                            <div
                              className="flexbox-show-div"
                              style={{
                                width: (hoursLiWidth * minutes) / 30 + "px",
                                backgroundColor: 
                                getMinutes(timeInMinutes) > getMinutes(program.start.substr(11, 16).toString())&& 
                                getMinutes(program.end.substr(11, 2).toString() == "00" && compareDate(program.start, program.end, this.state.day) || getMinutes(program.start.substr(11, 16).toString()) > getMinutes( program.end.substr(11, 16).toString()) ? "23:59" : program.end.substr(11, 16).toString() ) >= getMinutes(timeInMinutes)  && this.state.day == "0" ? 
                                "#b10017" : programOnReplay ? "#3f3b3b" : "#3f3b3b"
                              
                              }}
                              onMouseOver={(div)=>{
                                //Original value 75 not 80
                                
                            
                                if((hoursLiWidth * minutes) / 30 < 80 ){
                                  //document.querySelectorAll(".flexbox-row-div").style.maxWidth = "4932px"
                                  if(div.target.localName === "h4" || div.target.localName === "h5" || div.target.localName === "h6"){
                                    div.target.parentElement.style.width= "200px";
                                  }
                                  
                                  if(div.target.localName === "div" && div.target.className.split(" ")[0] !== "replayLogoContainer"  && div.target.className.split(" ")[0] !== "replayLogo"){
                                    console.log(div)
                                    div.target.style.width= "200px";   
                                    div.target.childNodes[1].innerText = program.title.length > 45 ? program.title.substring(0, 45) + "..." : program.title
                                    div.target.childNodes[1].style.position = "relative"
                                    div.target.lastChild.style.color = "white"
                                    div.target.lastChild.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6): "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                    div.target.lastChild.style.visibility = "visible"
        
                                  }       
                                }
                              }}
                              onMouseLeave={(div)=>{
                                //Original value 75 not 80
                                if((hoursLiWidth * minutes) / 30 < 80 ){
                                  //document.querySelectorAll(".flexbox-row-div").style.maxWidth = "unset"
                                  if(div.target.localName === "h4" || div.target.localName === "h5" || div.target.localName === "h6"){
                                    div.target.parentElement.style.width = (hoursLiWidth * minutes) / 30 + "px";
                                    if(div.target.localName === "h4") {
                                      div.target.innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                      div.target.nextSibling.style.color = "#3f3b3b"
                                      div.target.nextSibling.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                      program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                      div.target.nextSibling.style.visibility = "hidden"
                                    }
                                    if(div.target.localName === "h5") {
                                      div.target.previousSibling.innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                      div.target.style.color = "#3f3b3b"
                                      div.target.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                      program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                      div.target.style.visibility = "hidden"
                                    }
                                  }
                                  if(div.target.localName === "div" && div.target.className.split(" ")[0] !== "replayLogoContainer"  && div.target.className.split(" ")[0] !== "replayLogo") {
                                    div.target.childNodes[1].innerText = (hoursLiWidth * minutes) / 30 < 50 ?  "" : "...";
                                    div.target.style.width = (hoursLiWidth * minutes) / 30 + "px";
                                    div.target.lastChild.style.color = "#3f3b3b"
                                    div.target.lastChild.innerText = (hoursLiWidth * minutes) / 30 < 80 ? "  " +
                                    program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)
                                    div.target.lastChild.style.visibility = "hidden"
                                  }    
                                }
                              }}
                            >

                              <style.StyledReplayLogoContainer  className="replayLogoContainer"
                              style={{
                                visibility: programOnReplay ? "visble": "hidden",
                                height: programOnReplay ? "auto": "0px"
                              }}>
                                <style.StyledReplayLogo className="replayLogo"/>
                              </style.StyledReplayLogoContainer>

                              <style.StyledShowDescription> {
                              //Original value 75 not 80

                              (hoursLiWidth * minutes) / 30 < 50 ? 
                              "" : (hoursLiWidth * minutes) / 30 < 80 ? 
                              "..." : (hoursLiWidth * minutes) / 30 < 100 ?
        
                              (program.title.length > 30 ? program.title.substring(0, 20) + "..." : program.title ) : 
        
                              (hoursLiWidth * minutes) / 30 < 150 ? 
                              (program.title.length > 25 ? program.title.substring(0, 20) + "..." : program.title) :
                              (program.title.length > 35 ? program.title.substring(0, 35) + "..." : program.title )
                              
                            
                            
                            }</style.StyledShowDescription>
        
                              <h5 className="schedule"
                                style ={{
                                  color: (hoursLiWidth * minutes) / 30 < 80 ? "#3f3b3b" : "white",
                                  visibility:  (hoursLiWidth * minutes) / 30 < 80 ? "hidden" : "visible"
                                }//Original value 75 not 80 
                              }
                              >
                                {(hoursLiWidth * minutes) / 30 < 80 ? "  " + program.start.substr(10, 6) +"-" +program.end.substr(10, 6) : "  " +program.start.substr(10, 6) +"-" +program.end.substr(10, 6)}
                              </h5>
                            </div>
                          );
                        //}
                      })  : false
                  }
                  </div>
                }
                  </>  );
              })
    }

    if(window.innerWidth <= conf.mobileSize){
      
      const reloadPage = () => {
        let val = localStorage.getItem("firstInit")
  
        if(val < 2){
          val ++
          localStorage.setItem("firstInit", val)
          window.location.reload()
        }
      }

      mobileVersion = this.state.channels.map((channel) => {
          let chanelLocalLogo = ""
          
            localChannels.channels.map(localChannelX => {
              if(channel.code == localChannelX.name){
                chanelLocalLogo= localChannelX.thumbnails
              }
            })
      
            if(channel.item_id == null || channelToExclude.includes(channel.code)){
              return
            }
      
            if(channelTitleAvoiDoublon.includes(channel.title)){
              return
            }
            channelTitleAvoiDoublon.push(channel.title)
            
        
            return (
              <>
              {
                <div className="flexbox-row-div-mobile">
               
                  <style.StyledFlexChannelDivM>
                    <style.StyledFlexContainerDivM>

                    <style.StyledImageDivM>
                    <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}> <style.StyledImageM src={chanelLocalLogo} size/></Link>
                    </style.StyledImageDivM>

                    <style.StyledFlexNumberDivM className="flexNumber">{channel.operateurNumber}</style.StyledFlexNumberDivM>

                      <style.StyledTitleDivM>
                        <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{
                        channel.title ==  channelToCorrect[0] ?  
                          channelCorrection[0] : channel.title ==  channelToCorrect[1] ? 
                            channelCorrection[1] : channel.title ==  channelToCorrect[2] ? 
                              channelCorrection[2]:channel.title}
                        </Link>
                      </style.StyledTitleDivM>

                    </style.StyledFlexContainerDivM>
                  </style.StyledFlexChannelDivM>

                  <style.StyledFlexShowContainerM>

                    {
                      channel.programs ? channel.programs.map((program, index) => {
                        let programOnReplay = false;         
                        
                        if(this.state.replayPrograms.length != 0){
                           this.state.replayPrograms.channels.map(channelR => {
                           this.state.userLanguage === "fr" ? channelR.programs.fr.map(programR => {
                            let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                            if(programR.title == program.title && bool){
                              programOnReplay = true;
                            }
                           }) :  channelR.programs.ar.map(programR => {
                            let bool = checkIfProgramInReplayIsActive(programR.start,programR.end) 
                            if(programR.title == program.title && bool){
                              programOnReplay = true;
                            }
                            })
                          })
                        }
                       

                        if(this.state.liveShows.includes(program.title + program.start)){

                          return (
                            <style.StyledShowDescriptionM>
                              <style.StyledFlexProgramsScheduleM> <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{"  " + program.start.substr(10, 6) +"-" +program.end.substr(10, 6)}</Link></style.StyledFlexProgramsScheduleM>

                              <style.StyledFlexProgramsTitleM> <Link to={"channel/" + channel.code +"/" + getDate(this.state.day, "-") + "/" + localStorage.getItem("language")+ "/"+this.state.day}>{program.title.length > 15 && this.state.userLanguage === "fr" ? program.title.substring(0, 15) + "..." : program.title.length > 15 && this.state.userLanguage === "ar" ?  "..." + program.title.substring(3, 18)  : program.title}</Link></style.StyledFlexProgramsTitleM>
                            
                            </style.StyledShowDescriptionM>
                          )
                        }
                       
                      }): reloadPage()
                    }
                  </style.StyledFlexShowContainerM>
              
                </div>
        }
        </>
            )
      })

    }

    
  return ( window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion);
  }



}