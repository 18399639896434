import styled from "@emotion/styled";



var style = getComputedStyle(document.body)

export const StyledHeader = styled.div`
  display: flex;
  background-color: ${style.getPropertyValue('--header-second-color')};
  width: 100%;
  z-index: 5;
  height: 15%;
  min-height: 120px;
  max-height: 15%;
  justify-content: center;
  position: sticky;

`;

export const StyledChannel= styled.div`
  display: flex;
  width: 40%;
  height: 100%;
  z-index: 5;
  justify-content: center;
  flex-wrap: wrap;

`;

export const StyledChannelImage= styled.img`
display: flex;
  width: 60%;
  height: 70%;
  min-width: 80px;
  max-width: 180px;
  

`;

export const StyledChannelTitle = styled.div`
display: flex;
z-index: 5;
justify-content: center;
align-items: center;
width: 100%;
height: 20%;

`;

export const StyledTitle = styled.span`
display: inline-block;
text-align: center;
padding-top: 0%;
width: 100%;
height: 100%;
font-weight: bold;
color: white;

`;

export const ButtonContainer= styled.div`
  display: flex;
  z-index: 6;
  position: absolute;
  left: 2%;
  top: 0%;
  width: 15%;
  height: 5%;
  justify-content: center;
  align-items: center;
`;

export const StyledBackSymbolContainer = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
`

export const StyledBackSymbol = styled.div`
  display: flex;
  width: 65%;
  height: 25%;
  border-top: 2px solid white;
  border-left: 2px solid white;
  transform: rotate(320deg);

`

export const StyledBackSpan = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color:white;
  font-weight: bold;
  width: 50%;
  height: 50%;
  font-size: 110%;

  &:hover {
    border-bottom: 1px solid white;
    cursor: pointer;
  }

`

export const StyledProgramsContainer= styled.div`
    display: flex;
    background-color: #333030;
    width: 100%;
    z-index: 5;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10%;

`;

export const StyledProgram = styled.div`
    display: flex;
    width: 100%;

    z-index: 5;
    /* needed for other device */
    min-height: auto;
    /*resolve the issue on iphone and huwaei but the issue is on ipad and big screen*/
    height: auto;

    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;

    /*370*/
    @media (min-width: 100px) and (max-width: 400px){
      min-height: 100px;
      min-height: 125px;
    }


`;

export const StyledThumbnailContainer = styled.div`
    display: flex;
    background-color: #3f3b3b;
    width: auto;
    max-width: 50%;
    max-height:500px;
    z-index: 5;
    height: auto;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;

`;

export const StyledThumbnail = styled.img`
    width: auto;
    max-width: 100%;
    max-height: 99.7%;
    height: auto; 
    min-height: 20%;
    min-width: 40%;


`;

export const StyledDescriptionContainer = styled.div`
      display: flex;
      width: 50%;
      z-index: 5;
      height: 100%;
      min-height: 100%;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      background-color: #3f3b3b;

      @media (min-width: 100px) and (max-width: 400px){
        min-height: 98px;
        min-height: 123px;
      }
`;


export const StyledProgramTitle = styled.span`
  display: inline;
  color: white;
  width: auto;
  height: auto;
  margin-top: 5%;
  margin-left: 5%;
  font-size: 3vmin;

/*
  &:hover {
    border-bottom: 2px solid white;
    cursor: pointer;
  }*/
`;

export const StyledProgramLiveContainer = styled.div`
    display: flex;
    position: absolute;
    width: 30%;
    height: 30%;
    right: 0%;
    bottom: 0%;
    z-index: 5;
    visibility: hidden;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;

`;

export const StyledProgramReplayContainer = styled.div`
    display: flex;
    position: absolute;
    width: 70%;
    height: 30%;
    left: 0%;
    bottom: 0%;
    z-index: 5;
    border-top: 1px solid black;
    justify-content: center;
    align-items: center;

`;

export const StyledProgramLiveSymbolContainer = styled.div`
    display: flex;
    width: 30%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: flex-end;
    border-radius: 2px;

`;

export const StyledProgramLiveSymbol= styled.div`
    display: flex;
    width: 45%;
    height: 20%;
    z-index: 5;
    background-color: red;
    border-radius: 100%;

`;

export const StyledReplayLogoContainer = styled.div `
display: flex;
background-color: #6b773c;
  height: 60%;
  width: 15%;
  border-radius: 2px;
  justify-content: center;
    align-items: center;
  margin-left: 5%;
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 1%;


`

export const StyledReplayLogo = styled.div `
    display: flex;
    width: 50%;
    height: 100%;
    margin: 5%;
    border-left: 3vw solid white;
    border-bottom: 2vw solid transparent;
    border-top: 2vw solid transparent;
    border-radius-top: 10px;
    border-radius-bottom: 50%;


`


export const StyledProgramLiveTextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;

`;

export const StyledProgramReplayTextContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 5;
    align-items: center;
    justify-content: center;

`;

export const StyledProgramLiveText = styled.span`
    display: flex;
    width: 100%;
    height: 90%;
    z-index: 5;
    color: red;
    font-weight: bold;
    font-size: 3vmin;
    align-items: center;
    justify-content: center;

`;

export const StyledProgramReplayText = styled.span`
display: flex;
width: auto;
height: 90%;
z-index: 5;
color: white;
font-weight: bold;
font-size: 3vmin;
align-items: center;
justify-content: center;


`;

export const StyledProgramSchedule= styled.span`
  display: inline-block;
  color: white;
  width: 95%;
  height: auto;
  margin-top: 2.5%;
  margin-left: 5%;
  font-size: 2.5vmin;
`;


export const StyledAppHeader = styled.div`
    display: flex;
    border-bottom: 1px solid black;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
`;




export const StyledThumbnailCategories = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  width: auto;
  height: auto;
  align-items: center;
  font-size: 4vmin;
  color: #C62A2A;
  font-weight: bold;
  font-family: trajan_reg, serif;
`;
