import React from "react";
import { Link } from "react-router-dom";
import "../../../../style.css";
import conf from "../../jsonFiles/config.json"
import * as style from "./StyledNavigation"
import {getDays, getDate, getSpanDate, select, carouselChange} from "./NavigationLogic"

export default function Navigation(props) {

  //Computer
  const createDays = () => {
    let resp = [];
    for(let i = 0; i < 7; i++){
        if(i === 0){
        resp.push(<style.Styledli className="navLi firstLi">
        <Link className={props.index  == i ?"option-selected dayLink":"dayLink"} to={getDate(0, "")} onClick={(e) => {select(e)}}><style.StyledDaySpan>{getDays()}</style.StyledDaySpan><style.StyledMonthSpan>{getSpanDate(0)}</style.StyledMonthSpan></Link>
        </style.Styledli>)
        }
        if(i > 0){
        resp.push(<style.Styledli className="navLi">
        <Link  className={props.index  == i ?"option-selected dayLink":"dayLink"} to={getDate(i, "")} onClick={(e) => {select(e)}}><style.StyledDaySpan>{getDays("",i)}</style.StyledDaySpan><style.StyledMonthSpan>{getSpanDate(i)}</style.StyledMonthSpan></Link>
        </style.Styledli>)
        }
    }
    return resp;
  }
  getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")


  //Mobile Version
  //get the actual date from the url 
  let url = window.location.href
  let urlDateStart = url.length - 10
  let urlDate = url.substring(urlDateStart, url.length)

  //create a date
  let dateT = new Date()
  let year = urlDate.substring(6,urlDate.length)
  let month = (parseInt(urlDate.substring(3,5)) - 1).toString()
  let day = urlDate.substring(0,2)
  dateT.setFullYear(year, month, day);

  //create a date for day + 1 and day -1
  let dateTplus1 = new Date()
  let dateTmoin1 = new Date()
  dateTplus1.setFullYear(year, month, (dateT.getDate() + 1));
  dateTmoin1.setFullYear(year, month, (parseInt(dateT.getDate()) - 1).toString());
  let dayCurrent = dateT.getDay() 
  let dayMoin1 = dateTmoin1.getDate()
  let dayPlus1 = dateTplus1.getDate()



  let computerVersion = (
     <style.StyledNavigation className="navigation">
      <style.StyledNavigationTestReplayContainer>
          <style.StyledNavigationTestReplay>
              <style.StyledReplayLogoContainer>
                <style.StyledReplayLogo />
              </style.StyledReplayLogoContainer>
              <span>Programmes disponibles sur MY ARABIA Replay</span>
          </style.StyledNavigationTestReplay>
      </style.StyledNavigationTestReplayContainer>
       <style.StyledNavigationWrapper> 
         <style.StyledUl className="navigationUl">
          {createDays()}
         </style.StyledUl>
     <style.StyledCarouselDayDiv className="carouselNavigationContainer" style={{visibility: "hidden"}}>
       <style.StyledDayContainerDiv>
       <Link className="carouselNav carouselLeft" to={getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 0 ? 6: (parseInt(localStorage.getItem("Day")) -1):6, "") } onClick={(e) => {carouselChange()}}>{'<'}</Link>
         <style.StyledCarouselDaySpan className="carouselDaySpan">{localStorage.getItem("Day") ? getDays("",parseInt(localStorage.getItem("Day"))): getDays()}</style.StyledCarouselDaySpan>
       <Link className="carouselNav carouselRight" to={getDate(localStorage.getItem("Day") ? parseInt(localStorage.getItem("Day")) === 6 ? 0: (parseInt(localStorage.getItem("Day")) +1):0, "")} onClick={() => {carouselChange()}}>{'>'}</Link>
       </style.StyledDayContainerDiv>
     </style.StyledCarouselDayDiv>
      </style.StyledNavigationWrapper>    
    </style.StyledNavigation>
  )

  let mobileVersion = (
    <style.StyledNavigationContainerM className="navigation" >
        <div className="carousel-container">
           <a href={getDate("", dayMoin1)} 
                class="carousel__prev"><img alt="left" src="data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E"/></a>  
            <span class="dayN">{getDays(dayCurrent, "")}</span>
            <a href={getDate("", dayPlus1)} 
                class="carousel__next"><img alt="right" src="data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E"/></a>
        </div>       
    </style.StyledNavigationContainerM>
  )

  return (window.innerWidth > conf.mobileSize ? computerVersion : mobileVersion);
}
